import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Button, { ButtonProps } from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Container from "@mui/system/Container";
import Stack from "@mui/system/Stack";
import { PropsWithChildren } from "react";
import Background from "../../assets/images/cb-bg.svg";

export default function CBTopBlueBox({
    highlightTitle,
    highlights,
    image,
    title,
}: {
    title: string;
    highlightTitle: string;
    highlights: string[];
    image: string;
}) {
    return (
        <Box sx={STYLES.blueBox}>
            <Stack
                direction={{ xs: "column", md: "row" }}
                component={Container}
                spacing={6}
                pt={{ xs: 3, md: 5 }}
            >
                <Box flex={1} sx={{ display: { xs: "none", md: "block" } }}>
                    <Box
                        sx={{
                            position: "relative",
                            height: "450px",
                        }}
                    >
                        <img
                            src={image}
                            style={{
                                border: "solid 4px black",
                                borderRadius: "10px",
                                height: "100%",
                                width: "100%",
                            }}
                            alt={title}
                        />
                    </Box>
                </Box>
                <Stack flex={1.5} spacing={4}>
                    <Typography
                        variant="h3"
                        fontWeight="bold"
                        textAlign={{ xs: "center", md: "left" }}
                    >
                        {title}
                    </Typography>
                    <Typography
                        variant="h5"
                        fontWeight="bold"
                        textAlign={{ xs: "center", md: "left" }}
                    >
                        {highlightTitle}
                    </Typography>
                    <Stack component="ul" pl={{ xs: 4, md: 8 }} spacing={1}>
                        {highlights.map((item, index) => (
                            <Typography key={index} component="li" variant="h6">
                                {item}
                            </Typography>
                        ))}
                    </Stack>
                    <Stack
                        direction={{ xs: "column", sm: "row" }}
                        alignItems="center"
                        alignSelf={{ xs: "center", md: "flex-start" }}
                        spacing={4}
                    >
                        <CBWhiteButton>TALK TO A MENTOR</CBWhiteButton>
                        <CBWhiteButton>ENROLL NOW</CBWhiteButton>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}

export function CBWhiteButton({
    children,
    disabled = true,
    buttonProps,
    sx,
}: PropsWithChildren & {
    disabled?: boolean;
    buttonProps?: ButtonProps;
    sx?: any;
}) {
    return (
        <Button
            size="large"
            disabled={disabled}
            sx={{ ...STYLES.button, ...sx }}
            {...buttonProps}
        >
            {children}
        </Button>
    );
}

const STYLES: { [key: string]: SxProps } = {
    blueBox: {
        pb: 10,
        pt: { xs: 0, md: 15 },
        width: "100%",
        backgroundImage: { md: `url(${Background})` },
        backgroundSize: { md: "cover" },
        // backgroundColor: "rgba(189, 220, 251, 0.5)",
    },
    button: {
        textTransform: "capitalize",
        backgroundColor: "white",
        px: 3,
        fontWeight: "bold",
        border: "3px solid",
        "&:hover": {
            border: "3px solid",
        },
    },
};
