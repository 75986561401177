import ExpandCircleDownIcon from "@mui/icons-material/ExpandCircleDown";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import IconButton, { IconButtonProps } from "@mui/material/IconButton";
import Stack, { StackProps } from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import moment from "moment";
import { ReactNode, useEffect, useState } from "react";

export default function CBPlan({
    prerequisite,
    duration,
}: {
    prerequisite: ReactNode;
    duration: string;
}) {
    return (
        <Box sx={styles.root}>
            <Stack component={Container} direction="column" spacing={3}>
                <Typography variant="h4" sx={styles.title}>
                    Here is the Plan
                </Typography>
                <Stack
                    spacing={{ md: 2, xs: 0 }}
                    sx={styles.summariesContainer}
                >
                    <Stack direction={{ xs: "column", md: "row" }}>
                        <Summary sx={{ flex: 1 }} title="Estimated Time">
                            <Typography>{duration}</Typography>
                        </Summary>

                        <Summary sx={{ flex: 1 }} title="Start by">
                            <DateComponent />
                        </Summary>

                        <Summary sx={{ flex: 1 }} title="Prerequisites">
                            {prerequisite}
                        </Summary>
                    </Stack>
                    <Stack
                        direction={{ xs: "column", md: "row" }}
                        width={{ xs: "auto", md: "100%" }}
                    >
                        {SUMMARIES.map((data, index) => (
                            <Summary
                                key={index}
                                sx={{ flex: 1 }}
                                title={data.title}
                            >
                                <Typography>{data.content}</Typography>
                            </Summary>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}

interface Props extends StackProps {
    title: string;
}

function Summary({ title, children, ...other }: Props) {
    return (
        <Stack direction="column" spacing={1} {...other}>
            <Stack direction="row" alignItems="center" alignSelf="start">
                <ExpandMore disabled>
                    <ExpandCircleDownIcon />
                </ExpandMore>
                <Typography variant="h6" sx={{ fontWeight: "bold" }}>
                    {title}
                </Typography>
            </Stack>
            <Box sx={{ pl: 4 }}>{children}</Box>
        </Stack>
    );
}

interface ExpandMoreProps extends IconButtonProps {
    expand?: boolean;
}

const ExpandMore = styled((props: ExpandMoreProps) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { expand = false, ...other } = props;
    return <IconButton {...other} />;
})(({ theme, expand }) => ({
    transform: !expand ? "rotate(-90deg)" : "-rotate(270deg)",
    marginLeft: "auto",
    transition: theme.transitions.create("transform", {
        duration: theme.transitions.duration.shortest,
    }),
}));

function DateComponent() {
    const [date, setDate] = useState("Today");

    useEffect(() => {
        setDate(moment().format("MMMM Do"));
    }, []);
    return <Typography>{date}</Typography>;
}

const SUMMARIES = [
    {
        title: "Real world projects",
        content: "From industry and academia",
    },
    {
        title: "Self-paced, flexible learning",
        content: "Control your learning time and pace",
    },
    {
        title: "Support for computing",
        content: "You don't have to buy a new computer to learn",
    },
];

const styles = {
    root: {
        backgroundColor: "rgba(189, 220, 251, 0.5)",
        width: "100%",
        py: 5,
    },
    title: {
        fontWeight: "bold",
        textAlign: { xs: "center", sm: "left" },
    },
    summariesContainer: {
        alignSelf: {
            xs: "center",
            sm: "flex-start",
        },
        width: { xs: "auto", md: "100%" },
    },
};
