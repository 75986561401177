import * as React from "react";
import { SxProps, Theme, useTheme } from "@mui/material/styles";
import OutlinedInput from "@mui/material/OutlinedInput";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select, { SelectChangeEvent } from "@mui/material/Select";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
    PaperProps: {
        style: {
            maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
            width: 250,
        },
    },
};

function getStyles(name: string, values: readonly string[], theme: Theme) {
    return {
        fontWeight:
            values.indexOf(name) === -1
                ? theme.typography.fontWeightRegular
                : theme.typography.fontWeightMedium,
    };
}

type Props = {
    placeholder: string;
    items: { label: string; value: string }[];
    value: string[];
    onChange: (event: SelectChangeEvent<string[]>) => void;
    sx?: SxProps;
};

export default function MultipleSelectPlaceholder({
    sx,
    placeholder,
    items,
    value,
    onChange,
}: Props) {
    const theme = useTheme();

    const values = value;

    return (
        <FormControl sx={sx}>
            <Select
                multiple
                displayEmpty
                value={values}
                onChange={onChange}
                input={<OutlinedInput />}
                renderValue={(selected) => {
                    if (selected.length === 0) {
                        return <span>{placeholder}</span>;
                    }

                    return selected
                        .map(
                            (svalue) =>
                                items.find(({ value }) => value === svalue)
                                    ?.label
                        )
                        .filter((sval) => Boolean(sval))
                        .join(", ");
                }}
                MenuProps={MenuProps}
                inputProps={{ "aria-label": "Without label" }}
            >
                <MenuItem disabled value="">
                    <span>{placeholder}</span>
                </MenuItem>
                {items.map(({ label, value }) => (
                    <MenuItem
                        key={value}
                        value={value}
                        style={getStyles(value, values, theme)}
                    >
                        {label}
                    </MenuItem>
                ))}
            </Select>
        </FormControl>
    );
}
