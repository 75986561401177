import { Edit } from "@mui/icons-material";
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import { Button, Divider, IconButton, Stack, Typography } from "@mui/material";
import { ModuleLanguageType } from "@teyalite/hackbio-common/dist/types/module-language-type.enum";
import { SubmoduleType } from "@teyalite/hackbio-common/dist/types/submodule-type.enum";
import { Component, Fragment } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useNavigate } from "react-router-dom";
import BackdropLoading from "../../components/BackdropLoading";
import Loading from "../../components/Loading";
import ModuleDetailsForm from "../../components/courses/ModuleDetailsForm";
import ModuleForm from "../../components/courses/ModuleForm";
import SubmoduleDisplay from "../../components/courses/SubmoduleDisplay";
import SubmoduleEditor from "../../components/courses/SubmoduleEditor";
import UrlForm from "../../components/courses/UrlForm";
import { CONNECTION_FAILED } from "../../constants";
import {
    addSubmoduleCreator,
    fetchModuleDetailsCreator,
    removeSubmoduleCreator,
    updateModuleCreator,
    updateSubmoduleCreator,
} from "../../redux/actions/course-module";
import { AppState } from "../../redux/store";
import {
    ModuleDetails as ModuleDetailsType,
    Question,
    Submodule,
} from "../../types";
import {
    deleteRequest,
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from "../../utils/http";
// import { sleep } from "../../utils/sleep";
import { withCourseId } from "../../utils/with-course";
import ModuleTestForm from "../../components/courses/ModuleTestForm";
import { fetchQuestionCreator } from "../../redux/actions/question";
import theme from "../../utils/theme";

type Props = PropsFromRedux & {
    courseId: number;
    moduleId: number;
};

type State = {
    openModuleForm: boolean;
    isEditingModule: boolean;
    isCreatingSection: boolean;

    changes: { [key: number]: Submodule };

    editor: null | {
        type: SubmoduleType.Text | SubmoduleType.Project;
        id: number;
        dialogTitle: string;
        lexicalState: any;
        title: string;
        onSubmitEditor: (
            id: number,
            title: string,
            editorState: any,
            htmlString: string
        ) => void;
        onClose: () => void;
    };

    urlForm: null | {
        id?: number;
        type: SubmoduleType.Video | SubmoduleType.Slide;
        urlPlaceholder: string;
        title: string;
        url: string;
        dialogTitle: string;
        submitText: string;
        submit: (
            type: SubmoduleType.Video | SubmoduleType.Slide,
            title: string,
            url: string,
            submoduleId?: number
        ) => void;
        onClose: () => void;
    };

    isModuleTestLoading: boolean;

    questionForm: null | {
        id?: number;
        request: "create" | "update";
        title: string;
        questionId: string;
        dialogTitle: string;
        submitButtonText: string;
    };
};

class ModuleDetails extends Component<Props, State> {
    private editorKey: number;

    constructor(props: Props) {
        super(props);

        this.state = {
            isCreatingSection: false,
            openModuleForm: false,
            isEditingModule: false,

            isModuleTestLoading: false,

            changes: {},
            urlForm: null,
            questionForm: null,
            editor: null,
        };

        this.editorKey = 0;
    }

    componentDidMount(): void {
        const { isLoading } = this.props;

        if (!isLoading) {
            this.fetchModuleDetails();
        }
    }

    fetchModuleDetails = async () => {
        const { details, fetch, courseId, moduleId, question, fetchQuestion } =
            this.props;

        fetch({ isLoading: true, failed: false, details: details });

        try {
            const fetchedDetails = await getRequest<ModuleDetailsType>(
                "/course/" + courseId + "/module/" + moduleId
            );

            if (question.questions.length === 0) {
                const questions = await getRequest<Question[]>("/question");

                fetchQuestion({
                    ...question,
                    questions,
                });
            }

            // await sleep(0.5);

            fetch({ isLoading: false, failed: false, details: fetchedDetails });
        } catch (error: any) {
            fetch({ isLoading: false, failed: true, details: null });
        }
    };

    onModuleEdit = () => {
        this.setState({ openModuleForm: true });
    };

    onCloseModuleTestForm = () => {
        this.setState({ questionForm: null });
    };

    onSubmitModuleTestForm = async (
        request: "create" | "update",
        title: string,
        questionId: number,
        submoduleId?: number
    ) => {
        const { addSubmodule, courseId, moduleId, updateSubmodule } =
            this.props;

        this.setState({
            isModuleTestLoading: true,
        });

        try {
            if (request === "create") {
                const createdSubmodule = await postRequest<Submodule>(
                    `/course/${courseId}/module/${moduleId}/submodule`,
                    { type: SubmoduleType.Test, questionId, title }
                );

                addSubmodule(createdSubmodule);

                window.alert(
                    "Testsection was created, scoll to bottom to view it"
                );
            } else {
                const updatedSubmodule = await putRequest(
                    `/course/${courseId}/module/${moduleId}/submodule/${submoduleId}`,
                    { type: SubmoduleType.Test, questionId, title }
                );

                updateSubmodule(updatedSubmodule);
            }

            this.setState({ questionForm: null });
        } catch (error) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({
            isModuleTestLoading: false,
        });
    };

    onSubmitCreateUrlForm = async (
        type: SubmoduleType.Video | SubmoduleType.Slide,
        title: string,
        url: string
    ) => {
        const { courseId, addSubmodule, moduleId } = this.props;

        this.setState({ isCreatingSection: true });

        try {
            const createdSubmodule = await postRequest<Submodule>(
                `/course/${courseId}/module/${moduleId}/submodule`,
                { type, url, title }
            );

            addSubmodule(createdSubmodule);

            this.setState({ urlForm: null });

            window.alert(
                `${
                    type === SubmoduleType.Slide ? "Slide" : "Video"
                } section was created, scoll to bottom to view it`
            );
        } catch (error) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isCreatingSection: false });
    };

    onCloseModuleForm = () => {
        this.setState({ openModuleForm: false });
    };

    submitModuleChanges = async (
        title: string,
        languageType: ModuleLanguageType | null
    ) => {
        const { details, courseId, moduleId, updateModule } = this.props;

        if (details === null) return;

        if (details.title === title && details.languageType === languageType) {
            return this.onCloseModuleForm();
        }

        this.setState({ isEditingModule: true });
        try {
            await patchRequest("/course/" + courseId + "/module/" + moduleId, {
                title,
                languageType,
            });

            updateModule({ title, languageType, moduleId });
            this.onCloseModuleForm();
        } catch (error: any) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isEditingModule: false });
    };

    onUrlFormPreviewChanges = (
        type: SubmoduleType.Video | SubmoduleType.Slide,
        title: string,
        url: string,
        submoduleId?: number
    ) => {
        const { details } = this.props;

        if (details === null) return;

        const submodule = details.submodules.find(
            (sub) => sub.id === submoduleId
        );

        if (submodule) {
            this.setState({
                urlForm: null,
                changes: {
                    ...this.state.changes,
                    [submoduleId!]: {
                        ...submodule,
                        title: title,
                        content: {
                            ...submodule.content,
                            ...(type === SubmoduleType.Video
                                ? { videoUrl: url }
                                : { slideUrl: url }),
                        },
                    },
                },
            });
        }
    };

    onEditSection = (
        type: SubmoduleType,
        submoduleId: number,
        title: string,
        content: any
    ) => {
        if (type === SubmoduleType.Video) {
            this.setState({
                urlForm: {
                    id: submoduleId,
                    type: SubmoduleType.Video,
                    urlPlaceholder: "Youtube video embed url",
                    title: title,
                    url: content.videoUrl ?? "",
                    dialogTitle: "Edit video section d",
                    submitText: "Preview changes",
                    submit: this.onUrlFormPreviewChanges,
                    onClose: () => this.setState({ urlForm: null }),
                },
            });
        } else if (
            type === SubmoduleType.Project ||
            type === SubmoduleType.Text
        ) {
            this.setState({
                editor: {
                    type: type,
                    id: submoduleId,
                    title: title,
                    dialogTitle:
                        type === SubmoduleType.Text
                            ? "Edit text section"
                            : "Edit project section",
                    lexicalState: content.lexicalState,
                    onSubmitEditor: this.onSubmitEditorEditor,
                    onClose: () => {
                        this.setState({ editor: null });
                    },
                },
            });
        } else if (type === SubmoduleType.Test) {
            this.setState({
                questionForm: {
                    request: "update",
                    title: title,
                    questionId: String(content.id),
                    dialogTitle: "Edit test section",
                    submitButtonText: "Save changes",
                    id: submoduleId,
                },
            });
        } else if (type === SubmoduleType.Slide) {
            this.setState({
                urlForm: {
                    id: submoduleId,
                    type: SubmoduleType.Slide,
                    urlPlaceholder: "Google slide embed url",
                    title: title,
                    url: content.slideUrl ?? "",
                    dialogTitle: "Edit slide section",
                    submitText: "Preview changes",
                    submit: this.onUrlFormPreviewChanges,
                    onClose: () => this.setState({ urlForm: null }),
                },
            });
        }
    };

    onSubmitEditorEditor = (
        id: number,
        title: string,
        editorState: any,
        htmlString: string
    ) => {
        this.editorKey += 1;

        const { details } = this.props;

        if (details === null) return;

        const submodule = details.submodules.find((sub) => sub.id === id);

        if (submodule) {
            this.setState({
                editor: null,
                changes: {
                    ...this.state.changes,
                    [id]: {
                        ...submodule,
                        title: title,
                        content: {
                            ...submodule.content,
                            html: htmlString,
                            lexicalState: editorState,
                        },
                    },
                },
            });
        }
    };

    onSaveChanges = async ({ type, title, content, id }: Submodule) => {
        const { courseId, updateSubmodule, moduleId } = this.props;

        const data: any = { type, title };

        if (type === SubmoduleType.Video) {
            data.url = content.videoUrl;
        } else if (type === SubmoduleType.Slide) {
            data.url = content.slideUrl;
        } else if (type === SubmoduleType.Text || SubmoduleType.Project) {
            data.richHtml = content;
        }

        this.setState({ isCreatingSection: true });

        try {
            const updatedSubmodule = await putRequest(
                `/course/${courseId}/module/${moduleId}/submodule/${id}`,
                data
            );

            this.setState({ changes: { ...this.state, [id]: undefined } });
            updateSubmodule(updatedSubmodule);
        } catch (error) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isCreatingSection: false });
    };

    onDeleteSubmodule = async (type: SubmoduleType, submoduleId: number) => {
        const { courseId, moduleId, removeSubmodule } = this.props;

        this.setState({ isCreatingSection: true });

        try {
            await deleteRequest(
                `/course/${courseId}/module/${moduleId}/submodule/${submoduleId}?type=${type}`
            );
            removeSubmodule(submoduleId);
        } catch (error: any) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isCreatingSection: false });
    };

    handleCreateVideoSection = async () => {
        this.setState({
            urlForm: {
                type: SubmoduleType.Video,
                urlPlaceholder: "Youtube video embed url",
                title: "",
                url: "",
                dialogTitle: "Create video section",
                submitText: "Create video",
                submit: this.onSubmitCreateUrlForm,
                onClose: () => this.setState({ urlForm: null }),
            },
        });
    };

    handleCreateSlideSection = async () => {
        this.setState({
            urlForm: {
                type: SubmoduleType.Slide,
                urlPlaceholder: "Google slide embed url",
                title: "",
                url: "",
                dialogTitle: "Create slide section",
                submitText: "Create Section",
                submit: this.onSubmitCreateUrlForm,
                onClose: () => this.setState({ urlForm: null }),
            },
        });
    };

    handleCreateRichHtml = async (
        type: SubmoduleType.Text | SubmoduleType.Project
    ) => {
        const { courseId, addSubmodule, moduleId } = this.props;

        this.setState({ isCreatingSection: true });

        try {
            const createdSubmodule = await postRequest<Submodule>(
                `/course/${courseId}/module/${moduleId}/submodule`,
                { type }
            );

            addSubmodule(createdSubmodule);

            window.alert(
                `${
                    type === SubmoduleType.Project ? "Project" : "Text"
                } section was created, scoll to bottom to view it`
            );
        } catch (error) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isCreatingSection: false });
    };

    handleCreateTest = () => {
        this.setState({
            questionForm: {
                request: "create",
                title: "",
                questionId: "",
                dialogTitle: "Create test section",
                submitButtonText: "Create Test",
            },
        });
    };

    render() {
        const { details, isLoading, failed, question } = this.props;

        if (isLoading || failed) {
            return (
                <Stack flexGrow={1} alignItems="center" justifyContent="center">
                    <Loading
                        failed={failed}
                        onRetry={this.fetchModuleDetails}
                    />
                </Stack>
            );
        }

        if (details === null) {
            return "Module Not Found";
        }

        const {
            openModuleForm,
            isEditingModule,
            changes,
            isCreatingSection,
            urlForm,
            editor,
            questionForm,
            isModuleTestLoading,
        } = this.state;

        return (
            <Stack className="content-min-width" spacing={3}>
                {isCreatingSection && <BackdropLoading open />}

                {questionForm !== null && (
                    <ModuleTestForm
                        id={questionForm.id}
                        request={questionForm.request}
                        title={questionForm.title}
                        questionId={questionForm.questionId}
                        dialogTitle={questionForm.dialogTitle}
                        submitButtonText={questionForm.submitButtonText}
                        isLoading={isModuleTestLoading}
                        onClose={this.onCloseModuleTestForm}
                        onSubmit={this.onSubmitModuleTestForm}
                        items={question.questions.map((quest) => ({
                            value: String(quest.id),
                            label: quest.title,
                        }))}
                    />
                )}

                {urlForm !== null && (
                    <UrlForm
                        open
                        submit={urlForm.submit}
                        type={urlForm.type}
                        submoduleId={urlForm.id}
                        onClose={urlForm.onClose}
                        dialogTitle={urlForm.dialogTitle}
                        submitButtonText={urlForm.submitText}
                        data={{ title: urlForm.title, url: urlForm.url }}
                        urlPlaceholder={urlForm.urlPlaceholder}
                        isLoading={isCreatingSection}
                    />
                )}

                {editor && (
                    <SubmoduleEditor
                        open={true}
                        id={editor.id}
                        initialState={editor.lexicalState}
                        dialogTitle={editor.dialogTitle}
                        title={editor.title}
                        onClose={editor.onClose}
                        onSubmit={editor.onSubmitEditor}
                        isLoading={false}
                    />
                )}

                <ModuleForm
                    open={openModuleForm}
                    submitButtonText="Save changes"
                    isLoading={isEditingModule}
                    dialogTitle="Edit module"
                    title={details.title}
                    languageType={details.languageType}
                    onClose={this.onCloseModuleForm}
                    submit={this.submitModuleChanges}
                />

                {/* todo: make app bar elevate on scroll */}
                <Stack
                    sx={{
                        width: "100%",
                        borderBottom: "1px rgb(0 0 0 / 8%) solid",
                        pb: 1,
                    }}
                    direction="row"
                    spacing={2}
                    justifyContent="space-between"
                >
                    <Stack spacing={2} alignItems="center" direction="row">
                        <BackButton />
                        <Stack direction="row" spacing={4}>
                            <Stack direction="row" spacing={1} maxWidth="80%">
                                <Typography>Module:</Typography>
                                <Typography fontWeight="bold" noWrap>
                                    {details.title}
                                </Typography>
                            </Stack>
                            <Typography minWidth="130px">
                                Language:{" "}
                                <strong>
                                    {details.languageType === null
                                        ? "None"
                                        : details.languageType ===
                                          ModuleLanguageType.Python
                                        ? "Python"
                                        : "R"}
                                </strong>
                            </Typography>
                            <Typography
                                color={theme.palette.warning.main}
                                fontStyle="italic"
                            >
                                {details.type}
                            </Typography>
                        </Stack>
                    </Stack>
                    <Button
                        startIcon={<Edit />}
                        sx={{ minWidth: "150px" }}
                        size="small"
                        variant="outlined"
                        onClick={this.onModuleEdit}
                    >
                        Edit module
                    </Button>
                </Stack>
                <ModuleDetailsForm
                    onCreateVideoSection={this.handleCreateVideoSection}
                    onCreateSlideSection={this.handleCreateSlideSection}
                    onCreateRichHtml={this.handleCreateRichHtml}
                    onTestCreate={this.handleCreateTest}
                    moduleType={details.type}
                />
                <Stack spacing={2}>
                    {details.submodules.map((submodule) => {
                        return (
                            <Fragment key={submodule.id}>
                                <SubmoduleDisplay
                                    submodule={
                                        changes[submodule.id] ?? submodule
                                    }
                                    onSave={this.onSaveChanges}
                                    onDelete={this.onDeleteSubmodule}
                                    showSave={Boolean(changes[submodule.id])}
                                    editorKey={String(this.editorKey)}
                                    onEdit={this.onEditSection}
                                />
                                <Divider />
                            </Fragment>
                        );
                    })}
                </Stack>
            </Stack>
        );
    }
}

function BackButton() {
    const navigate = useNavigate();

    const onBack = () => {
        navigate(-1);
    };

    return (
        <IconButton onClick={onBack}>
            <ArrowBackIcon />
        </IconButton>
    );
}

const mapDispatchToProps = {
    fetch: fetchModuleDetailsCreator,
    updateModule: updateModuleCreator,
    updateSubmodule: updateSubmoduleCreator,
    addSubmodule: addSubmoduleCreator,
    removeSubmodule: removeSubmoduleCreator,
    fetchQuestion: fetchQuestionCreator,
};

function mapStateToProps(state: AppState) {
    return {
        ...state.courseModule.details,
        question: { ...state.question },
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withCourseId<Props>(ModuleDetails));
