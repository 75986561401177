import { Question, QuestionDetails, QuestionTest } from "../../types";
import { Dispatch } from "redux";
import {
    AddQuestion,
    FetchQuestion,
    QuestionActionType,
    FetchQuestionState,
    EditQuestionDetails,
    FetchQuestionDetails,
    QuestionDetailsState,
    UpdateQuestionTest,
    AddQuestionTest,
} from "../types/question";

export const fetchQuestionCreator = (payload: FetchQuestionState) => {
    return (dispatch: Dispatch<FetchQuestion>) => {
        dispatch({
            value: payload,
            type: QuestionActionType.FetchQuestion,
        });
    };
};

export const addQuestionCreator = (payload: Question) => {
    return (dispatch: Dispatch<AddQuestion>) => {
        dispatch({
            value: payload,
            type: QuestionActionType.AddQuestion,
        });
    };
};

export const fetchQuestionDetailsCreator = (payload: QuestionDetailsState) => {
    return (dispatch: Dispatch<FetchQuestionDetails>) => {
        dispatch({
            value: payload,
            type: QuestionActionType.FetchQuestionDetails,
        });
    };
};

export const editQuestionDetailsCreator = (payload: QuestionDetails) => {
    return (dispatch: Dispatch<EditQuestionDetails>) => {
        dispatch({
            value: payload,
            type: QuestionActionType.EditQuestionDetails,
        });
    };
};

export const updateQuestionTestCreator = (payload: QuestionTest) => {
    return (dispatch: Dispatch<UpdateQuestionTest>) => {
        dispatch({
            value: payload,
            type: QuestionActionType.UpdateQuestionTest,
        });
    };
};

export const addQuestionTestCreator = (payload: QuestionTest) => {
    return (dispatch: Dispatch<AddQuestionTest>) => {
        dispatch({
            value: payload,
            type: QuestionActionType.AddQuestionTest,
        });
    };
};
