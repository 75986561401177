import {
    defaultCourseDetailsState,
    CourseDetailsState,
    CourseDetailsActionType,
    CourseDetailsAction,
} from "../types/course-details";

function courseDetailsReducer(
    state: CourseDetailsState = defaultCourseDetailsState,
    action: CourseDetailsAction
    // action: any
): CourseDetailsState {
    switch (action.type) {
        case CourseDetailsActionType.FetchCourseDetails:
            state = {
                ...state,
                ...action.value,
            };
            return state;

        case CourseDetailsActionType.UpdateCourseDetails:
            state = {
                ...state,
                courseDetails: action.value,
            };
            return state;

        default:
            return state;
    }
}

export default courseDetailsReducer;
