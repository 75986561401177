// Names max length
export const NAME_MAX_LENGTH = 50;
// user Name max length
export const USER_NAME_MAX_LENGHT = 255;
// user name
export const USER_NAME_FIELD = 'Name';

// user username name max length if changes, then it sould be changed in the regex as well
export const USER_USERNAME_MAX_LENGTH = 50;
// user username name min length if changes, then it sould be changed in the regex as well
export const USER_USERNAME_MIN_LENGTH = 4;
// Username field
export const USERNAME_FIELD = 'Username';
export const USERNAME_REGEX =
  /^(?=.{4,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/i;

// Username length error message
export const USERNAME_LENGTH_ERROR_MESSAGE = `${USERNAME_FIELD} must contain at least ${USER_USERNAME_MIN_LENGTH} characters`;
export const USERNAME_CHARACTERS_ERROR =
  'Can only contain alphanumeric characters and numbers and ._ characters but not at the end or at the beginning';

/**
 * PASSWORD FIELD
 */
// Password MaxLength User and Admin
export const PASSWORD_MAX_LENGTH = 70;
// user password min length
export const PASSWORD_MIN_LENGTH = 6;
// Password field
export const PASSWORD_FIELD = 'Password';
// Password ASCII pattern error message
export const PASSWORD_ASCII_ERROR_MESSAGE = `${PASSWORD_FIELD} must only contain ASCII characters`;

// Email max length
export const EMAIL_MAX_LENGHT = 326;
// Invalid email error message
export const INVALID_EMAIL = 'Email address is invalid';

// User doesn't exist in workshop
export const USER_NOT_FOUND = 'User not found';
// User data don't correponds
export const USER_INVALID_IDENTIFIERS = 'Password incorrect';
// When email already exist on another account
export const USER_EMAIL_EXIST = 'An account with this email already exists';
// Random code length
export const USER_CONFIRMATION_CODE_LENGTH = 20;
// Invalid Confirmation Link
export const USER_INVALID_CONFIRMATION_LINK =
  'This confirmation link is invalid. Maybe you have already used it ?';
// Invalid Confirmation Link
export const USER_INVALID_PASSWORD_RECOVERY_LINK =
  'This reset password link is invalid. Maybe you have already used it ?';

/**
 * FILE
 */
export const FILE_SIZE_UNIT = ['Bytes', 'KB', 'MB', 'GB'];

export const IMAGE_ALLOWED_TYPES = [
  '.jpg',
  '.jpeg',
  '.jpe',
  '.png',
  '.gif',
  '.webp',
  '.svg',
];

export const IMAGE_ALLOWED_MIME_TYPES = [
  'image/jpeg',
  'image/gif',
  'image/png',
  'image/webp',
  'image/svg+xml',
];

/* Image max size */
export const IMAGE_SIZE_LIMIT = 1024 * 1024 * 10;

export const TITLE_MAX_LENGTH = 200;

export const BADGE_IMAGES_DIRECTORY = 'badges';

export const COURSE_IMAGES_DIRECTORY = 'courses';

export const USER_PROFILE_UPLOADE_DIRECTORY = 'avatars';

/* Submission xp and grades */
export const MAX_PROJECT_GRADE = 60;

export const MIN_PROJECT_GRADE = 1;
