import { SxProps } from "@mui/material";
import Button from "@mui/material/Button";
import { PropsWithChildren } from "react";

export default function BlueButton({
    children,
    sx,
    type,
}: PropsWithChildren & {
    sx?: SxProps;
    href?: string;
    type?: "submit" | "reset" | "button";
}) {
    return (
        <Button
            variant="contained"
            color="info"
            size="large"
            sx={{ fontWeight: 600, px: 8, ...sx }}
            disableElevation
            type={type}
        >
            {children}
        </Button>
    );
}
