import React, { Component, ReactNode } from "react";
import MuiTableCell from "@mui/material/TableCell";
import Typography from "@mui/material/Typography";

const maxCellWidth: number = 250;

class TextTabCell extends Component<{
    children: ReactNode;
    align?: "left" | "center" | "right" | "justify" | "inherit" | undefined;
    maxWidth?: number;
}> {
    render() {
        const { children, maxWidth, align } = this.props;
        return (
            <MuiTableCell align={align} component="th">
                <Typography
                    noWrap
                    style={{ maxWidth: maxWidth ?? maxCellWidth }}
                >
                    {children}
                </Typography>
            </MuiTableCell>
        );
    }
}

export default TextTabCell;
