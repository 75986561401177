import { Edit } from "@mui/icons-material";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import { QuestionTestType } from "@teyalite/hackbio-common/dist/types/question-test-type.enum";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useParams } from "react-router-dom";
import Container from "../../components/Container";
import AddQuestionTestForm from "../../components/questions/AddQuestionForm";
import FillInGapForm from "../../components/questions/FillInGapForm";
import MCQForm from "../../components/questions/MCQForm";
import QuestionTestDisplay from "../../components/questions/QuestionTestDisplay";
import ReorderListForm from "../../components/questions/ReorderListForm";
import TypeItInForm from "../../components/questions/TypeItInForm";
import { CONNECTION_FAILED } from "../../constants";
import {
    addQuestionTestCreator,
    editQuestionDetailsCreator,
    fetchQuestionDetailsCreator,
    updateQuestionTestCreator,
} from "../../redux/actions/question";
import { AppState } from "../../redux/store";
import {
    DrawerLink,
    MCQAnswer,
    QuestionDetails as QuestionDetailsType,
    QuestionTest,
} from "../../types";
import {
    getRequest,
    patchRequest,
    postRequest,
    putRequest,
} from "../../utils/http";
import NotFound from "../NotFound";
import QuestionForm from "../../components/questions/QuestionForm";
import BackdropLoading from "../../components/BackdropLoading";

type Props = PropsFromRedux & {};

type State = {
    drawerItem: DrawerLink;
    isChangingTitle: boolean;
    isSavingUpdates: boolean;
    isCurrentLoading: boolean;
    openQuestionForm: boolean;

    changes: { [key: number]: QuestionTest | undefined };

    mcqQuestionTest: null | QuestionTest;
    typeItInQuestionTest: null | QuestionTest;
    reorderListQuestionTest: null | QuestionTest;
    fillInGapQuestionTest: null | QuestionTest;
};

class QuestionDetailsCC extends Component<
    Props & { questionId: number },
    State
> {
    // private mounted: boolean;

    constructor(props: Props & { questionId: number }) {
        super(props);
        this.state = {
            drawerItem: {
                href: `/questions/${props.questionId}`,
                text: props.details?.title ?? "",
                selected: true,
            },

            openQuestionForm: false,
            isChangingTitle: false,
            isSavingUpdates: false,
            isCurrentLoading: false,
            changes: {},

            mcqQuestionTest: null,
            typeItInQuestionTest: null,
            reorderListQuestionTest: null,
            fillInGapQuestionTest: null,
        };

        // this.mounted = false;
    }

    componentDidMount(): void {
        // this.mounted = true;

        const { questionId, details, fetch } = this.props;

        if (isNaN(questionId)) {
            return fetch({
                isLoading: false,
                failed: false,
                details: null,
            });
        }

        if (details === null || questionId !== details.id) {
            this.fetchQuestionDetails();
        }
    }

    componentWillUnmount(): void {
        // this.mounted = false;
    }

    fetchQuestionDetails = async () => {
        const { fetch, details, questionId } = this.props;

        fetch({
            isLoading: true,
            failed: false,
            details,
        });

        try {
            const pathname = "/question/" + questionId;

            const questionDetails = await getRequest<QuestionDetailsType>(
                pathname
            );

            this.setState({
                drawerItem: {
                    ...this.state.drawerItem,
                    text: questionDetails.title,
                },
            });

            fetch({
                isLoading: false,
                failed: false,
                details: questionDetails,
            });
        } catch (error) {
            fetch({
                isLoading: true,
                failed: true,
                details: null,
            });
        }
    };

    handleQuestionTitleEdit = async (title: string) => {
        const { questionId, editQuestionDetails } = this.props;
        const { drawerItem } = this.state;

        if (title === drawerItem.text) {
            return this.setState({
                openQuestionForm: false,
            });
        }

        try {
            const pathname = `/question/${questionId}`;

            const question = await patchRequest<QuestionDetailsType>(pathname, {
                title,
            });

            this.setState({
                isChangingTitle: false,
                openQuestionForm: false,
                drawerItem: { ...drawerItem, text: question.title },
            });

            editQuestionDetails(question);
        } catch (error) {
            window.alert(CONNECTION_FAILED);
        }
    };

    handleQuestionTestCreate = async (type: QuestionTestType) => {
        const { addQuestionTest, questionId } = this.props;
        this.setState({ isCurrentLoading: true });

        try {
            const pathname = `/question/${questionId}`;

            const createdQuestionTest = await postRequest<QuestionTest>(
                pathname,
                {
                    type,
                    title: `Untitle ${type} test`,
                    trials: 3,
                    xp: 10,
                }
            );

            addQuestionTest(createdQuestionTest);
            this.setState({ openQuestionForm: false });
        } catch (error) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isCurrentLoading: false });
    };

    onQuestionTestEdit = (
        type: QuestionTestType,
        questionTest: QuestionTest
    ) => {
        if (type === QuestionTestType.MCQ) {
            this.setState({ mcqQuestionTest: questionTest });
        } else if (type === QuestionTestType.TypeItIn) {
            this.setState({ typeItInQuestionTest: questionTest });
        } else if (type === QuestionTestType.ReorderList) {
            this.setState({ reorderListQuestionTest: questionTest });
        } else if (type === QuestionTestType.FillInGap) {
            this.setState({ fillInGapQuestionTest: questionTest });
        }
    };

    closeMCQForm = () => {
        this.setState({ mcqQuestionTest: null });
    };

    submitMCQUpdate = (data: {
        id: number;
        title: string;
        trials: number;
        xp: number;
        explanation: string[];
        hint: string[];
        mcq: { answers: MCQAnswer[]; question: string };
    }) => {
        const questionTest: QuestionTest = {
            id: data.id,
            title: data.title,
            type: QuestionTestType.MCQ,
            trials: data.trials,
            xp: data.xp,
            explanation: data.explanation,
            hint: data.hint,
            content: data.mcq,
        };

        this.setState({
            changes: {
                ...this.state.changes,
                [data.id]: { ...questionTest },
            },
            mcqQuestionTest: null,
        });
    };

    closeTypeItInForm = () => {
        this.setState({ typeItInQuestionTest: null });
    };

    submitTypeItInUpdate = (data: {
        id: number;
        title: string;
        trials: number;
        xp: number;
        explanation: string[];
        hint: string[];
        typeItIn: { answer: string; question: string };
    }) => {
        const questionTest: QuestionTest = {
            id: data.id,
            title: data.title,
            type: QuestionTestType.TypeItIn,
            trials: data.trials,
            xp: data.xp,
            explanation: data.explanation,
            hint: data.hint,
            content: data.typeItIn,
        };

        this.setState({
            changes: {
                ...this.state.changes,
                [data.id]: { ...questionTest },
            },
            typeItInQuestionTest: null,
        });
    };

    closeReorderListForm = () => {
        this.setState({ reorderListQuestionTest: null });
    };

    submitReorderListUpdate = (data: {
        id: number;
        title: string;
        trials: number;
        xp: number;
        explanation: string[];
        hint: string[];
        reorderList: { list: string[]; question: string };
    }) => {
        const questionTest: QuestionTest = {
            id: data.id,
            title: data.title,
            type: QuestionTestType.ReorderList,
            trials: data.trials,
            xp: data.xp,
            content: data.reorderList,
            explanation: data.explanation,
            hint: data.hint,
        };

        this.setState({
            changes: {
                ...this.state.changes,
                [data.id]: { ...questionTest },
            },
            reorderListQuestionTest: null,
        });
    };

    closeFillInGapForm = () => {
        this.setState({ fillInGapQuestionTest: null });
    };

    submitFillInGapUpdate = (data: {
        id: number;
        title: string;
        trials: number;
        xp: number;
        explanation: string[];
        hint: string[];
        fillInGap: { answers: string[]; question: string };
    }) => {
        const questionTest: QuestionTest = {
            id: data.id,
            title: data.title,
            type: QuestionTestType.FillInGap,
            trials: data.trials,
            xp: data.xp,
            explanation: data.explanation,
            hint: data.hint,
            content: data.fillInGap,
        };

        this.setState({
            changes: {
                ...this.state.changes,
                [data.id]: { ...questionTest },
            },
            fillInGapQuestionTest: null,
        });
    };

    onSaveChanges = async ({ id, content, ...others }: QuestionTest) => {
        const { questionId, updateQuestionTest } = this.props;
        const data: any = { ...others };

        if (others.type === QuestionTestType.MCQ) {
            data.mcq = content;
        } else if (others.type === QuestionTestType.FillInGap) {
            data.fillInGap = content;
        } else if (others.type === QuestionTestType.TypeItIn) {
            data.typeItIn = content;
        } else {
            data.reorderList = content;
        }

        this.setState({ isSavingUpdates: true });

        try {
            const updatedQuestionTest = await putRequest<QuestionTest>(
                `/question/${questionId}/${id}`,
                data
            );

            updateQuestionTest(updatedQuestionTest);

            this.setState({
                mcqQuestionTest: null,
                changes: { ...this.state.changes, [id]: undefined },
            });
        } catch (error: any) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isSavingUpdates: false });
    };

    render() {
        const { isLoading, failed, details } = this.props;
        const {
            drawerItem,
            isChangingTitle,
            mcqQuestionTest,
            typeItInQuestionTest,
            reorderListQuestionTest,
            changes,
            fillInGapQuestionTest,
            isCurrentLoading,
            openQuestionForm,
        } = this.state;

        return (
            <Container
                drawerItems={[drawerItem]}
                drawerBackButton={DRAWER_BACK_BUTTON}
                appBarTitle={
                    <>
                        {Boolean(drawerItem.text)
                            ? drawerItem.text
                            : details?.title}{" "}
                        <IconButton
                            sx={{ ml: 2 }}
                            onClick={() =>
                                this.setState({ openQuestionForm: true })
                            }
                            disabled={isChangingTitle || isLoading}
                        >
                            <Edit />
                        </IconButton>
                    </>
                }
                isLoading={isLoading}
                failed={failed}
                onFail={this.fetchQuestionDetails}
            >
                <Stack className="content-min-width" spacing={5}>
                    <AddQuestionTestForm
                        onCreateQuestionTest={this.handleQuestionTestCreate}
                    />

                    {isCurrentLoading && <BackdropLoading open />}

                    {openQuestionForm && (
                        <QuestionForm
                            isLoading={isChangingTitle}
                            defaultTitle={drawerItem.text}
                            edit
                            onSubmit={this.handleQuestionTitleEdit}
                            onClose={() =>
                                this.setState({ openQuestionForm: false })
                            }
                        />
                    )}

                    {mcqQuestionTest !== null && (
                        <MCQForm
                            questionTest={mcqQuestionTest}
                            onClose={this.closeMCQForm}
                            onSubmit={this.submitMCQUpdate}
                        />
                    )}

                    {typeItInQuestionTest !== null && (
                        <TypeItInForm
                            questionTest={typeItInQuestionTest}
                            onClose={this.closeTypeItInForm}
                            onSubmit={this.submitTypeItInUpdate}
                        />
                    )}

                    {reorderListQuestionTest !== null && (
                        <ReorderListForm
                            questionTest={reorderListQuestionTest}
                            onClose={this.closeReorderListForm}
                            onSubmit={this.submitReorderListUpdate}
                        />
                    )}

                    {fillInGapQuestionTest !== null && (
                        <FillInGapForm
                            questionTest={fillInGapQuestionTest}
                            onClose={this.closeFillInGapForm}
                            onSubmit={this.submitFillInGapUpdate}
                        />
                    )}

                    <Stack spacing={5}>
                        {details !== null &&
                            details.tests.map((test) => (
                                <QuestionTestDisplay
                                    key={test.id}
                                    questionTest={changes[test.id] ?? test}
                                    onEdit={this.onQuestionTestEdit}
                                    showSave={Boolean(changes[test.id])}
                                    onSave={this.onSaveChanges}
                                />
                            ))}
                    </Stack>
                </Stack>
                {details === null && <NotFound />}
            </Container>
        );
    }
}

function QuestionDetails(props: Props) {
    const questionId = Number(useParams().questionId);

    return <QuestionDetailsCC {...props} questionId={questionId} />;
}

const DRAWER_BACK_BUTTON = { title: "Questions", href: "/questions" };

const mapDispatchToProps = {
    fetch: fetchQuestionDetailsCreator,
    editQuestionDetails: editQuestionDetailsCreator,
    updateQuestionTest: updateQuestionTestCreator,
    addQuestionTest: addQuestionTestCreator,
};

function mapStateToProps(state: AppState) {
    return { ...state.question.questionDetails };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(QuestionDetails);
