import { Button, Stack, Typography } from "@mui/material";
import { SelectChangeEvent } from "@mui/material/Select";
import { ChangeEvent, useState } from "react";
import DialogForm from "../DialogForm";
import Input from "../Input";
import Select from "../Select";
import { useNavigate } from "react-router-dom";

type Props = {
    id?: number;
    request: "create" | "update";
    title: string;
    questionId: string;
    dialogTitle: string;
    onClose: () => void;
    onSubmit: (
        request: "create" | "update",
        title: string,
        questionId: number,
        submoduleId?: number
    ) => void;
    submitButtonText: string;
    isLoading?: boolean;
    items: { label: string; value: string }[];
};

const TITLE_ERROR = "Title is required";
const QUESTION_ERROR = "Question is required";

export default function ModuleTestForm(props: Props) {
    const [inputTitle, setInputTitle] = useState(props.title);
    const [questionId, setQuestionId] = useState(props.questionId);
    const [triggered, setTriggered] = useState(false);
    const navigate = useNavigate();

    const handleSubmit = () => {
        if (inputTitle.trim().length === 0) {
            return setTriggered(true);
        }

        props.onSubmit(props.request, inputTitle, Number(questionId), props.id);
    };

    const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setInputTitle(e.currentTarget.value);
    };

    const onQuestionId = (event: SelectChangeEvent) => {
        setQuestionId(event.target.value);
    };

    const goToQuestions = () => {
        navigate("/questions");
    };

    return (
        <DialogForm
            open={true}
            title={props.dialogTitle}
            submitButtonText={props.submitButtonText}
            onClose={props.onClose}
            onSubmit={handleSubmit}
            isLoading={props.isLoading}
            PaperSx={{ minWidth: "500px" }}
            submitDisabled={props.items.length === 0}
        >
            {props.items.length === 0 && (
                <Stack spacing={2}>
                    <Typography textAlign="center">
                        You need to create question before you can add test
                        section
                    </Typography>
                    <Button
                        sx={{ alignSelf: "center" }}
                        variant="outlined"
                        onClick={goToQuestions}
                    >
                        Create Question
                    </Button>
                </Stack>
            )}
            {props.items.length !== 0 && (
                <Stack spacing={2}>
                    <Input
                        placeholder="Title"
                        size="medium"
                        value={inputTitle}
                        onChange={onChangeTitle}
                        error={triggered && inputTitle.trim().length === 0}
                        errorMessage={TITLE_ERROR}
                        sx={styles.input}
                    />
                    <Select
                        items={props.items}
                        value={questionId}
                        onChange={onQuestionId}
                        sx={styles.input}
                        placeholder="Question"
                        error={triggered && questionId.trim().length === 0}
                        errorMessage={QUESTION_ERROR}
                    />
                </Stack>
            )}
        </DialogForm>
    );
}

const styles = {
    input: {
        maxWidth: "500px",
        alignSelf: "center",
        width: "100%",
    },
};
