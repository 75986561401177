import MuiTableCell from "@mui/material/TableCell";
import { CourseUser } from "../../../types";
import CustomTableRow from "../../TableRow";
import TextTabCell from "../../TextTabCell";
import { Typography } from "@mui/material";

const maxCellWidth: number = 150;

type Props = {
    user: CourseUser;
};

export default function TableRow({ user }: Props) {
    return (
        <CustomTableRow>
            <TextTabCell maxWidth={maxCellWidth}>{user.name}</TextTabCell>
            <TextTabCell maxWidth={maxCellWidth}>
                <Typography component="a" noWrap href={"mailto:" + user.email}>
                    {user.email}
                </Typography>
            </TextTabCell>
            <MuiTableCell align="right">{user.progress}%</MuiTableCell>
            <MuiTableCell align="right">
                {!user.certificate ? "Not delivered" : "Delivered"}
            </MuiTableCell>
            <MuiTableCell align="right">{user.createdAt}</MuiTableCell>
        </CustomTableRow>
    );
}
