import { SxProps } from "@mui/material";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import FlyerGif from "../../assets/images/flyer.gif";
import GreenSuccess from "../../assets/images/green-success.svg";
import { CBWhiteButton } from "./CBTopBlueBox";

export default function CBFlyer({
    price,
    title,
}: {
    price: number;
    title: string;
}) {
    return (
        <Stack
            component={Container}
            py={{ xs: 8, md: 10 }}
            direction={{ xs: "column", md: "row" }}
        >
            <Box
                sx={{
                    flex: 1,
                    display: { xs: "none", md: "flex" },
                    alignItems: "center",
                    justifyContent: "center",
                }}
            >
                <img alt="" src={FlyerGif} width={450} height={450} />
            </Box>
            <Stack spacing={6} flex={1}>
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    textAlign={{ xs: "center", md: "left" }}
                >
                    {title}
                </Typography>
                <Stack spacing={1}>
                    {FLYER.map((text, index) => (
                        <Stack
                            direction="row"
                            key={index}
                            spacing={1.5}
                            alignItems="center"
                        >
                            <img
                                src={GreenSuccess}
                                alt=""
                                width={24}
                                height={24}
                            />
                            <Typography variant="h6" fontWeight="bold">
                                {text}
                            </Typography>
                        </Stack>
                    ))}
                </Stack>
                <Typography variant="h5" sx={STYLES.alignSelfStartCenter}>
                    <span style={{ fontWeight: "bold" }}>
                        Price: {`$ ${price}`}
                    </span>{" "}
                    per month
                </Typography>
                <CBWhiteButton sx={STYLES.alignSelfStartCenter}>
                    ENROLL NOW
                </CBWhiteButton>
            </Stack>
        </Stack>
    );
}

const STYLES: { [key: string]: SxProps } = {
    alignSelfStartCenter: {
        alignSelf: { md: "flex-start", xs: "center" },
    },
    pathfinder: {
        my: 10,
        maxWidth: { xs: "100%", md: "850px" },
    },
};

const FLYER = [
    "100% Practice Oriented",
    "Mentorship",
    "Immediately applicable skills",
    "High quality portfolio projects",
];
