import { Dispatch } from "redux";
import {
    FetchCourseDetails,
    CourseDetailsActionType,
    CourseDetailsState,
    UpdateCourseDetails,
} from "../types/course-details";
import { CourseDetails } from "../../types";

export const fetchCourseDetailsCreator = (payload: CourseDetailsState) => {
    return (dispatch: Dispatch<FetchCourseDetails>) => {
        dispatch({
            value: payload,
            type: CourseDetailsActionType.FetchCourseDetails,
        });
    };
};

export const updateCourseDetailsCreator = (payload: CourseDetails) => {
    return (dispatch: Dispatch<UpdateCourseDetails>) => {
        dispatch({
            value: payload,
            type: CourseDetailsActionType.UpdateCourseDetails,
        });
    };
};
