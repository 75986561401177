/**
 * Button that controls lexical on Save
 */
import { forwardRef, MouseEvent } from "react";
import { useLexicalComposerContext } from "@lexical/react/LexicalComposerContext";
import { $generateHtmlFromNodes } from "@lexical/html";

interface Props {
    onSave: (editorState: any, htmlString: string) => void;
}

type Ref = HTMLButtonElement;

const EditorButton = forwardRef<Ref, Props>(({ onSave }, ref) => {
    const [editor] = useLexicalComposerContext();

    const onClick = (e: MouseEvent<HTMLButtonElement>) => {
        e.preventDefault();
        editor.update(() => {
            const htmlString = $generateHtmlFromNodes(editor, null);
            onSave(editor._editorState, htmlString);
        });
    };

    return <button ref={ref} onClick={onClick}></button>;
});

EditorButton.displayName = "EditorButton";

export default EditorButton;
