/**
 * Single course layout
 * Fetch course {title}
 */
import ViewModuleIcon from "@mui/icons-material/ViewModule";
import Stack from "@mui/material/Stack";
import { FORBIDDEN, NOT_FOUND } from "http-status";
import { useEffect, useState } from "react";
import { Outlet, useLocation, useParams } from "react-router-dom";
import { COURSES } from "../../data/navigation.data";
import GroupIcon from "@mui/icons-material/Group";
import GradeIcon from "@mui/icons-material/Grade";
import { DrawerLink } from "../../types";
import { getRequest } from "../../utils/http";
import Container from "../Container";
import Loading from "../Loading";
import SimpleAppBar from "../navigation/SimpleAppBar";
import CourseNotFound from "./CourseNotFound";

// todo: display skeleton
export default function CourseLayout() {
    const location = useLocation();
    const courseId = Number(useParams().courseId);
    const moduleId = Number(useParams().moduleId);
    const [loading, setLoading] = useState(true);
    const [title, setTitle] = useState<string | null | undefined>(undefined);
    const [failed, setFailed] = useState(false);

    const setCourseTitle = (title: string) => {
        setTitle(title);
    };

    useEffect(() => {
        if (!isNaN(courseId)) {
            fetchCourseData();
        }

        return () => {};
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [courseId]);

    const fetchCourseData = async () => {
        setLoading(true);
        setFailed(false);

        try {
            const { title } = await getRequest("/course/" + courseId);

            setTitle(title);
            setLoading(false);
            setFailed(false);
        } catch (error: any) {
            setTitle(
                error.response &&
                    [NOT_FOUND, FORBIDDEN].includes(error.response.status)
                    ? null
                    : undefined
            );
            setLoading(false);
            setFailed(!(error.response && error.response.status === NOT_FOUND));
        }
    };

    if (isNaN(courseId) || title === null) {
        return (
            <Stack width="100%" height="100%" sx={{ background: "white" }}>
                <SimpleAppBar />
                <CourseNotFound />
            </Stack>
        );
    }

    if (loading || failed) {
        return (
            <Stack width="100%" height="100%" sx={{ background: "white" }}>
                <SimpleAppBar />
                <Stack flexGrow={1} alignItems="center" justifyContent="center">
                    <Loading failed={failed} onRetry={fetchCourseData} />
                </Stack>
            </Stack>
        );
    }

    const detailsHref = `/courses/${courseId}`;
    const detailsSelected =
        location.pathname === detailsHref ||
        location.pathname === detailsHref + "/";

    const drawerItems: DrawerLink[] = [
        {
            text: "Course Details",
            selected: detailsSelected,
            icon: COURSES.icon,
            href: `/courses/${courseId}`,
        },
        {
            text: "Modules",
            selected:
                !detailsSelected &&
                location.pathname.startsWith(`${detailsHref}/modules`),
            icon: <ViewModuleIcon />,
            href: `${detailsHref}/modules`,
        },
        {
            text: "Users",
            selected:
                !detailsSelected &&
                location.pathname.startsWith(`${detailsHref}/users`),
            icon: <GroupIcon />,
            href: `${detailsHref}/users`,
        },
        {
            text: "Projects Grading",
            selected:
                !detailsSelected &&
                location.pathname.startsWith(`${detailsHref}/grades`),
            icon: <GradeIcon />,
            href: `${detailsHref}/grades`,
        },
    ];

    return (
        <Container
            drawerItems={drawerItems}
            appBarTitle={title}
            drawerBackButton={DRAWER_BACK_BUTTON}
        >
            <Outlet context={{ courseId, setCourseTitle, moduleId }} />
        </Container>
    );
}

const DRAWER_BACK_BUTTON = { title: "Courses", href: "/courses" };
