import { Dispatch } from "redux";
import {
    CourseSubmissionsState,
    FetchCourseSubmissions,
    CourseSubmissionsActionType,
    UpdateCourseSubmission,
} from "./types";
import { ProjectSubmission } from "../../../types";

export const fetchCourseSubmissionsCreator = (
    payload: CourseSubmissionsState
) => {
    return (dispatch: Dispatch<FetchCourseSubmissions>) => {
        dispatch({
            value: payload,
            type: CourseSubmissionsActionType.FetchCourseSubmissions,
        });
    };
};

export const updateCourseSubmissionCreator = (payload: ProjectSubmission) => {
    return (dispatch: Dispatch<UpdateCourseSubmission>) => {
        dispatch({
            value: payload,
            type: CourseSubmissionsActionType.UpdateCourseSubmission,
        });
    };
};
