import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { useParams } from "react-router-dom";
import Container from "../../components/Container";
import { fetchFeedbackDetailsCreator } from "../../redux/feedback/actions";
import { AppState } from "../../redux/store";
import { drawerItems } from "./Feedbacks";
import { FeedbackDetails } from "../../redux/feedback/types";
import { getRequest } from "../../utils/http";
import QuestionTestDisplay from "../../components/questions/QuestionTestDisplay";
import FeedbackDetailsTable from "../../components/feedbacks/details/Table";
import { NOT_FOUND } from "http-status";

type Props = PropsFromRedux & { testId: number };

class FedbackQuestionDetailsCCC extends Component<Props> {
    componentDidMount(): void {
        const { testId, details, fetch } = this.props;

        if (isNaN(testId)) {
            return fetch(null);
        }

        if (
            details === null ||
            (typeof details === "object" && details.id !== testId)
        ) {
            this.fetchFeedbacksDetails();
        }
    }

    fetchFeedbacksDetails = async () => {
        const { fetch, testId } = this.props;

        fetch("loading");

        try {
            const details = await getRequest<FeedbackDetails>(
                `/feedback/${testId}`
            );

            fetch(details);
        } catch (error: any) {
            if (error.response && error.response.status === NOT_FOUND) {
                return fetch(null);
            }

            fetch("failed");
        }
    };

    render() {
        const { details } = this.props;

        return (
            <Container
                drawerItems={drawerItems}
                appBarTitle="Feedbacks"
                isLoading={details === "loading"}
                failed={details === "failed"}
                onFail={this.fetchFeedbacksDetails}
            >
                {details && typeof details === "object" && (
                    <Stack className="content-min-width" spacing={3}>
                        <Typography variant="h6">Feedback details</Typography>
                        <QuestionTestDisplay questionTest={details} />
                        <FeedbackDetailsTable feedbacks={details.feedbacks} />
                    </Stack>
                )}
                {details === null && (
                    <Typography
                        fontWeight="bold"
                        variant="h5"
                        textAlign="center"
                    >
                        Feedback Doesn't exist
                    </Typography>
                )}
            </Container>
        );
    }
}

function FedbackQuestionDetails(props: Omit<Props, "testId">) {
    const testId = Number(useParams().testId);

    return <FedbackQuestionDetailsCCC {...props} testId={testId} />;
}

const mapDispatchToProps = {
    fetch: fetchFeedbackDetailsCreator,
};

function mapStateToProps(state: AppState) {
    return { details: state.feedbacks.details };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(FedbackQuestionDetails);
