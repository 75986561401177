import {
    defaultQuestionState,
    QuestionState,
    QuestionActionType,
    QuestionAction,
} from "../types/question";

function questionReducer(
    state: QuestionState = defaultQuestionState,
    action: QuestionAction
    // action: any,
): QuestionState {
    switch (action.type) {
        case QuestionActionType.FetchQuestion:
            state = {
                ...state,
                ...action.value,
            };
            return state;

        case QuestionActionType.AddQuestion:
            state = {
                ...state,
                questions: [action.value, ...state.questions],
            };
            return state;

        case QuestionActionType.FetchQuestionDetails:
            state = {
                ...state,
                questionDetails: {
                    ...state.questionDetails,
                    ...action.value,
                },
            };
            return state;

        case QuestionActionType.EditQuestionDetails:
            const questions = state.questions.slice();

            const index = questions.findIndex(
                (question) => question.id === action.value.id
            );

            if (index >= 0) {
                questions[index] = {
                    ...questions[index],
                    title: action.value.title,
                };
            }

            state = {
                ...state,
                questions: [...questions],
                questionDetails: {
                    ...state.questionDetails,
                    details: {
                        ...state.questionDetails.details,
                        ...action.value,
                    },
                },
            };

            return state;

        case QuestionActionType.AddQuestionTest:
            state = {
                ...state,
                questionDetails: {
                    ...state.questionDetails,
                    details: {
                        ...state.questionDetails.details!,
                        tests: [
                            ...state.questionDetails.details!.tests,
                            action.value,
                        ],
                    },
                },
            };

            return state;

        case QuestionActionType.UpdateQuestionTest: {
            const tests = state.questionDetails.details!.tests.slice();

            const index = state.questionDetails.details!.tests.findIndex(
                (question) => question.id === action.value.id
            );

            if (index >= 0) {
                tests[index] = {
                    ...tests[index],
                    ...action.value,
                };
            }

            state = {
                ...state,
                questionDetails: {
                    ...state.questionDetails,
                    details: {
                        ...state.questionDetails.details!,
                        tests: [...tests],
                    },
                },
            };

            return state;
        }

        default:
            return state;
    }
}

export default questionReducer;
