import React, { Component } from "react";
import Container from "../../components/Container";
import {
    COURSES,
    DOCS,
    FEEDBACKS,
    QUESTIONS,
    SETTINGS,
} from "../../data/navigation.data";
import Stack from "@mui/material/Stack";
import { AuthContext } from "../../auth.context";
import { AuthContextType, DrawerLink } from "../../types";
import { AdminRole } from "@teyalite/hackbio-common/dist/types/admin-role.enum";
import { connect, ConnectedProps } from "react-redux";
import NewCourseForm from "../../components/courses/NewCourseForm";
import { AppState } from "../../redux/store";
import Table from "../../components/courses/Table";
import {
    addCourseCreator,
    fetchCourseCreator,
} from "../../redux/actions/course";
import { getRequest } from "../../utils/http";
import { fetchSettingCreator } from "../../redux/actions/setting";

type Props = PropsFromRedux & {};

type State = {
    drawerItems: DrawerLink[];
    open: boolean;
};

class Courses extends Component<Props, State> {
    static contextType = AuthContext;

    constructor(props: Props) {
        super(props);
        this.state = {
            drawerItems: [{ ...COURSES, selected: true }, QUESTIONS, DOCS],
            open: false,
        };
    }

    componentDidMount(): void {
        const user = (this.context as AuthContextType).user;

        // Add settings to side bar if it doesn't exist already
        if (
            user?.role.includes(AdminRole.Super) &&
            this.state.drawerItems.findIndex(
                (item) => item.href === SETTINGS.href
            ) < 0
        ) {
            this.setState({
                drawerItems: [...this.state.drawerItems, SETTINGS, FEEDBACKS],
            });
        }

        const { courses, isLoading } = this.props;

        if (!isLoading && courses.length === 0) {
            this.fetchCourse();
        }
    }

    fetchCourse = async () => {
        const { fetch, fetchSetting, setting } = this.props;

        fetch({
            isLoading: true,
            failed: false,
            courses: [],
        });

        try {
            const courses = await getRequest("/course");
            const badges = await getRequest("/setting/badge");
            const biostacks = await getRequest("/setting/biostack");

            fetchSetting({ ...setting, badges, biostacks });

            fetch({
                isLoading: false,
                failed: false,
                courses,
            });
        } catch (error) {
            fetch({
                isLoading: true,
                failed: true,
                courses: [],
            });
        }
    };

    onAdd = () => {
        this.setState({ open: true });
    };

    onClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { drawerItems, open } = this.state;
        const { courses, isLoading, failed, biostacks, addCourse } = this.props;

        return (
            <Container
                drawerItems={drawerItems}
                appBarTitle="Courses"
                isLoading={isLoading}
                failed={failed}
                onFail={this.fetchCourse}
            >
                <Stack className="content-min-width" spacing={5}>
                    <Table courses={courses} onAdd={this.onAdd} />
                    <NewCourseForm
                        biostacks={biostacks}
                        addCourse={addCourse}
                        open={open}
                        onClose={this.onClose}
                    />
                </Stack>
            </Container>
        );
    }
}
const mapDispatchToProps = {
    fetch: fetchCourseCreator,
    addCourse: addCourseCreator,
    fetchSetting: fetchSettingCreator,
};

function mapStateToProps(state: AppState) {
    return {
        ...state.course,
        biostacks: state.setting.biostacks,
        badges: state.setting.badges,
        setting: state.setting,
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Courses);
// todo: reorder course table whenever a course gets edited
