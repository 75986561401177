import React from "react";

// todo: youtube video https://stackoverflow.com/questions/59025419/how-to-find-out-when-a-youtube-embedded-iframe-is-loaded
export default function VideoShow({
    src,
    title,
}: {
    src: string;
    title: string;
}) {
    const handleLoad = async () => {
        console.log("LOADED VIDEO");
    };

    return (
        <iframe
            width="760"
            height="473"
            allowFullScreen
            src={src}
            title={title}
            onLoad={handleLoad}
        ></iframe>
    );
}
