import { CONNECTION_FAILED } from "../../constants";
import { Badge } from "../../types";
import { postRequest } from "../../utils/http";
import { Button, Stack, Typography } from "@mui/material";
import {
    IMAGE_ALLOWED_MIME_TYPES,
    IMAGE_ALLOWED_TYPES,
    IMAGE_SIZE_LIMIT,
} from "@teyalite/hackbio-common/dist/constants";
import { requiredErrorMessage } from "@teyalite/hackbio-common/dist/error-messages";
import { CSSProperties, ChangeEvent, Component } from "react";
import DialogForm from "../DialogForm";
import ImageField from "../ImageField";
import Input from "../Input";

// todo: title max 60 characters
// todo: check title length validation

const TITLE_ERROR = requiredErrorMessage("Title");
const IMAGE_ERROR = requiredErrorMessage("Image");

type Props = {
    badges: Badge[];
    onAddbadge: (badge: Badge) => void;
};

type State = {
    open: boolean;
    title: string;
    level: string;

    imageSrc: string | undefined;
    imageFile: File | undefined;

    isLoading: boolean;
    triggered: boolean;
};

const defaultState: State = {
    open: false,
    title: "",
    level: "",
    imageSrc: undefined,
    imageFile: undefined,
    isLoading: false,
    triggered: false,
};

export default class SettingsBadges extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            ...defaultState,
        };
    }

    // Called when badge image value changes
    onImageChange = ({
        imageFile,
        imageSrc,
    }: {
        imageSrc: string | undefined;
        error: boolean;
        imageFile: undefined | File;
    }) => {
        this.setState({
            imageFile,
            imageSrc,
        });
    };

    onAdd = () => this.setState({ open: true });

    onClose = () => {
        if (this.state.isLoading) return;
        this.setState({ open: false, triggered: false });
    };

    onSubmit = async () => {
        const { title, level, imageFile } = this.state;
        const { onAddbadge } = this.props;

        if (title.trim().length === 0 || imageFile === undefined) {
            return this.setState({
                triggered: true,
            });
        }

        this.setState({ isLoading: true });

        const formData = new FormData();

        formData.append("title", title);

        if (level.trim()) {
            formData.append("level", level.trim());
        }

        formData.append("image", imageFile);

        try {
            const createdBadge = await postRequest("/setting/badge", formData);
            onAddbadge(createdBadge as Badge);
            this.setState({ ...defaultState });
        } catch (error) {
            this.setState({ isLoading: false });
            window.alert(CONNECTION_FAILED);
        }
    };

    onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            title: e.currentTarget.value,
        });
    };

    onChangeLevel = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            level: e.currentTarget.value,
        });
    };

    render() {
        const { isLoading, title, level, imageSrc, triggered, open } =
            this.state;

        const { badges } = this.props;

        return (
            <Stack spacing={3}>
                <Stack
                    direction="row"
                    alignItems="center"
                    justifyContent="space-between"
                >
                    <Typography variant="h5" fontWeight="bold">
                        Badges
                    </Typography>
                    <Button
                        variant="contained"
                        disableElevation
                        onClick={this.onAdd}
                    >
                        Add new badge
                    </Button>
                </Stack>

                <Stack direction="row" flexWrap="wrap" width="100%">
                    {badges.map(({ title, id, image, level }) => (
                        <Stack
                            key={id}
                            alignItems="center"
                            spacing={1.5}
                            mr={5}
                            mt={4}
                        >
                            <img
                                src={image}
                                alt={title}
                                width={100}
                                height={100}
                            />
                            <Typography>{`${title}${
                                level ? " - " + level : ""
                            }`}</Typography>
                        </Stack>
                    ))}
                </Stack>

                <DialogForm
                    open={open}
                    title="Add New Badge"
                    submitButtonText="Add"
                    onClose={this.onClose}
                    onSubmit={this.onSubmit}
                    isLoading={isLoading}
                >
                    <Stack spacing={2}>
                        <Input
                            placeholder="Title"
                            size="medium"
                            value={title}
                            onChange={this.onChangeTitle}
                            error={triggered && title.trim().length === 0}
                            errorMessage={TITLE_ERROR}
                            sx={styles.input}
                        />
                        <Input
                            placeholder="Level"
                            size="medium"
                            value={level}
                            onChange={this.onChangeLevel}
                            sx={styles.input}
                        />
                        <ImageField
                            placeholder="Upload Badge Image"
                            onChange={this.onImageChange}
                            imageSrc={imageSrc}
                            error={imageSrc === undefined && triggered}
                            errorMessage={IMAGE_ERROR}
                            allowedTypes={IMAGE_ALLOWED_TYPES}
                            allowedMimeTypes={IMAGE_ALLOWED_MIME_TYPES}
                            sizeLimit={IMAGE_SIZE_LIMIT}
                            invalidTypeMessage="Invalid image type"
                            sizeLimitMessage="Image size too large"
                            helperMessage={
                                imageSrc !== undefined
                                    ? "Click badge image to change it"
                                    : ""
                            }
                            imageProcessFailMessage="Image processing failed"
                            imageStyle={styles.imageStyle}
                        />
                    </Stack>
                </DialogForm>
            </Stack>
        );
    }
}

const styles = {
    input: {
        maxWidth: "500px",
        alignSelf: "center",
        width: "100%",
    },
    imageStyle: {
        width: "100px",
        height: "100px",
    } as CSSProperties,
};
