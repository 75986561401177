import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Badge } from "../../types";

type Props = {
    syllabus: { title: string; submodules: string[] }[];
    badges: Badge[];
};

export default function CBSyllabus({ syllabus, badges }: Props) {
    return (
        <Box width={"100%"} id="syllabus">
            <Stack
                component={Container}
                direction="column"
                spacing={{ xs: 4, md: 4 }}
                pt={8}
                pb={8}
            >
                <Typography variant="h5" fontWeight="bold">
                    Course Syllabus
                </Typography>
                <Stack>
                    {syllabus.map(({ title, submodules }, index) => (
                        <Stack key={index} spacing={2}>
                            <Typography
                                fontWeight="bold"
                                fontSize={{ xs: "1rem", sm: "1.2rem" }}
                            >
                                {title}
                            </Typography>
                            <Stack component="ul">
                                {submodules.map((item, index) => (
                                    <Typography
                                        key={"module-list-" + index}
                                        component="li"
                                        ml={3}
                                        mb={2}
                                    >
                                        {item}
                                    </Typography>
                                ))}
                            </Stack>
                        </Stack>
                    ))}
                </Stack>
                <Stack spacing={3}>
                    <Typography variant="h5" fontWeight="bold">
                        Technologies you will use
                    </Typography>
                    <Stack direction="row" flexWrap="wrap">
                        {badges.map(({ title, image }, index) => (
                            <Stack
                                key={"Technologies-" + index}
                                alignItems="center"
                                spacing={1.5}
                                mr={4}
                                mt={2}
                            >
                                <img
                                    src={image}
                                    alt={title}
                                    width={100}
                                    height={100}
                                />
                                <Typography>{title}</Typography>
                            </Stack>
                        ))}
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}
