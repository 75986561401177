import {
    defaultSettingState,
    SettingState,
    SettingActionType,
    SettingAction,
} from "../types/setting";

function settingReducer(
    state: SettingState = defaultSettingState,
    action: SettingAction
    // action: any,
): SettingState {
    switch (action.type) {
        case SettingActionType.FetchSetting:
            state = {
                ...state,
                ...action.value,
            };
            return state;

        case SettingActionType.AddBadge:
            state = {
                ...state,
                badges: [action.value, ...state.badges],
            };
            return state;

        case SettingActionType.AddBiostack:
            state = {
                ...state,
                biostacks: [...state.biostacks, action.value],
            };
            return state;

        default:
            return state;
    }
}

export default settingReducer;
