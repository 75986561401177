import React from "react";
import IconButton from "@mui/material/IconButton";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Edit from "@mui/icons-material/Edit";

type Props = {
    title: string;
    onClick: () => void;
};

export default function EditHead({ title, onClick }: Props) {
    return (
        <Stack direction="row" alignItems="center" width="100%">
            <Typography variant="h6" fontWeight="bold" flexGrow={1}>
                {title}
            </Typography>
            <IconButton onClick={onClick}>
                <Edit />
            </IconButton>
        </Stack>
    );
}
