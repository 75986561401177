import Stack from "@mui/material/Stack";
import { AdminRole } from "@teyalite/hackbio-common/dist/types/admin-role.enum";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { AuthContext } from "../../auth.context";
import Container from "../../components/Container";
import QuestionForm from "../../components/questions/QuestionForm";
import Table from "../../components/questions/Table";
import { CONNECTION_FAILED } from "../../constants";
import {
    COURSES,
    DOCS,
    FEEDBACKS,
    QUESTIONS,
    SETTINGS,
} from "../../data/navigation.data";
import {
    addQuestionCreator,
    fetchQuestionCreator,
} from "../../redux/actions/question";
import { AppState } from "../../redux/store";
import { AuthContextType, DrawerLink, Question } from "../../types";
import { getRequest, postRequest } from "../../utils/http";

type Props = PropsFromRedux & {};

type State = {
    drawerItems: DrawerLink[];
    isCreatingQuestion: boolean;
    openQuestionForm: boolean;
};

class Questions extends Component<Props, State> {
    static contextType = AuthContext;

    constructor(props: Props) {
        super(props);
        this.state = {
            drawerItems: [COURSES, { ...QUESTIONS, selected: true }, DOCS],
            isCreatingQuestion: false,
            openQuestionForm: false,
        };
    }

    componentDidMount(): void {
        const user = (this.context as AuthContextType).user;

        // Add settings to side bar if it doesn't exist already
        if (
            user?.role.includes(AdminRole.Super) &&
            this.state.drawerItems.findIndex(
                (item) => item.href === SETTINGS.href
            ) < 0
        ) {
            this.setState({
                drawerItems: [...this.state.drawerItems, SETTINGS, FEEDBACKS],
            });
        }

        const { questions, isLoading } = this.props;

        if (!isLoading && questions.length === 0) {
            this.fetchQuestion();
        }
    }

    fetchQuestion = async () => {
        const { fetch } = this.props;

        fetch({
            isLoading: true,
            failed: false,
            questions: [],
        });

        try {
            const questions = await getRequest("/question");

            fetch({
                isLoading: false,
                failed: false,
                questions,
            });
        } catch (error) {
            fetch({
                isLoading: true,
                failed: true,
                questions: [],
            });
        }
    };

    handleSubmitCreateQuestion = async (title: string) => {
        this.setState({ isCreatingQuestion: true });

        try {
            const question = await postRequest<Question>("/question", {
                title,
            });

            this.props.addQuestion(question);
            this.setState({ openQuestionForm: false });
        } catch (error) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isCreatingQuestion: false });
    };

    render() {
        const { drawerItems, isCreatingQuestion, openQuestionForm } =
            this.state;

        const { failed, isLoading, questions } = this.props;

        return (
            <Container
                drawerItems={drawerItems}
                appBarTitle="Questions"
                isLoading={isLoading}
                failed={failed}
                onFail={this.fetchQuestion}
            >
                <Stack className="content-min-width" spacing={5}>
                    {openQuestionForm && (
                        <QuestionForm
                            isLoading={isCreatingQuestion}
                            defaultTitle="Untitle Question"
                            onSubmit={this.handleSubmitCreateQuestion}
                            onClose={() =>
                                this.setState({ openQuestionForm: false })
                            }
                        />
                    )}
                    <Table
                        onAdd={() => this.setState({ openQuestionForm: true })}
                        questions={questions}
                    />
                </Stack>
            </Container>
        );
    }
}

const mapDispatchToProps = {
    fetch: fetchQuestionCreator,
    addQuestion: addQuestionCreator,
};

function mapStateToProps(state: AppState) {
    return { ...state.question };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Questions);
