import Backdrop from "@mui/material/Backdrop";
import React from "react";
import Loading from "./LoadingIndicator";

export default function BackdropLoading({ open }: { open: boolean }) {
    return (
        <Backdrop
            sx={{
                color: "white",
                zIndex: (theme) => theme.zIndex.drawer + 1000000,
                // position: "absolute",
            }}
            open={open}
        >
            <Loading />
        </Backdrop>
    );
}
