import { FILE_SIZE_UNIT } from './constants';

/**
 * Extract the extension
 * @param filename filename string
 * @returns the extension
 */
export function fileExtension(filename: string): string {
  const extension = filename.split('.');
  return '.' + extension[extension.length - 1].toLowerCase();
}

/**
 * File size
 * @param size size
 * @returns
 */
export function fileSize(size: number): string {
  let index: number = 0;

  while (size > 900) {
    size /= 1024;
    ++index;
  }

  return Math.round(size * 100) / 100 + ' ' + FILE_SIZE_UNIT[index];
}
