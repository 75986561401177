import SaveIcon from "@mui/icons-material/Save";
import { Button, SelectChangeEvent } from "@mui/material";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import {
    IMAGE_ALLOWED_MIME_TYPES,
    IMAGE_ALLOWED_TYPES,
    IMAGE_SIZE_LIMIT,
} from "@teyalite/hackbio-common/dist/constants";
import { fileExtension } from "@teyalite/hackbio-common/dist/file-helper";
import { isEmpty, isEqual } from "lodash";
import {
    CSSProperties,
    ChangeEvent,
    Component,
    RefObject,
    createRef,
} from "react";
import { ConnectedProps, connect } from "react-redux";
import BackdropLoading from "../../components/BackdropLoading";
import DialogForm from "../../components/DialogForm";
import Input from "../../components/Input";
import Loading from "../../components/Loading";
import MultipleSelectPlaceholder from "../../components/Multiselect";
import Select from "../../components/Select";
import ImageContainer from "../../components/courses/ImageContainer";
import TextEditContainer from "../../components/courses/TextEditContainer";
import DialogEditor from "../../components/editor/DialogEditor";
import EditorPreview from "../../components/editor/EditorPreview";
import { CONNECTION_FAILED, DEFAULT_EDITOR_STATE } from "../../constants";
import {
    fetchCourseDetailsCreator,
    updateCourseDetailsCreator,
} from "../../redux/actions/course-details";
import { fetchSettingCreator } from "../../redux/actions/setting";
import { AppState } from "../../redux/store";
import {
    Badge,
    Biostack,
    CourseDetails as CourseDetailsType,
    RichHTML,
} from "../../types";
import { getRequest, postRequest, putRequest } from "../../utils/http";
import { withCourseId } from "../../utils/with-course";
import VideoShow from "../../components/VideoShow";
import { fetchCourseModulesCreator } from "../../redux/actions/course-module";
import { Preview } from "@mui/icons-material";
import CoursePreview from "../../components/courses/CoursePreview";
import { updateCourseCreator } from "../../redux/actions/course";

type Props = PropsFromRedux & {
    courseId: number;
    setCourseTitle: (titl: string) => void;
};

type State = {
    onCloseEditor: () => void;
    onSubmitEditor: (editorState: any, htmlString: string) => void;
    openPreview: boolean;
    openEditor: boolean;
    openVideo: boolean;
    editorState: any;
    editorTitle: string;

    openHighlightList: boolean;
    highlighListString: string;
    video: string;

    isEditingCourse: boolean;

    changes: {
        title?: string;
        biostack?: Biostack;
        price?: string;
        badges?: Badge[];
        fakePrice?: string;
        syllabus?: string;
        video?: string;
        highlightTitle?: string;
        highlights?: string[];
        about?: RichHTML;
        prerequisite?: RichHTML;
        estimatedTime?: string;
        smallImage?: string;
        largeImage?: string;
        smallImageFile?: File;
        largeImageFile?: File;
    };
};

class CourseDetails extends Component<Props, State> {
    private imageInput: RefObject<HTMLInputElement>; // reference to the input element for image
    private mounted: boolean;
    private size: "large" | "small";
    private aboutKey: number;
    private prerequisiteKey: number;

    constructor(props: Props) {
        super(props);
        this.state = {
            openPreview: false,
            changes: {},
            video: "",
            openHighlightList: false,
            openVideo: false,
            highlighListString: "",
            isEditingCourse: false,

            openEditor: false,
            editorState: DEFAULT_EDITOR_STATE,
            editorTitle: "",
            onCloseEditor: () => {},
            onSubmitEditor: () => {},
        };

        this.imageInput = createRef();
        this.mounted = false;
        this.size = "small";
        this.aboutKey = 0;
        this.prerequisiteKey = 0;
    }

    componentDidMount() {
        this.mounted = true;
        const { isLoading } = this.props;
        if (!isLoading) {
            this.fetchCourseDetails();
        }
    }

    componentWillUnmount() {
        this.mounted = false;
    }

    fetchCourseDetails = async () => {
        const {
            fetchCourseDetails,
            courseId,
            courseDetails,
            fetchSetting,
            fetchCourseModules,
            setting,
        } = this.props;

        fetchCourseDetails({ failed: false, isLoading: true, courseDetails });

        try {
            const fetchedCourseDetails = await getRequest<CourseDetailsType>(
                "/course/details/" + courseId
            );

            const badges = await getRequest("/setting/badge");
            const biostacks = await getRequest("/setting/biostack");
            // await sleep(0.5);

            this.setState({
                highlighListString:
                    fetchedCourseDetails.highlights.join("\r\n"),
            });

            fetchSetting({ ...setting, badges, biostacks });

            fetchCourseDetails({
                failed: false,
                isLoading: false,
                courseDetails: fetchedCourseDetails,
            });

            fetchCourseModules({
                isLoading: false,
                failed: false,
                modules: [],
            });
        } catch (error: any) {
            fetchCourseDetails({
                failed: false,
                isLoading: false,
                courseDetails: null,
            });

            fetchCourseModules({
                isLoading: true,
                failed: false,
                modules: [],
            });
        }
    };

    onCloseEditor = () => {
        this.setState({
            openEditor: false,
            editorState: DEFAULT_EDITOR_STATE,
        });
    };

    onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            changes: {
                ...this.state.changes,
                title: e.currentTarget.value,
            },
        });
    };

    onChangeBiostack = (event: SelectChangeEvent) => {
        const { allBiostacks } = this.props;

        this.setState({
            changes: {
                ...this.state.changes,
                biostack: allBiostacks.find(
                    (value) => Number(event.target.value) === value.id
                ),
            },
        });
    };

    onChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            changes: {
                ...this.state.changes,
                price: e.currentTarget.value,
            },
        });
    };

    onChangeFakePrice = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            changes: {
                ...this.state.changes,
                fakePrice: e.currentTarget.value,
            },
        });
    };

    onChangeSyllabus = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            changes: {
                ...this.state.changes,
                syllabus: e.currentTarget.value,
            },
        });
    };

    onChangeHighlightTitle = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            changes: {
                ...this.state.changes,
                highlightTitle: e.currentTarget.value,
            },
        });
    };

    onHighlightList = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            highlighListString: e.currentTarget.value,
        });
    };

    onHighlightListClose = () => {
        this.setState({
            openHighlightList: false,
        });
    };

    onHighlightListSave = () =>
        this.setState({
            openHighlightList: false,
            changes: {
                ...this.state.changes,
                highlights: this.state.highlighListString
                    .split(/\r|\r|\n/g)
                    .filter((str) => Boolean(str)),
            },
        });

    onChangeEstimatedTime = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            changes: {
                ...this.state.changes,
                estimatedTime: e.currentTarget.value,
            },
        });
    };

    onVideoClose = () => {
        this.setState({
            openVideo: false,
        });
    };

    onVideoSave = () =>
        this.setState({
            openVideo: false,
            changes: {
                ...this.state.changes,
                video: this.state.video,
            },
        });

    /**
     * When about changes
     */
    onAboutCourse = () => {
        this.setState({
            openEditor: true,
            editorTitle: "Edit about the course",
            editorState:
                this.state.changes.about?.lexicalState ?? DEFAULT_EDITOR_STATE,

            onSubmitEditor: (editorState, htmlString) => {
                this.aboutKey += 1;

                this.setState({
                    changes: {
                        ...this.state.changes,
                        about: {
                            html: htmlString,
                            lexicalState: editorState,
                        },
                    },
                    openEditor: false,
                    editorState: DEFAULT_EDITOR_STATE,
                });
            },
        });
    };

    onChangeBadges = (event: SelectChangeEvent<string[]>) => {
        const { allBadges } = this.props;

        const {
            target: { value },
        } = event;

        this.setState({
            changes: {
                ...this.state.changes,
                badges: (typeof value === "string" ? value.split(",") : value)
                    .map((sv) =>
                        allBadges.find((badge) => badge.id === Number(sv))
                    )
                    .filter((badge) => badge !== undefined) as Badge[],
            },
        });
    };

    /**
     * When prerequisite changes
     */
    onPrerequisite = () => {
        this.setState({
            openEditor: true,
            editorTitle: "Edit course prerequisites",
            editorState:
                this.state.changes.prerequisite?.lexicalState ??
                DEFAULT_EDITOR_STATE,

            onSubmitEditor: (editorState, htmlString) => {
                this.prerequisiteKey += 1;

                this.setState({
                    changes: {
                        ...this.state.changes,
                        prerequisite: {
                            html: htmlString,
                            lexicalState: editorState,
                        },
                    },
                    openEditor: false,
                    editorState: DEFAULT_EDITOR_STATE,
                });
            },
        });
    };

    /**
     * Call back to set the images
     */
    onChangeImage = (size: "small" | "large") => () => {
        this.size = size;

        if (this.imageInput !== null && this.imageInput.current) {
            this.imageInput.current.click();
        }
    };

    // Handle Input File process
    // handle only one file selection
    handleFileInput = (event: ChangeEvent<HTMLInputElement>) => {
        if (!event.target.files || event.target.files.length < 1) {
            return;
        }

        const file = event.target.files[0];

        if (
            !IMAGE_ALLOWED_TYPES.includes(fileExtension(file.name)) ||
            !IMAGE_ALLOWED_MIME_TYPES.includes(file.type)
        ) {
            window.alert("invalidTypeMessage");
            return;
        }

        if (file.size > IMAGE_SIZE_LIMIT) {
            window.alert("sizeLimitMessage");
            return;
        }

        const reader = new FileReader();

        // When successfylly loaded
        reader.onload = (event: ProgressEvent<FileReader>) => {
            if (!this.mounted) return;

            if (this.size === "small") {
                this.setState({
                    changes: {
                        ...this.state.changes,
                        smallImage: reader.result as string,
                        smallImageFile: file,
                    },
                });
            } else {
                this.setState({
                    changes: {
                        ...this.state.changes,
                        largeImage: reader.result as string,
                        largeImageFile: file,
                    },
                });
            }
        };

        // When error occurs
        reader.onerror = (event: ProgressEvent<FileReader>) => {
            if (!this.mounted) return;

            window.alert("imageProcessFailMessage");
        };

        reader.readAsDataURL(file);
    };

    onSaveChanges = async () => {
        const { changes } = this.state;
        const {
            allBiostacks,
            courseDetails,
            courseId,
            setCourseTitle,
            updateCourseDetails,
            updateCourse,
        } = this.props;

        if (courseDetails === null) return;

        // todo: check if changes values are the same
        if (isEmpty(changes)) {
            return window.alert("Changes saved");
        }

        const fd = new FormData();

        if (changes.largeImageFile) {
            fd.append("largeImageFile", changes.largeImageFile);
        }

        if (changes.smallImageFile) {
            fd.append("smallImageFile", changes.smallImageFile);
        }

        if (changes.prerequisite) {
            fd.append("prerequisite", JSON.stringify(changes.prerequisite));
        }

        if (changes.estimatedTime) {
            fd.append("estimatedTime", changes.estimatedTime);
        }

        if (changes.badges) {
            fd.append(
                "badges",
                JSON.stringify(changes.badges.map((badge) => badge.id))
            );
        }

        if (changes.about) {
            fd.append("about", JSON.stringify(changes.about));
        }

        if (
            changes.highlights &&
            !isEqual(changes.highlights, courseDetails.highlights)
        ) {
            fd.append("highlights", JSON.stringify(changes.highlights));
        }

        if (changes.highlightTitle) {
            fd.append("highlightTitle", changes.highlightTitle);
        }

        if (changes.syllabus) {
            fd.append("syllabus", changes.syllabus);
        }

        if (changes.video) {
            fd.append("video", changes.video);
        }

        if (changes.fakePrice) {
            fd.append("fakePrice", changes.fakePrice);
        }

        if (changes.price) {
            fd.append("price", changes.price);
        }

        if (changes.biostack) {
            fd.append("biostack", String(changes.biostack.id));
        }

        if (changes.title) {
            fd.append("title", changes.title);
        }

        this.setState({ isEditingCourse: true });

        try {
            const pathname = `/course/${courseId}`;

            const updatedCourse = await putRequest<CourseDetailsType>(
                pathname,
                fd
            );

            const biostack = allBiostacks.find(
                (biostack) => updatedCourse.biostack.id === biostack.id
            );

            setCourseTitle(updatedCourse.title);

            updateCourse({
                courseId,
                course: {
                    title: updatedCourse.title,
                    ...(biostack ? { biostack } : {}),
                    price: updatedCourse.price,
                },
            });
            this.setState({ changes: {} });

            updateCourseDetails(updatedCourse);
        } catch (error) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isEditingCourse: false });
    };

    handlePreview = () => {
        this.setState({ openPreview: true });
    };

    handlePublish = async () => {
        const confirm = window.confirm(
            "Are you sure you want to publish this course ?"
        );

        if (!confirm) return;

        const { courseId, updateCourseDetails, updateCourse, courseDetails } =
            this.props;

        this.setState({ isEditingCourse: true });

        if (courseDetails === null) {
            return null;
        }

        // todo: handle failed case
        try {
            await postRequest("/course/" + courseId + "/publish", {});
            updateCourseDetails({ ...courseDetails, published: true });
            updateCourse({ courseId, course: { published: true } });
            window.alert("Course has been published");
        } catch (error: any) {
            window.alert(CONNECTION_FAILED);
        }

        this.setState({ isEditingCourse: false });
    };

    render() {
        const {
            courseDetails,
            isLoading,
            failed,
            allBadges,
            allBiostacks,
            courseId,
        } = this.props;

        const {
            changes,
            openEditor,
            openHighlightList,
            highlighListString,
            isEditingCourse,
            openPreview,
            openVideo,
        } = this.state;

        if (isLoading || failed || courseDetails === null) {
            return (
                <Stack flexGrow={1} alignItems="center" justifyContent="center">
                    <Loading
                        failed={failed}
                        onRetry={this.fetchCourseDetails}
                    />
                </Stack>
            );
        }

        return (
            <Stack
                className="content-min-width"
                spacing={4}
                pt={4}
                sx={{ position: "relative" }}
            >
                {isEditingCourse && <BackdropLoading open />}
                {openPreview && (
                    <CoursePreview
                        onClose={() => this.setState({ openPreview: false })}
                        details={courseDetails}
                        courseId={courseId}
                    />
                )}
                <Stack
                    sx={{
                        // width: "100%",
                        borderBottom: "1px rgb(0 0 0 / 8%) solid",
                        position: "fixed",
                        top: 77,
                        right: 15,
                        left: 265,
                        backgroundColor: "white",
                        zIndex: 1000000,
                        py: 1,
                        pr: 1,
                    }}
                    direction="row"
                    justifyContent="space-between"
                    spacing={20}
                >
                    <Button
                        variant="outlined"
                        size="large"
                        startIcon={<SaveIcon />}
                        onClick={this.onSaveChanges}
                    >
                        Save Changes
                    </Button>

                    <Stack direction="row" spacing={2}>
                        <Button
                            variant="outlined"
                            size="large"
                            startIcon={<Preview />}
                            onClick={this.handlePreview}
                        >
                            Preview
                        </Button>

                        <Button
                            variant="contained"
                            size="large"
                            disabled={courseDetails.published}
                            onClick={this.handlePublish}
                            disableElevation
                            sx={{ fontWeight: "bold" }}
                        >
                            Publish
                        </Button>
                    </Stack>
                </Stack>

                <DialogForm
                    title={"Course Video"}
                    open={openVideo}
                    key={"COURSE VIDEO"}
                    onClose={this.onVideoClose}
                    onSubmit={this.onVideoSave}
                    submitButtonText="Save"
                >
                    <Input
                        placeholder="Course video embed url"
                        size="medium"
                        value={this.state.video}
                        onChange={(e: ChangeEvent<HTMLInputElement>) => {
                            this.setState({
                                video: e.currentTarget.value,
                            });
                        }}
                        sx={{
                            maxWidth: "500px",
                            alignSelf: "center",
                            width: "100%",
                        }}
                    />
                </DialogForm>

                {/* Hight light list dialog form */}
                <DialogForm
                    title={"Course Highlight List"}
                    open={openHighlightList}
                    key={"HIGHLIGHT LIST"}
                    onClose={this.onHighlightListClose}
                    PaperSx={{ minWidth: "600px" }}
                    onSubmit={this.onHighlightListSave}
                    submitButtonText="Save"
                >
                    <Input
                        placeholder="Course Highlight List"
                        size="medium"
                        value={highlighListString}
                        multiline
                        onChange={this.onHighlightList}
                        sx={styles.HighlightInput}
                    />
                </DialogForm>

                {/* Rich text editor dialog form: for prerequisite & about */}
                {openEditor && (
                    <DialogEditor
                        open={true}
                        initialState={this.state.editorState}
                        title={this.state.editorTitle}
                        onClose={this.onCloseEditor}
                        onSubmit={this.state.onSubmitEditor}
                        isLoading={false}
                    />
                )}

                {/* Image input for small & large images */}
                <input
                    type="file"
                    ref={this.imageInput}
                    hidden
                    onChange={this.handleFileInput}
                />

                <Stack direction="column" alignSelf="flex-start" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        Course Title
                    </Typography>

                    <Input
                        placeholder="Title"
                        size="medium"
                        value={changes.title ?? courseDetails.title}
                        onChange={this.onChangeTitle}
                        sx={styles.Input}
                    />
                </Stack>

                <Stack direction="column" alignSelf="flex-start" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        Biostack
                    </Typography>

                    <Select
                        items={allBiostacks.map(({ id, name }) => ({
                            value: String(id),
                            label: name,
                        }))}
                        value={String(
                            (changes.biostack ?? courseDetails.biostack).id
                        )}
                        onChange={this.onChangeBiostack}
                        placeholder="Biostack"
                        sx={styles.MultipleSelectPlaceholder}
                    />
                </Stack>

                <Stack direction="column" alignSelf="flex-start" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        Price
                    </Typography>

                    <Input
                        placeholder="Price"
                        size="medium"
                        type="number"
                        value={String(changes.price ?? courseDetails.price)}
                        onChange={this.onChangePrice}
                        sx={styles.Input}
                    />
                </Stack>

                <Stack direction="column" alignSelf="flex-start" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        Fake Price
                    </Typography>

                    <Input
                        placeholder="Fake price"
                        size="medium"
                        type="number"
                        value={String(
                            changes.fakePrice ?? courseDetails.fakePrice
                        )}
                        onChange={this.onChangeFakePrice}
                        sx={styles.Input}
                    />
                </Stack>

                <Stack direction="column" alignSelf="flex-start" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        Course Syllabus
                    </Typography>

                    <Input
                        placeholder="Syllabus link"
                        size="medium"
                        value={changes.syllabus ?? courseDetails.syllabus ?? ""}
                        onChange={this.onChangeSyllabus}
                        sx={styles.Input}
                    />
                </Stack>

                <TextEditContainer
                    title="Course video"
                    onClick={() =>
                        this.setState({
                            openVideo: true,
                            video: changes.video ?? courseDetails.video ?? "",
                        })
                    }
                >
                    <VideoShow
                        src={changes.video ?? courseDetails.video}
                        title="Course video"
                    />
                </TextEditContainer>

                <Stack direction="column" alignSelf="flex-start" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        Course highlight title
                    </Typography>

                    <Input
                        placeholder="Learn to use R and BAsh to:"
                        size="medium"
                        value={
                            changes.highlightTitle ??
                            courseDetails.highlightTitle
                        }
                        onChange={this.onChangeHighlightTitle}
                        sx={styles.Input}
                    />
                </Stack>

                {/* Course Highligh list */}
                <TextEditContainer
                    title="Course Highlight List"
                    onClick={() =>
                        this.setState({
                            openHighlightList: true,
                        })
                    }
                >
                    <Stack
                        component={"ul"}
                        spacing={1}
                        sx={{ ...styles.BorderedContent, pl: 4 }}
                    >
                        {(changes.highlights ?? courseDetails.highlights).map(
                            (value, index) => (
                                <Typography
                                    component="li"
                                    sx={styles.ListItem}
                                    key={index + "highlight" + value}
                                >
                                    {value}
                                </Typography>
                            )
                        )}
                    </Stack>
                </TextEditContainer>

                <TextEditContainer
                    title="About the course"
                    onClick={this.onAboutCourse}
                >
                    <Typography component="div" sx={styles.BorderedContent}>
                        <EditorPreview
                            key={this.aboutKey + "about"}
                            initialState={
                                changes.about?.lexicalState ??
                                courseDetails.about.lexicalState
                            }
                        />
                    </Typography>
                </TextEditContainer>
                <Stack direction="column" alignSelf="flex-start" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        Technologies
                    </Typography>

                    <MultipleSelectPlaceholder
                        placeholder="Technologies student will use"
                        value={(changes.badges ?? courseDetails.badges).map(
                            (badge) => String(badge.id)
                        )}
                        onChange={this.onChangeBadges}
                        items={badgesToSelectItem(allBadges)}
                        sx={styles.MultipleSelectPlaceholder}
                    />
                </Stack>
                <Stack direction="column" alignSelf="flex-start" spacing={1}>
                    <Typography variant="h6" fontWeight="bold">
                        Estimated Time (Weeks)
                    </Typography>
                    <Input
                        placeholder="1 week @ 10 hours"
                        size="medium"
                        value={
                            changes.estimatedTime ?? courseDetails.estimatedTime
                        }
                        onChange={this.onChangeEstimatedTime}
                        minRows={4}
                        sx={styles.Input}
                    />
                </Stack>
                <TextEditContainer
                    title="Prerequisites"
                    onClick={this.onPrerequisite}
                >
                    <Typography component="div" sx={styles.BorderedContent}>
                        <EditorPreview
                            key={this.prerequisiteKey + "prerequisite"}
                            initialState={
                                changes.prerequisite?.lexicalState ??
                                courseDetails.prerequisite.lexicalState
                            }
                        />
                    </Typography>
                </TextEditContainer>
                <ImageContainer
                    title="Small Image"
                    src={changes.smallImage ?? courseDetails.smallImage}
                    width={326}
                    height={198}
                    onClick={this.onChangeImage("small")}
                />
                <ImageContainer
                    title="Large Image"
                    src={changes.largeImage ?? courseDetails.largeImage}
                    width={441}
                    height={450}
                    imageStyle={styles.LargeImageStyle}
                    onClick={this.onChangeImage("large")}
                />
            </Stack>
        );
    }
}

function badgesToSelectItem(badges: Badge[]) {
    return badges.map(({ id, title, level }) => ({
        value: String(id),
        label: title + (level ? ` - ${level}` : ""),
    }));
}

const mapDispatchToProps = {
    fetchCourseDetails: fetchCourseDetailsCreator,
    fetchSetting: fetchSettingCreator,
    updateCourseDetails: updateCourseDetailsCreator,
    fetchCourseModules: fetchCourseModulesCreator,
    updateCourse: updateCourseCreator,
};

function mapStateToProps(state: AppState) {
    return {
        ...state.courseDetails,
        setting: state.setting,
        allBiostacks: state.setting.biostacks,
        allBadges: state.setting.badges,
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withCourseId<Props>(CourseDetails));

const styles = {
    DrawerButton: { mt: 1.5 },
    MultipleSelectPlaceholder: { m: 1, width: 800, mt: 3 },
    Input: {
        maxWidth: "500px",
        alignSelf: "center",
        width: "100%",
        minWidth: 800,
    },
    ListItem: {
        fontSize: "1rem",
        fontWeight: 400,
    },
    BorderedContent: {
        border: "1px solid silver",
        borderRadius: 1,
        p: "16px",
    },
    HighlightInput: {
        maxWidth: "600px",
        alignSelf: "center",
        width: "100%",
    },
    LargeImageStyle: {
        border: "solid 4px black",
        borderRadius: "10px",
    } as CSSProperties,
};
// todo: make sure modules fetch doesn't interrupt the course module details
