import React, { ChangeEvent, useState } from "react";
import DialogForm from "../DialogForm";
import Input from "../Input";
import { requiredErrorMessage } from "@teyalite/hackbio-common/dist/error-messages";
import Stack from "@mui/material/Stack";

type Props = {
    defaultTitle?: string;
    edit?: boolean;
    isLoading: boolean;
    onClose: () => void;
    onSubmit: (title: string) => void;
};

const TITLE_ERROR = requiredErrorMessage("Question title");

export default function QuestionForm({
    onClose,
    onSubmit,
    defaultTitle = "",
    isLoading,
    edit = false,
}: Props) {
    const [title, setTitle] = useState(defaultTitle);
    const [triggered, setTriggered] = useState(false);

    const handleSubmit = () => {
        if (title.trim().length === 0) {
            return setTriggered(true);
        }

        onSubmit(title);
    };

    return (
        <DialogForm
            open={true}
            title={edit ? "Edit Question" : "Add New Question"}
            submitButtonText={edit ? "Save changes" : "Create Question"}
            onClose={onClose}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            PaperSx={{ minWidth: "500px" }}
        >
            <Stack spacing={3}>
                <Input
                    placeholder="Question title"
                    size="medium"
                    value={title}
                    onChange={(e: ChangeEvent<HTMLInputElement>) =>
                        setTitle(e.currentTarget.value)
                    }
                    error={triggered && title.trim().length === 0}
                    errorMessage={TITLE_ERROR}
                    sx={styles.input}
                />
            </Stack>
        </DialogForm>
    );
}

const styles = {
    input: {
        maxWidth: "500px",
        alignSelf: "center",
        width: "100%",
    },
};
