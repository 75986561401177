import React, { Component } from "react";
import { Navigate, Outlet, Route, Routes } from "react-router-dom";
import Courses from "./pages/courses/Courses";
import CourseLayout from "./components/courses/CourseLayout";
import CourseDetails from "./pages/courses/CourseDetails";
import CourseModules from "./pages/courses/CourseModules";
import Settings from "./pages/settings/Settings";
import Questions from "./pages/questions/Questions";
import Login from "./pages/Login";
import { AuthProtected, SuperProtected } from "./auth.context";
import QuestionDetails from "./pages/questions/QuestionDetails";
import ModulesLayout from "./components/courses/ModulesLayout";
import ModuleDetails from "./pages/courses/ModuleDetails";
import CourseUsers from "./pages/courses/CourseUsers";
import ProjectGrading from "./pages/courses/ProjectGrading";
import Feedbacks from "./pages/feedbacks/Feedbacks";
import FedbackQuestionDetails from "./pages/feedbacks/FedbackQuestionDetails";

export default class App extends Component {
    render() {
        return (
            <Routes>
                <Route path="/" element={<AuthProtected />}>
                    <Route index element={<Navigate to={"/courses"} />} />

                    <Route path="courses" element={<Outlet />}>
                        <Route index element={<Courses />} />

                        <Route path=":courseId" element={<CourseLayout />}>
                            <Route index element={<CourseDetails />} />

                            <Route path="modules" element={<ModulesLayout />}>
                                <Route index element={<CourseModules />} />
                                <Route
                                    path=":moduleId"
                                    element={<ModuleDetails />}
                                />
                            </Route>

                            <Route path="users" element={<CourseUsers />} />
                            <Route path="grades" element={<ProjectGrading />} />
                        </Route>
                    </Route>

                    <Route path="questions" element={<Outlet />}>
                        <Route index element={<Questions />} />
                        <Route
                            path=":questionId"
                            element={<QuestionDetails />}
                        />
                    </Route>

                    <Route path="settings" element={<SuperProtected />}>
                        <Route index element={<Settings />} />
                    </Route>

                    <Route path="feedbacks" element={<SuperProtected />}>
                        <Route index element={<Feedbacks />} />
                        <Route
                            path=":testId"
                            element={<FedbackQuestionDetails />}
                        />
                    </Route>
                </Route>

                <Route path="/login" element={<Login />} />
            </Routes>
        );
    }
}
