import React from "react";
import { IconButton, Stack, Typography } from "@mui/material";
import RefreshIcon from "@mui/icons-material/Refresh";
import LoadingIndicator from "./LoadingIndicator";
import { CONNECTION_FAILED } from "../constants";

type Props = {
    failed?: boolean;
    onRetry?: () => void;
};

export default function Loading({ failed = false, onRetry }: Props) {
    if (failed) {
        return (
            <Stack
                textAlign="center"
                spacing={3}
                alignItems="center"
                justifyContent="center"
            >
                <IconButton size="large" onClick={onRetry}>
                    <RefreshIcon sx={{ fontSize: 50 }} />
                </IconButton>
                <Typography>{CONNECTION_FAILED}</Typography>
                <Typography>Try again</Typography>
            </Stack>
        );
    }

    return <LoadingIndicator />;
}
