import AppBar from "@mui/material/AppBar";
import Toolbar from "@mui/material/Toolbar";
import Logo from "../Logo";

export default function SimpleAppBar() {
    return (
        <AppBar position="static" sx={{ background: "#f6f8fc" }} elevation={0}>
            <Toolbar sx={{ height: "77px" }}>
                <Logo />
            </Toolbar>
        </AppBar>
    );
}
