import {
    defaultCourseUsersState,
    CourseUsersState,
    CourseUsersActionType,
    CourseUsersAction,
} from "../types/course-users";

function courseUsersReducer(
    state: CourseUsersState = defaultCourseUsersState,
    action: CourseUsersAction
    // action: any
): CourseUsersState {
    switch (action.type) {
        case CourseUsersActionType.FetchCourseUsers:
            state = {
                ...state,
                ...action.value,
            };
            return state;

        default:
            return state;
    }
}

export default courseUsersReducer;
