import React, { CSSProperties, Component, PropsWithChildren } from "react";
import MuiTableRow from "@mui/material/TableRow";
import theme from "../utils/theme";

class TableRow extends Component<PropsWithChildren & { onClick?: () => void }> {
    render() {
        const { children, onClick } = this.props;

        return (
            <MuiTableRow sx={styles.tableRow} onClick={onClick}>
                {children}
            </MuiTableRow>
        );
    }
}

const styles = {
    tableRow: [
        {
            height: 70,
            backgroundColor: "#EBF1FA",
            "&:hover": {
                backgroundColor: theme.palette.primary.light,
            },
        },
    ],
};

export const tableLinkStyle = {
    textDecoration: "none",
    color: "black",
} as CSSProperties;

export default TableRow;
