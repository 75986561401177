import { Button, OutlinedInput, Stack, Typography } from "@mui/material";
import { ChangeEvent, Component } from "react";
import { QuestionTest } from "../../types";
import DialogForm from "../DialogForm";
import Input from "../Input";
import QuestionBlueInput from "./QuestionBlueInput";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

type Props = {
    questionTest: QuestionTest;
    onClose: () => void;
    onSubmit: (data: {
        id: number;
        title: string;
        trials: number;
        explanation: string[];
        hint: string[];
        xp: number;
        reorderList: { list: string[]; question: string };
    }) => void;
};

type State = {
    title: string;
    trials: string;
    xp: string;
    explanation: string;
    hint: string;

    question: string;
    list: string[];
    triggered: boolean;
};

export default class ReorderListForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            title: props.questionTest.title,
            trials: String(props.questionTest.trials),
            xp: String(props.questionTest.xp),
            question: props.questionTest.content.question,
            explanation: props.questionTest.explanation.join("\r\n"),
            hint: props.questionTest.hint.join("\r\n"),
            list: props.questionTest.content.list,
            triggered: false,
        };
    }

    onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            title: e.currentTarget.value,
        });
    };

    onChangeQuestion = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            question: e.currentTarget.value,
        });
    };

    onChangeListItem = (index: number, value: string) => {
        const list = [...this.state.list.slice()];

        list[index] = value;

        this.setState({
            list: [...list],
        });
    };

    onChangeExplanation = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            explanation: e.currentTarget.value,
        });
    };

    onChangeHint = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            hint: e.currentTarget.value,
        });
    };

    onChangeTrials = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            trials: e.currentTarget.value,
        });
    };

    onChangeXP = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            xp: e.currentTarget.value,
        });
    };

    onSubmit = () => {
        const { title, list, question, trials, xp, explanation, hint } =
            this.state;
        // todo: make verification

        const {
            questionTest: { id },
        } = this.props;

        const explanationValue = explanation.split(/\r|\r|\n/g);
        const hintValue = hint.split(/\r|\r|\n/g);

        this.props.onSubmit({
            title,
            reorderList: { list, question },
            trials: Number(trials),
            xp: Number(xp),
            explanation:
                explanationValue.length === 1 && explanationValue[0] === ""
                    ? []
                    : explanationValue,
            hint:
                hintValue.length === 1 && hintValue[0] === "" ? [] : hintValue,
            id,
        });
    };

    render() {
        const { title, trials, xp, question, list, explanation, hint } =
            this.state;
        // todo: list empty
        return (
            <DialogForm
                title="Reorder list test"
                onClose={this.props.onClose}
                onSubmit={this.onSubmit}
                submitButtonText="Preview changes"
                isLoading={false}
            >
                <Stack spacing={3} pb={3}>
                    <Input
                        placeholder="Title"
                        size="medium"
                        value={title}
                        onChange={this.onChangeTitle}
                        sx={styles.input}
                    />

                    <textarea
                        rows={4}
                        placeholder="Question..."
                        className="question-input"
                        value={question}
                        onChange={this.onChangeQuestion}
                    ></textarea>

                    {list.map((value, index) => (
                        <QuestionBlueInput
                            key={index}
                            placeholder="Answer"
                            label={`Item ${index + 1}`}
                            value={value}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                this.onChangeListItem(
                                    index,
                                    e.currentTarget.value
                                )
                            }
                        />
                    ))}

                    <Stack direction="row" alignItems="center" spacing={3}>
                        <Button
                            startIcon={<AddIcon />}
                            sx={{ alignSelf: "flex-start" }}
                            variant="outlined"
                            size="small"
                            onClick={() =>
                                this.setState({
                                    list: [...this.state.list, ""],
                                })
                            }
                        >
                            Add item to the list
                        </Button>

                        <Button
                            startIcon={<RemoveIcon />}
                            sx={{ alignSelf: "flex-start" }}
                            variant="outlined"
                            size="small"
                            color="warning"
                            onClick={() => {
                                const list = this.state.list.slice();
                                list.pop();

                                this.setState({
                                    list: [...list],
                                });
                            }}
                        >
                            Remove last item from the list
                        </Button>
                    </Stack>

                    <Stack spacing={0.5}>
                        <Typography fontWeight="bold">Explanation:</Typography>
                        <textarea
                            rows={4}
                            placeholder="Explanation..."
                            className="question-input"
                            value={explanation}
                            onChange={this.onChangeExplanation}
                        ></textarea>
                    </Stack>

                    <Stack spacing={0.5}>
                        <Typography fontWeight="bold">Hint:</Typography>
                        <textarea
                            rows={4}
                            placeholder="Hint..."
                            className="question-input"
                            value={hint}
                            onChange={this.onChangeHint}
                        ></textarea>
                    </Stack>

                    <TwoRowBlueInput
                        placeholder="Number of trials"
                        value={trials}
                        onChange={this.onChangeTrials}
                    />

                    <TwoRowBlueInput
                        placeholder="Experience point (XP)"
                        value={xp}
                        onChange={this.onChangeXP}
                    />
                </Stack>
            </DialogForm>
        );
    }
}

function TwoRowBlueInput({
    placeholder,
    value,
    onChange,
}: {
    placeholder: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <Stack
            sx={{ width: "300px" }}
            spacing={1}
            direction="row"
            alignItems="center"
        >
            <Typography
                sx={{
                    background: "rgba(47, 128, 237, 0.2)",
                    borderLeft: "3px solid #6F71E0",
                    height: "35px",
                    pt: 0.8,
                    pl: 1,
                    flexGrow: 1,
                }}
            >
                {placeholder}
            </Typography>
            <OutlinedInput
                sx={{ width: "70px", height: "35px" }}
                onChange={onChange}
                value={value}
                type="number"
                inputProps={{ min: 0 }}
            />
        </Stack>
    );
}

const styles = {
    input: {
        width: "542px",
        alignSelf: "center",
    },
};
