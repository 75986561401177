import { Dispatch } from "redux";
import {
    CourseUsersActionType,
    CourseUsersState,
    FetchCourseUsers,
} from "../types/course-users";

export const fetchCourseUsersCreator = (payload: CourseUsersState) => {
    return (dispatch: Dispatch<FetchCourseUsers>) => {
        dispatch({
            value: payload,
            type: CourseUsersActionType.FetchCourseUsers,
        });
    };
};
