import { Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { Component } from "react";
import { withCourseId } from "../../utils/with-course";
import { getRequest } from "../../utils/http";
import { CourseUser } from "../../types";
import { fetchCourseUsersCreator } from "../../redux/actions/course-users";
import { AppState } from "../../redux/store";
import { ConnectedProps, connect } from "react-redux";
import Loading from "../../components/Loading";
import Table from "../../components/courses/users/Table";

type Props = PropsFromRedux & {
    courseId: number;
};
class CourseUsers extends Component<Props> {
    private usersPathname: string;

    constructor(props: Props) {
        super(props);
        this.usersPathname = "/course/" + props.courseId + "/user";
    }

    componentDidMount(): void {
        const { isLoading, users } = this.props;

        if (!isLoading && users.length === 0) {
            this.fetchUsers();
        }
    }

    fetchUsers = async () => {
        const { fetch, users } = this.props;

        fetch({ isLoading: true, failed: false, users });

        try {
            const fetchedUsers = await getRequest<CourseUser[]>(
                this.usersPathname
            );

            fetch({ isLoading: false, failed: false, users: fetchedUsers });
        } catch (error: any) {
            fetch({ isLoading: false, failed: true, users: [] });
        }
    };

    render() {
        const { isLoading, failed, users } = this.props;

        if (isLoading || failed) {
            return (
                <Stack flexGrow={1} alignItems="center" justifyContent="center">
                    <Loading failed={failed} onRetry={this.fetchUsers} />
                </Stack>
            );
        }

        if (users.length === 0) {
            return (
                <Stack className="content-min-width" spacing={3} pt={5}>
                    <Typography
                        fontWeight="bold"
                        variant="h5"
                        textAlign="center"
                    >
                        There is no users enrolled in this course currently
                    </Typography>
                </Stack>
            );
        }

        return (
            <Stack className="content-min-width" spacing={3} pt={5}>
                <Table
                    users={this.props.users}
                    downloadPathname={this.usersPathname + "?csv=true"}
                />
            </Stack>
        );
    }
}

const mapDispatchToProps = {
    fetch: fetchCourseUsersCreator,
};

function mapStateToProps(state: AppState) {
    return {
        ...state.courseUsers,
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withCourseId<Props>(CourseUsers));
