import { ProjectSubmission } from "../../../types";

export interface CourseSubmissionsState {
    isLoading: boolean;
    failed: boolean;
    submissions: ProjectSubmission[];
}

export interface FetchCourseSubmissions {
    type: CourseSubmissionsActionType.FetchCourseSubmissions;
    value: CourseSubmissionsState;
}
export interface UpdateCourseSubmission {
    type: CourseSubmissionsActionType.UpdateCourseSubmission;
    value: ProjectSubmission;
}

export enum CourseSubmissionsActionType {
    FetchCourseSubmissions = "CourseFetchCourseSubmissions",
    UpdateCourseSubmission = "CourseUpdateCourseSubmission",
}

export type CourseSubmissionsAction =
    | FetchCourseSubmissions
    | UpdateCourseSubmission;

export const defaultCourseSubmissionsState: CourseSubmissionsState = {
    isLoading: false,
    failed: false,
    submissions: [],
};
