import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import hackBioLogo from "../assets/images/hackbio-icon.png";
import MuiLink from "@mui/material/Link";
import { Link } from "react-router-dom";
import theme from "../utils/theme";

export default function Logo() {
    return (
        <MuiLink component={Link} to="/courses" sx={styles.logo}>
            <ListItem>
                <ListItemIcon>
                    <img
                        src={hackBioLogo}
                        width={36}
                        height={36}
                        alt="HackBio Logo"
                    />
                </ListItemIcon>
                <ListItemText sx={{ color: "black" }}>HackBio</ListItemText>
            </ListItem>
        </MuiLink>
    );
}

const styles = {
    logo: {
        color: "inherit",
        textDecoration: "none",
        boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
        py: 1.5,
        fontSize: theme.typography.h5,
        "&:hover, &:focus": {
            bgcolor: "rgba(255, 255, 255, 0.08)",
            color: theme.palette.primary.main,
        },
    },
};
