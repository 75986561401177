import React, { ChangeEvent, createRef, useState } from "react";
import DialogForm from "../DialogForm";
import Editor from "../editor/Editor";
import EditorButton from "../editor/Button";
import Input from "../Input";

type Props = {
    id: number;
    dialogTitle: string;
    title: string;
    isLoading?: boolean; // True when form is being submitted
    open?: boolean; // Open the form
    onClose: () => void;
    initialState: any;
    onSubmit: (
        id: number,
        title: string,
        editorState: any,
        htmlString: string
    ) => void;
};

const TITLE_ERROR = "TITLE_ERROR";

export default function SubmoduleEditor({
    title,
    id,
    dialogTitle,
    open,
    onClose,
    onSubmit,
    isLoading,
    initialState,
}: Props) {
    const buttonRef = createRef<HTMLButtonElement>();

    const [inputTitle, setInputTitle] = useState(title);
    const [triggered, setTriggered] = useState(false);

    const handlePreview = () => {
        buttonRef.current?.click();
    };

    const handleSubmit = (editorState: any, htmlString: string) => {
        if (inputTitle.trim().length === 0) {
            return setTriggered(true);
        }

        onSubmit(id, inputTitle, editorState, htmlString);
    };

    const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setInputTitle(e.currentTarget.value);
    };

    return (
        <DialogForm
            open={open}
            PaperSx={styles.dialog}
            title={dialogTitle}
            submitButtonText="Preview Changes"
            onClose={onClose}
            onSubmit={handlePreview}
            minWidth={600}
            isLoading={isLoading}
        >
            <Input
                placeholder="Title"
                size="medium"
                value={inputTitle}
                onChange={onChangeTitle}
                error={triggered && inputTitle.trim().length === 0}
                errorMessage={TITLE_ERROR}
                sx={styles.input}
            />
            <Editor
                placeholder="Enter some rich text..."
                initialState={initialState}
                button={<EditorButton onSave={handleSubmit} ref={buttonRef} />}
            />
        </DialogForm>
    );
}

const styles = {
    dialog: {
        minWidth: "650px",
    },
    input: {
        maxWidth: 600,
        alignSelf: "center",
        width: "100%",
        minWidth: 600,
        mb: 3,
    },
};
