import React from "react";
import ListItem from "@mui/material/ListItem";
import ListItemButton from "@mui/material/ListItemButton";
import ListItemIcon from "@mui/material/ListItemIcon";
import ListItemText from "@mui/material/ListItemText";
import { DrawerLink as DrawerLinkType } from "../../types";
import MuiLink from "@mui/material/Link";
import { Typography } from "@mui/material";
import { Link } from "react-router-dom";
import theme from "../../utils/theme";

export default function DrawerLink({
    href,
    selected = false,
    text,
    icon,
    external = false,
}: DrawerLinkType) {
    if (external) {
        return (
            <MuiLink
                href={href}
                target="_blank"
                rel="noreferrer"
                sx={styles.link}
            >
                <ListItem disablePadding sx={{ mt: 1 }}>
                    <ListItemButton>
                        {icon && (
                            <ListItemIcon sx={styles.ListItemIcon}>
                                {icon}
                            </ListItemIcon>
                        )}
                        <ListItemText>
                            <Typography noWrap>{text}</Typography>
                        </ListItemText>
                    </ListItemButton>
                </ListItem>
            </MuiLink>
        );
    }

    return (
        <MuiLink to={href} component={Link} sx={styles.link}>
            <ListItem disablePadding sx={{ mt: 1 }}>
                <ListItemButton
                    selected={selected}
                    // sx={{ "&.Mui-selected": { backgroun: "yellow" } }}
                >
                    {icon && (
                        <ListItemIcon sx={styles.ListItemIcon}>
                            {icon}
                        </ListItemIcon>
                    )}
                    <ListItemText>
                        <Typography noWrap>{text}</Typography>
                    </ListItemText>
                </ListItemButton>
            </ListItem>
        </MuiLink>
    );
}

const link = {
    color: "inherit",
    textDecoration: "none",
    "&:hover, &:focus": {
        bgcolor: "rgba(255, 255, 255, 0.08)",
        color: theme.palette.primary.main,
    },
    boxShadow: "0 -1px 0 rgb(255,255,255,0.1) inset",
    py: 1.5,
};

const styles = {
    link: {
        ...link,
    },
    ListItemIcon: {
        // pl: 1.5,
    },
};
