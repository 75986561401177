import { Stack } from "@mui/material";
import Box from "@mui/material/Box";
import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTableHead from "@mui/material/TableHead";
import MuiTablePagination from "@mui/material/TablePagination";
import MuiTableRow from "@mui/material/TableRow";
import { CSSProperties, ChangeEvent, PureComponent } from "react";
import { ROW_PER_PAGE } from "../../../constants";
import { ProjectSubmission } from "../../../types";
import TableRow from "./TableRow";

type Props = {
    submissions: ProjectSubmission[];
    onGrade: (d: ProjectSubmission) => void;
};

type State = {
    page: number;
    rowsPerPage: number;
};

class Table extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: ROW_PER_PAGE[0],
        };
    }

    /**
     * When Page changes this method will be called
     * @param e
     * @param newPage
     * @returns
     */
    onPageChange = (e: unknown, newPage: number) =>
        this.setState({ page: newPage });

    /**
     * Changes Rows per page
     * @param e
     * @returns
     */
    onRowsPerPageChange = (e: ChangeEvent<HTMLInputElement>) =>
        this.setState({ rowsPerPage: +e.target.value, page: 0 });

    render() {
        const { submissions, onGrade } = this.props;

        return (
            <Stack style={styles.root} pt={5}>
                <MuiTableContainer component={Box}>
                    <MuiTable sx={styles.table} size="small">
                        <MuiTableHead>
                            <MuiTableRow
                                sx={{
                                    background: "rgba(47, 128, 237, 0.35)",
                                    height: "70px",
                                    borderTopRightRadius: 5,
                                }}
                            >
                                <MuiTableCell
                                    sx={{
                                        fontSize: "large",
                                        fontWeight: "bold",
                                        borderTopLeftRadius: "10px",
                                    }}
                                >
                                    Module
                                </MuiTableCell>
                                <MuiTableCell
                                    sx={{
                                        fontSize: "large",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Submodule
                                </MuiTableCell>
                                <MuiTableCell
                                    align="right"
                                    sx={{
                                        fontSize: "large",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Grade
                                </MuiTableCell>
                                <MuiTableCell
                                    align="right"
                                    sx={{
                                        fontSize: "large",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Grade
                                </MuiTableCell>
                            </MuiTableRow>
                        </MuiTableHead>
                        <MuiTableBody>
                            {submissions
                                .slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                        this.state.rowsPerPage
                                )
                                .map((submission) => (
                                    <TableRow
                                        onGrade={onGrade}
                                        submission={submission}
                                        key={submission.id}
                                    />
                                ))}
                        </MuiTableBody>
                    </MuiTable>
                </MuiTableContainer>
                <MuiTablePagination
                    component="div"
                    rowsPerPageOptions={ROW_PER_PAGE}
                    count={submissions.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                />
            </Stack>
        );
    }
}

const styles = {
    root: {
        marginRight: 10,
        paddingBottom: 150,
        minWidth: 900,
        maxWidth: 900,
        // alignSelf: "center",
    } as CSSProperties,
    table: {
        minWidth: 900,
        maxWidth: 900,
    },
};

export default Table;
