export const IS_PRODUCTION = process.env.NODE_ENV === "production";

export const CONNECTION_FAILED = "Connection failed";
export const API_BASE_URL = process.env.REACT_APP_API_BASE_URL;
export const DIALOG_CANCEL = "Cancel";
export const ROW_PER_PAGE: number[] = [25, 50, 100];
export const FILL_IN_THE_GAP_INDICATOR = "_BOX_";

export const DEFAULT_EDITOR_STATE = {
    root: {
        children: [
            {
                children: [
                    {
                        detail: 0,
                        format: 0,
                        mode: "normal",
                        style: "",
                        text: "Type some rich text",
                        type: "text",
                        version: 1,
                    },
                ],
                direction: "ltr",
                format: "",
                indent: 0,
                type: "paragraph",
                version: 1,
            },
        ],
        direction: "ltr",
        format: "",
        indent: 0,
        type: "root",
        version: 1,
    },
};

export const DEFAULT_EDITOR_PLACEHOLDER = `<p class="editor-paragraph" dir="ltr"><span>Type some rich text</span></p>`;
