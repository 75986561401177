import Alert from "@mui/material/Alert";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { CourseDetails } from "../../types";
import { getRequest } from "../../utils/http";
import CBAbout from "../cb/CBAbout";
import CBAlumni from "../cb/CBAlumni";
import CBFlyer from "../cb/CBFlyer";
import CBPlan from "../cb/CBPlan";
import CBSyllabus from "../cb/CBSyllabus";
import CBTopBlueBox from "../cb/CBTopBlueBox";

type Props = {
    onClose: () => void;
    courseId: number;
    details: CourseDetails;
};

interface ModuleT {
    title: string;
    submodules: string;
}

type State = {
    loading: boolean;
    failed: boolean;
    modules: ModuleT[];
};

export default class CoursePreview extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            loading: true,
            failed: false,
            modules: [],
        };
    }

    componentDidMount(): void {
        this.fetchModules();
    }

    fetchModules = async () => {
        const { courseId } = this.props;

        this.setState({ loading: true, failed: false, modules: [] });

        // todo: handle failed case
        try {
            const fetchedModules = await getRequest<ModuleT[]>(
                "/course/" + courseId + "/module?detailed=true"
            );

            this.setState({
                modules: fetchedModules,
                loading: false,
                failed: false,
            });
        } catch (error: any) {
            this.setState({ loading: false, failed: true, modules: [] });
        }
    };

    render() {
        const { onClose, details } = this.props;
        const { modules } = this.state;

        return (
            <Dialog
                open
                onClose={onClose}
                scroll="paper"
                fullWidth
                fullScreen
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <DialogTitle
                    id="scroll-dialog-title"
                    sx={styles.dialogTitle}
                    component={"section"}
                >
                    <Stack direction="row" alignItems="center" spacing={2}>
                        <Typography
                            sx={{ fontSize: "large", fontWeight: "bold" }}
                        >
                            Course preview
                        </Typography>
                        <Alert severity="info" sx={{ py: 0 }}>
                            Only saved changes can be previewed
                        </Alert>
                    </Stack>
                    <Button
                        variant="outlined"
                        sx={{ textTransform: "none" }}
                        color="error"
                        onClick={onClose}
                    >
                        Close Preview
                    </Button>
                </DialogTitle>
                <DialogContent dividers sx={{ p: 0 }}>
                    <Stack sx={{ width: "100%", pb: 6 }}>
                        <CBTopBlueBox
                            title={details.title}
                            image={details.largeImage}
                            highlightTitle={details.highlightTitle}
                            highlights={details.highlights}
                        />

                        <CBAlumni />

                        <CBAbout
                            contentTitle={"About the Course"}
                            video={details.video}
                            syllabus={details.syllabus}
                        >
                            {details.about.html}
                        </CBAbout>

                        <CBSyllabus
                            syllabus={modules as any}
                            badges={details.badges}
                        />

                        <Container component={Divider} />

                        <CBFlyer title={details.title} price={details.price} />

                        <CBPlan
                            duration={details.estimatedTime}
                            prerequisite={
                                <div
                                    dangerouslySetInnerHTML={{
                                        __html: details.prerequisite.html,
                                    }}
                                />
                            }
                        />

                        <Typography variant="h1" textAlign="center">
                            . . .
                        </Typography>
                    </Stack>
                </DialogContent>
                <DialogActions>
                    <Button
                        variant="outlined"
                        sx={{ textTransform: "none" }}
                        color="error"
                        onClick={onClose}
                    >
                        Close Preview
                    </Button>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = {
    dialogTitle: {
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },
};
