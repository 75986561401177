import { CourseDetails } from "../../types";

export interface CourseDetailsState {
    isLoading: boolean;
    failed: boolean;
    courseDetails: CourseDetails | null;
}

export interface FetchCourseDetails {
    type: CourseDetailsActionType.FetchCourseDetails;
    value: CourseDetailsState;
}

export interface UpdateCourseDetails {
    type: CourseDetailsActionType.UpdateCourseDetails;
    value: CourseDetails;
}

export enum CourseDetailsActionType {
    FetchCourseDetails = "FetchCourseDetails",
    UpdateCourseDetails = "UpdateCourseDetails",
}

export type CourseDetailsAction = FetchCourseDetails | UpdateCourseDetails;

export const defaultCourseDetailsState: CourseDetailsState = {
    isLoading: false,
    failed: false,
    courseDetails: null,
};
