import { ModuleLanguageType } from "@teyalite/hackbio-common/dist/types/module-language-type.enum";
import { Module, ModuleDetails, Submodule } from "../../types";

export interface CourseModuleState {
    modules: ModulesState;
    details: ModuleDetailsState;
}

export interface ModulesState {
    isLoading: boolean;
    failed: boolean;
    modules: Module[];
}

export interface ModuleDetailsState {
    isLoading: boolean;
    failed: boolean;
    details: ModuleDetails | null;
}

export interface FetchCourseModules {
    type: CourseModuleActionType.FetchCourseModules;
    value: ModulesState;
}

export interface FetchModuleDetails {
    type: CourseModuleActionType.FetchModuleDetails;
    value: ModuleDetailsState;
}

export interface AddModule {
    type: CourseModuleActionType.AddModule;
    value: Module;
}

export interface UpdateModuleData {
    moduleId: number;
    title: string;
    languageType: ModuleLanguageType | null;
}

export interface UpdateModule {
    type: CourseModuleActionType.UpdateModule;
    value: UpdateModuleData;
}

export interface UpdateSubmodule {
    type: CourseModuleActionType.UpdateSubmodule;
    value: Submodule;
}

export interface AddSubmodule {
    type: CourseModuleActionType.AddSubmodule;
    value: Submodule;
}

export interface RemoveSubmodule {
    type: CourseModuleActionType.RemoveSubmodule;
    value: number;
}

export enum CourseModuleActionType {
    FetchCourseModules = "FetchCourseModules",
    FetchModuleDetails = "FetchModuleDetails",
    AddModule = "AddModule",
    UpdateModule = "UpdateModule",
    UpdateSubmodule = "UpdateSubmodule",
    AddSubmodule = "AddSubmodule",
    RemoveSubmodule = "RemoveSubmodule",
}

export type CourseModuleAction =
    | FetchCourseModules
    | FetchModuleDetails
    | AddModule
    | UpdateModule
    | UpdateSubmodule
    | AddSubmodule
    | RemoveSubmodule;

export const defaultCourseModuleState: CourseModuleState = {
    modules: {
        isLoading: false,
        failed: false,
        modules: [],
    },
    details: {
        isLoading: false,
        failed: false,
        details: null,
    },
};
