import { isEmail } from 'class-validator';
import { INVALID_EMAIL } from './constants';

/**
 * Validate an email address
 * @param email
 * @returns
 */
export function emailValidation(email: string) {
  if (!isEmail(email)) {
    return INVALID_EMAIL;
  }

  return '';
}
