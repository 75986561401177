import { FormHelperText, Stack, SxProps } from "@mui/material";
import TextField from "@mui/material/TextField";
import React, { ChangeEvent } from "react";

export default function QuestionBlueInput({
    placeholder,
    value,
    label,
    sx,
    textFieldSx,
    error = false,
    errorMessage,
    disabled = false,
    onChange,
}: {
    value: string;
    placeholder: string;
    label: string;
    sx?: SxProps;
    textFieldSx?: SxProps;
    error?: boolean;
    errorMessage?: string;
    disabled?: boolean;
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void;
}) {
    return (
        <Stack sx={sx}>
            <TextField
                label={label}
                value={value}
                variant="filled"
                placeholder={placeholder}
                color="primary"
                size="small"
                sx={{ ...style, ...textFieldSx }}
                onChange={onChange}
                disabled={disabled}
            />
            {error && <FormHelperText error>{errorMessage}</FormHelperText>}
        </Stack>
    );
}

const style = {
    "&.MuiTextField-root": {
        background: "rgb(47 128 237 / 11%)",
        borderLeft: "3px solid #6F71E0",
        borderBottom: "none",
    },
    "& :focus": {},
};
