import { PopoverOrigin } from "@mui/material";
import Avatar from "@mui/material/Avatar";
import IconButton from "@mui/material/IconButton";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { ReactNode, useContext } from "react";
import { AuthContext } from "../../auth.context";
import theme from "../../utils/theme";
import { getRequest } from "../../utils/http";

export default function AppBar({ title }: { title: ReactNode }) {
    const user = useContext(AuthContext).user!;

    const [anchorElUser, setAnchorElUser] = React.useState<null | HTMLElement>(
        null
    );

    const handleOpenUserMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const handleLogout = async () => {
        try {
            await getRequest("/auth/logout");
            window.location.reload();
        } catch (error: any) {}
    };

    const avatar = <Avatar alt={user.name}>{user.name[0]}</Avatar>;

    return (
        <Stack id="appbar" sx={styles.root}>
            <Typography variant="h6">{title}</Typography>
            <Stack direction="row" alignItems="center" spacing={2}>
                <Tooltip title="Open profile">
                    <IconButton
                        onClick={handleOpenUserMenu}
                        sx={styles.IconButton}
                    >
                        {avatar}
                    </IconButton>
                </Tooltip>
                <Menu
                    sx={styles.Menu}
                    keepMounted
                    anchorEl={anchorElUser}
                    anchorOrigin={anchorOrigin}
                    transformOrigin={transformOrigin}
                    open={Boolean(anchorElUser)}
                    onClose={handleCloseUserMenu}
                >
                    <Stack px={2} spacing={1} mb={1}>
                        <Stack direction="row" spacing={1} alignItems="center">
                            {avatar}
                            <Typography fontWeight="bold">
                                {user.name}
                            </Typography>
                        </Stack>
                        <Typography component="em">{user.email}</Typography>
                    </Stack>
                    <MenuItem onClick={handleLogout}>
                        <Typography textAlign="center">Logout</Typography>
                    </MenuItem>
                </Menu>
                <Typography noWrap maxWidth={150}>
                    {user.name}
                </Typography>
            </Stack>
        </Stack>
    );
}

const anchorOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "right",
};

const transformOrigin: PopoverOrigin = {
    vertical: "top",
    horizontal: "right",
};

const styles = {
    root: { background: theme.palette.secondary.light },
    IconButton: { p: 0 },
    Menu: { mt: "45px" },
};

// todo: set admin data on logout instead of reloading
