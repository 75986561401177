import AddIcon from "@mui/icons-material/Add";
import { Stack, Typography } from "@mui/material";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import MuiTable from "@mui/material/Table";
import MuiTableBody from "@mui/material/TableBody";
import MuiTableCell from "@mui/material/TableCell";
import MuiTableContainer from "@mui/material/TableContainer";
import MuiTableHead from "@mui/material/TableHead";
import MuiTablePagination from "@mui/material/TablePagination";
import MuiTableRow from "@mui/material/TableRow";
import { ChangeEvent, CSSProperties, PureComponent } from "react";
import { ROW_PER_PAGE } from "../../constants";
import { Question } from "../../types";
import TableRow from "./TableRow";

type Props = {
    questions: Question[];
    onAdd: () => void;
};

type State = {
    page: number;
    rowsPerPage: number;
};

class Table extends PureComponent<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            page: 0,
            rowsPerPage: ROW_PER_PAGE[0],
        };
    }

    /**
     * When Page changes this method will be called
     * @param e
     * @param newPage
     * @returns
     */
    onPageChange = (e: unknown, newPage: number) =>
        this.setState({ page: newPage });

    /**
     * Changes Rows per page
     * @param e
     * @returns
     */
    onRowsPerPageChange = (e: ChangeEvent<HTMLInputElement>) =>
        this.setState({ rowsPerPage: +e.target.value, page: 0 });

    render() {
        const { questions, onAdd } = this.props;

        return (
            <Stack style={styles.root} spacing={4} pt={5}>
                <Button
                    variant="contained"
                    sx={{ alignSelf: "flex-end" }}
                    disableElevation
                    onClick={onAdd}
                    startIcon={<AddIcon />}
                >
                    New Question
                </Button>
                {questions.length === 0 && (
                    <Typography variant="h4" textAlign="center" pb={4} pt={2}>
                        Your questions will appear here.
                    </Typography>
                )}
                <MuiTableContainer component={Box}>
                    <MuiTable sx={styles.table} size="small">
                        <MuiTableHead>
                            <MuiTableRow
                                sx={{
                                    background: "rgba(47, 128, 237, 0.35)",
                                    height: "70px",
                                    borderTopRightRadius: 5,
                                }}
                            >
                                <MuiTableCell
                                    sx={{
                                        fontSize: "large",
                                        fontWeight: "bold",
                                        borderTopLeftRadius: "10px",
                                    }}
                                >
                                    Question title
                                </MuiTableCell>
                                <MuiTableCell
                                    align="center"
                                    sx={{
                                        fontSize: "large",
                                        fontWeight: "bold",
                                    }}
                                >
                                    Number of tests
                                </MuiTableCell>
                            </MuiTableRow>
                        </MuiTableHead>
                        <MuiTableBody>
                            {questions
                                .slice(
                                    this.state.page * this.state.rowsPerPage,
                                    this.state.page * this.state.rowsPerPage +
                                        this.state.rowsPerPage
                                )
                                .map((question) => (
                                    <TableRow
                                        question={question}
                                        key={question.id}
                                    />
                                ))}
                        </MuiTableBody>
                    </MuiTable>
                </MuiTableContainer>
                <MuiTablePagination
                    component="div"
                    rowsPerPageOptions={ROW_PER_PAGE}
                    count={questions.length}
                    rowsPerPage={this.state.rowsPerPage}
                    page={this.state.page}
                    onPageChange={this.onPageChange}
                    onRowsPerPageChange={this.onRowsPerPageChange}
                />
            </Stack>
        );
    }
}

const styles = {
    root: {
        marginRight: 10,
        paddingBottom: 150,
        minWidth: 900,
        maxWidth: 900,
        // alignSelf: "center",
    } as CSSProperties,
    table: {
        minWidth: 900,
        maxWidth: 900,
    },
};

export default Table;
