import React, { Fragment, ReactNode } from "react";
import Stack from "@mui/material/Stack";
import { QuestionTestType } from "@teyalite/hackbio-common/dist/types/question-test-type.enum";
import {
    FillInGapTest,
    MCQTest,
    QuestionTest,
    ReorderListTest,
    TypeItIn,
} from "../../types";
import { Button, IconButton, Radio, Typography } from "@mui/material";
import { Edit } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import QuestionBlueInput from "./QuestionBlueInput";
import { FILL_IN_THE_GAP_INDICATOR } from "../../constants";

export default function QuestionTestDisplay({
    onEdit,
    onSave = () => {},
    questionTest,
    showSave = false,
}: {
    questionTest: QuestionTest;
    onEdit?: (type: QuestionTestType, questionTest: QuestionTest) => void;
    showSave?: boolean;
    onSave?: (test: QuestionTest) => void;
}) {
    const { type, title, trials, xp, explanation } = questionTest;

    if (type === QuestionTestType.TypeItIn) {
        const content = questionTest.content as TypeItIn;

        return (
            <QuestionTestContainer
                type={type}
                onEdit={onEdit ? () => onEdit(type, questionTest) : undefined}
                title={title}
                explanation={explanation}
                trials={trials}
                showSave={showSave}
                onSave={() => onSave(questionTest)}
                xp={xp}
            >
                <DisplayTestQuestion>{content.question}</DisplayTestQuestion>
                <QuestionBlueInput
                    placeholder="Answer"
                    label="Answer"
                    value={content.answer}
                    disabled
                />
            </QuestionTestContainer>
        );
    }

    if (type === QuestionTestType.FillInGap) {
        const content = questionTest.content as FillInGapTest;

        const htmlContent = replaceBox(content.question, content.answers).split(
            /\r|\r|\n/g
        );

        return (
            <QuestionTestContainer
                type={type}
                onEdit={onEdit ? () => onEdit(type, questionTest) : undefined}
                title={title}
                showSave={showSave}
                explanation={explanation}
                onSave={() => onSave(questionTest)}
                trials={trials}
                xp={xp}
            >
                <Typography component="div">
                    {htmlContent.map((txt, index) => (
                        <Stack
                            key={index}
                            alignItems="center"
                            direction="row"
                            component={"div"}
                            flexWrap={"wrap"}
                            sx={{ lineHeight: 3 }}
                            dangerouslySetInnerHTML={{ __html: txt }}
                        ></Stack>
                    ))}
                </Typography>
                <Stack spacing={3}>
                    {content.answers.map((box, index) => (
                        <QuestionBlueInput
                            key={index}
                            placeholder={`Box ${index + 1}`}
                            label={`Box ${index + 1}`}
                            value={box}
                            disabled
                        />
                    ))}
                </Stack>
            </QuestionTestContainer>
        );
    }

    if (type === QuestionTestType.MCQ) {
        const content = questionTest.content as MCQTest;

        return (
            <QuestionTestContainer
                type={type}
                showSave={showSave}
                onSave={() => onSave(questionTest)}
                onEdit={onEdit ? () => onEdit(type, questionTest) : undefined}
                title={title}
                explanation={explanation}
                trials={trials}
                xp={xp}
            >
                <DisplayTestQuestion>{content.question}</DisplayTestQuestion>
                <Stack spacing={2}>
                    {content.answers.map(({ text, correct }, index) => (
                        <Stack key={index} direction="row">
                            <Radio checked={correct} />
                            <QuestionBlueInput
                                key={index}
                                placeholder={`Option ${index + 1}`}
                                label={`Option ${index + 1}`}
                                value={text}
                                disabled
                            />
                        </Stack>
                    ))}
                </Stack>
            </QuestionTestContainer>
        );
    }

    if (type === QuestionTestType.ReorderList) {
        const content = questionTest.content as ReorderListTest;

        return (
            <QuestionTestContainer
                showSave={showSave}
                type={type}
                onSave={() => onSave(questionTest)}
                onEdit={onEdit ? () => onEdit(type, questionTest) : undefined}
                title={title}
                explanation={explanation}
                trials={trials}
                xp={xp}
            >
                <DisplayTestQuestion>{content.question}</DisplayTestQuestion>
                <Stack spacing={2}>
                    {content.list.map((box, index) => (
                        <QuestionBlueInput
                            key={index}
                            placeholder={`${index + 1}`}
                            label={`${index + 1}`}
                            value={box}
                            disabled
                        />
                    ))}
                </Stack>
            </QuestionTestContainer>
        );
    }

    return null;
}

export function replaceBox(question: string, list: string[]) {
    for (let index = 0; index < list.length; index++) {
        question = question.replace(
            FILL_IN_THE_GAP_INDICATOR,
            "<span class='fill-in-gap-box'></span>"
        );
    }

    return question;
}

function DisplayTestQuestion({ children }: { children: string }) {
    return (
        <Typography>
            {children.split(/\r|\r|\n/g).map((txt, index) => (
                <Fragment key={index}>
                    {txt}
                    <br />
                </Fragment>
            ))}
        </Typography>
    );
}

function QuestionTestContainer({
    onEdit,
    title,
    type,
    trials,
    xp,
    children,
    explanation,
    showSave,
    onSave,
}: {
    type: QuestionTestType;
    title: string;
    xp: number;
    trials: number;
    explanation: string[];
    onEdit?: () => void;
    children?: ReactNode;
    showSave: boolean;
    onSave: () => void;
}) {
    return (
        <Stack
            sx={{
                background: "rgba(196, 196, 196, 0.2)",
                width: "700px",
                borderRadius: "5px",
                py: 2,
                px: 1.5,
            }}
            spacing={2}
        >
            <Stack alignItems="center" direction="row">
                <Stack
                    flexGrow={1}
                    alignItems="center"
                    direction="row"
                    spacing={5}
                >
                    <Typography variant="h6" fontWeight="bold">
                        {title}
                    </Typography>
                    <Stack alignItems="center" direction="row" spacing={2}>
                        {onEdit && (
                            <IconButton onClick={onEdit}>
                                <Edit />
                            </IconButton>
                        )}
                        {showSave && (
                            <Button
                                onClick={onSave}
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                disableElevation
                            >
                                Save Changes
                            </Button>
                        )}
                    </Stack>
                </Stack>
                <em style={{ color: "red" }}>{type}</em>
            </Stack>
            <Stack sx={{ background: "white" }}>
                <Stack p={2} spacing={2}>
                    {children}
                </Stack>
                <Stack p={2} spacing={0.5}>
                    <Typography fontWeight="bold">Explanation</Typography>
                    <Typography pl={0.5}>
                        {explanation.length !== 0 &&
                            explanation.map((val, index) => (
                                <Fragment key={index}>
                                    {val}
                                    <br />
                                </Fragment>
                            ))}
                        {explanation.length === 0 && <>No explanation</>}
                    </Typography>
                </Stack>
            </Stack>
            <Stack direction="row" spacing={10}>
                {[
                    { label: "Numer of trials", value: trials },
                    { label: "XP", value: xp },
                ].map(({ label, value }, index) => (
                    <Typography key={index + label}>
                        {label}
                        {": "}
                        <strong
                            style={{
                                color: label === "XP" ? "#CF6D19" : "#219653",
                            }}
                        >
                            {value}
                        </strong>
                    </Typography>
                ))}
            </Stack>
        </Stack>
    );
}
