import { Alert, OutlinedInput, Stack, Typography } from "@mui/material";
import { ChangeEvent, Component, startTransition } from "react";
import { FILL_IN_THE_GAP_INDICATOR } from "../../constants";
import { QuestionTest } from "../../types";
import DialogForm from "../DialogForm";
import Input from "../Input";
import QuestionBlueInput from "./QuestionBlueInput";

type Props = {
    questionTest: QuestionTest;
    onClose: () => void;
    onSubmit: (data: {
        id: number;
        title: string;
        trials: number;
        xp: number;
        explanation: string[];
        hint: string[];
        fillInGap: { answers: string[]; question: string };
    }) => void;
};

type State = {
    title: string;
    trials: string;
    xp: string;
    explanation: string;
    hint: string;

    question: string;
    answers: string[];
    triggered: boolean;
};

export default class FillInGapForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            title: props.questionTest.title,
            explanation: props.questionTest.explanation.join("\r\n"),
            hint: props.questionTest.hint.join("\r\n"),
            trials: String(props.questionTest.trials),
            xp: String(props.questionTest.xp),
            question: props.questionTest.content.question,
            answers: props.questionTest.content.answers,
            triggered: false,
        };
    }

    onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            title: e.currentTarget.value,
        });
    };

    onChangeQuestion = (e: ChangeEvent<HTMLTextAreaElement>) => {
        const value = e.currentTarget.value;

        this.setState({
            question: value,
        });

        startTransition(() => {
            const len = (value.match(/_BOX_/g) ?? []).length;

            if (this.state.answers.length > len) {
                const answers = this.state.answers.slice();

                answers.pop();

                this.setState({
                    answers,
                });
            } else if (this.state.answers.length < len) {
                this.setState({
                    answers: [...this.state.answers, ""],
                });
            } else {
                this.setState({
                    question: e.currentTarget.value,
                });
            }
        });
    };

    onChangeAnswer = (index: number, value: string) => {
        const answers = [...this.state.answers.slice()];

        answers[index] = value;

        this.setState({
            answers: [...answers],
        });
    };

    onChangeTrials = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            trials: e.currentTarget.value,
        });
    };

    onChangeExplanation = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            explanation: e.currentTarget.value,
        });
    };

    onChangeHint = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            hint: e.currentTarget.value,
        });
    };

    onChangeXP = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            xp: e.currentTarget.value,
        });
    };

    onSubmit = () => {
        const { title, answers, question, trials, xp, explanation, hint } =
            this.state;

        // todo: make verification
        const explanationValue = explanation.split(/\r|\r|\n/g);
        const hintValue = hint.split(/\r|\r|\n/g);

        const {
            questionTest: { id },
        } = this.props;
        this.props.onSubmit({
            title,
            fillInGap: { answers, question },
            trials: Number(trials),
            xp: Number(xp),
            explanation:
                explanationValue.length === 1 && explanationValue[0] === ""
                    ? []
                    : explanationValue,
            hint:
                hintValue.length === 1 && hintValue[0] === "" ? [] : hintValue,
            id,
        });
    };

    render() {
        const { title, trials, xp, question, answers, explanation, hint } =
            this.state;

        const htmlContent = replaceBox(question, answers).split(/\r|\r|\n/g);

        return (
            <DialogForm
                title="MCQ"
                onClose={this.props.onClose}
                onSubmit={this.onSubmit}
                submitButtonText="Preview changes"
                isLoading={false}
            >
                <Stack spacing={3} pb={3}>
                    <Input
                        placeholder="Title"
                        size="medium"
                        value={title}
                        onChange={this.onChangeTitle}
                        sx={styles.input}
                    />

                    <Alert severity="info">
                        To add box type{" "}
                        <strong>{FILL_IN_THE_GAP_INDICATOR}</strong>
                    </Alert>

                    <textarea
                        rows={4}
                        placeholder="Question..."
                        className="question-input"
                        value={question}
                        onChange={this.onChangeQuestion}
                    ></textarea>

                    <Stack spacing={1}>
                        <Typography>Preview</Typography>
                        <Typography
                            component="div"
                            sx={{
                                border: "1px solid #00000030",
                                borderRadius: 2,
                                p: 1,
                                color: "black",
                            }}
                        >
                            {htmlContent.map((txt, index) => (
                                <Stack
                                    key={index}
                                    alignItems="center"
                                    direction="row"
                                    component={"div"}
                                    flexWrap={"wrap"}
                                    sx={{ lineHeight: 3 }}
                                    dangerouslySetInnerHTML={{ __html: txt }}
                                ></Stack>
                            ))}
                        </Typography>
                    </Stack>

                    {answers.map((value, index) => (
                        <QuestionBlueInput
                            key={index}
                            placeholder={`Box ${index + 1} answer`}
                            label={`Box ${index + 1} answer`}
                            value={value}
                            onChange={(e: ChangeEvent<HTMLTextAreaElement>) =>
                                this.onChangeAnswer(
                                    index,
                                    e.currentTarget.value
                                )
                            }
                        />
                    ))}

                    <Stack spacing={0.5}>
                        <Typography fontWeight="bold">Explanation:</Typography>
                        <textarea
                            rows={4}
                            placeholder="Explanation..."
                            className="question-input"
                            value={explanation}
                            onChange={this.onChangeExplanation}
                        ></textarea>
                    </Stack>

                    <Stack spacing={0.5}>
                        <Typography fontWeight="bold">Hint:</Typography>
                        <textarea
                            rows={4}
                            placeholder="Hint..."
                            className="question-input"
                            value={hint}
                            onChange={this.onChangeHint}
                        ></textarea>
                    </Stack>

                    <TwoRowBlueInput
                        placeholder="Number of trials"
                        value={trials}
                        onChange={this.onChangeTrials}
                    />

                    <TwoRowBlueInput
                        placeholder="Experience point (XP)"
                        value={xp}
                        onChange={this.onChangeXP}
                    />
                </Stack>
            </DialogForm>
        );
    }
}

export function TwoRowBlueInput({
    placeholder,
    value,
    onChange,
    disabled = false,
}: {
    placeholder: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
    disabled?: boolean;
}) {
    return (
        <Stack
            sx={{ width: "300px" }}
            spacing={1}
            direction="row"
            alignItems="center"
        >
            <Typography
                sx={{
                    background: "rgba(47, 128, 237, 0.2)",
                    borderLeft: "3px solid #6F71E0",
                    height: "35px",
                    pt: 0.8,
                    pl: 1,
                    flexGrow: 1,
                }}
            >
                {placeholder}
            </Typography>
            <OutlinedInput
                sx={{ width: "70px", height: "35px" }}
                onChange={onChange}
                value={value}
                type="number"
                disabled={disabled}
                inputProps={{ min: 0 }}
            />
        </Stack>
    );
}

export function replaceBox(question: string, list: string[]) {
    for (let index = 0; index < list.length; index++) {
        question = question.replace(
            FILL_IN_THE_GAP_INDICATOR,
            "<span class='fill-in-gap-box'></span>"
        );
    }

    return question;
}

const styles = {
    input: {
        width: "542px",
        alignSelf: "center",
    },
};
