import { Fragment, PureComponent } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { FeedbackAnswer } from "../../../types";
import CustomTableRow from "../../TableRow";
import MuiTableCell from "@mui/material/TableCell";
import TextTabCell from "../../TextTabCell";
import { Typography } from "@mui/material";

const maxCellWidth: number = 150;

type Props = {
    feedback: FeedbackAnswer;
};

type State = {};

class TableRowCC extends PureComponent<
    Props & { navigate: NavigateFunction },
    State
> {
    render() {
        const { feedback } = this.props;

        return (
            <CustomTableRow>
                <TextTabCell maxWidth={maxCellWidth}>
                    {feedback.user.name}
                </TextTabCell>
                <TextTabCell maxWidth={maxCellWidth}>
                    <Typography
                        component="a"
                        noWrap
                        href={"mailto:" + feedback.user.email}
                    >
                        {feedback.user.email}
                    </Typography>
                </TextTabCell>
                <MuiTableCell>
                    {Array.isArray(feedback.content) ? (
                        <>
                            {feedback.content.map((txt, idx) => (
                                <li key={idx}>
                                    {txt}
                                    <br />
                                </li>
                            ))}
                        </>
                    ) : (
                        feedback.content
                    )}
                </MuiTableCell>
            </CustomTableRow>
        );
    }
}

export default function TableRow(props: Props) {
    const navigate = useNavigate();

    return <TableRowCC {...props} navigate={navigate} />;
}
