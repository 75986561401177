import axios from "axios";
import { API_BASE_URL, IS_PRODUCTION } from "../constants";
import { sleep } from "./sleep";

const withCredentials = !IS_PRODUCTION;

export function getUrl(pathname: string) {
    const url = new URL(`${API_BASE_URL}${pathname}`, window.location.origin);
    return url;
}

/**
 *
 * @param pathname
 * @param data
 * @returns
 */
export async function getRequest<T = any>(pathname: string): Promise<T> {
    const url = getUrl(pathname);

    if (!IS_PRODUCTION) {
        console.log("GET", url.href);

        await sleep(1);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: "GET",
            url: url.href,
            withCredentials,
        })
            .then((response) => {
                resolve(response.data as T);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 *
 * @param pathname
 * @param data
 * @returns
 */
export async function postRequest<T = any>(
    pathname: string,
    data: any
): Promise<T> {
    const url = getUrl(pathname);
    if (!IS_PRODUCTION) {
        console.log("POST", url.href);

        await sleep(1);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: "POST",
            url: url.href,
            data: data,
            withCredentials,
        })
            .then((response) => {
                resolve(response.data as T);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 *
 * @param pathname
 * @param data
 * @returns
 */
export async function patchRequest<T = any>(
    pathname: string,
    data: any
): Promise<T> {
    const url = getUrl(pathname);
    if (!IS_PRODUCTION) {
        console.log("PATCH", url.href);

        await sleep(1);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: "PATCH",
            url: url.href,
            data: data,
            withCredentials,
        })
            .then((response) => {
                resolve(response.data as T);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 *
 * @param pathname
 * @param data
 * @returns
 */
export async function putRequest<T = any>(
    pathname: string,
    data: any
): Promise<T> {
    const url = getUrl(pathname);
    if (!IS_PRODUCTION) {
        console.log("PUT", url.href);

        await sleep(1);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: "PUT",
            url: url.href,
            data: data,
            withCredentials,
        })
            .then((response) => {
                resolve(response.data as T);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}

/**
 *
 * @param pathname
 * @param data
 * @returns
 */
export async function deleteRequest<T = any>(
    pathname: string,
    data?: any
): Promise<T> {
    const url = getUrl(pathname);
    if (!IS_PRODUCTION) {
        console.log("DELETE", url.href);

        await sleep(1);
    }

    return new Promise((resolve, reject) => {
        axios({
            method: "DELETE",
            url: url.href,
            data: data,
            withCredentials,
        })
            .then((response) => {
                resolve(response.data as T);
            })
            .catch((error) => {
                console.log(error);
                reject(error);
            });
    });
}
