import React, { createRef } from "react";
import DialogForm from "../DialogForm";
import Editor from "./Editor";
import EditorButton from "./Button";

type Props = {
    title: string;
    isLoading?: boolean; // True when form is being submitted
    open?: boolean; // Open the form
    onClose: () => void;
    initialState: any;
    onSubmit: (editorState: any, htmlString: string) => void;
};

export default function DialogEditor({
    title,
    open,
    onClose,
    onSubmit,
    isLoading,
    initialState,
}: Props) {
    const buttonRef = createRef<HTMLButtonElement>();

    const handleSubmit = () => {
        buttonRef.current?.click();
    };

    return (
        <DialogForm
            open={open}
            PaperSx={styles}
            title={title}
            submitButtonText="Save"
            onClose={onClose}
            onSubmit={handleSubmit}
            minWidth={600}
            isLoading={isLoading}
        >
            <Editor
                placeholder="Enter some rich text..."
                initialState={initialState}
                button={<EditorButton onSave={onSubmit} ref={buttonRef} />}
            />
        </DialogForm>
    );
}

const styles = {
    minWidth: "650px",
};
