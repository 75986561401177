import {
    defaultFeedbacksState,
    FeedbacksState,
    FeedbacksAction,
    FeedbacksActionType,
} from "./types";

function feedbacksReducer(
    state: FeedbacksState = defaultFeedbacksState,
    action: FeedbacksAction
): FeedbacksState {
    switch (action.type) {
        case FeedbacksActionType.FetchFeedbackTests:
            state = {
                ...state,
                ...action.value,
            };
            return state;

        case FeedbacksActionType.FetchFeedbackDetails:
            state = {
                ...state,
                details: action.value,
            };
            return state;

        default:
            return state;
    }
}

export default feedbacksReducer;
