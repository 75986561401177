import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { CBWhiteButton } from "./CBTopBlueBox";

// todo:minor add see syllabus https://www.notion.so/hackbio/Introduction-to-Molecular-Biology-bb5e0a5703914053b736b75d137e2acf
export default function CBAbout({
    video,
    contentTitle,
    syllabus,
    children,
}: {
    syllabus: string;
    video: string;
    contentTitle: string;
    children: string;
}) {
    return (
        <Box className="bgcolor-yellow" sx={{ width: "100%" }}>
            <Stack
                component={Container}
                alignItems="center"
                spacing={5}
                sx={{ py: 4 }}
            >
                <Typography
                    variant="h4"
                    fontWeight="bold"
                    sx={{ display: { xs: "block", md: "none" } }}
                >
                    {contentTitle}
                </Typography>
                <Stack
                    direction={{
                        xs: "column-reverse",
                        md: "row",
                    }}
                    sx={{ width: "100%" }}
                >
                    <Stack
                        spacing={5}
                        sx={{
                            flex: 1.5,
                            pt: { xs: 3, md: 0 },
                            textAlign: { xs: "center", md: "left" },
                        }}
                    >
                        <Typography
                            variant="h4"
                            fontWeight="bold"
                            sx={{ display: { xs: "none", md: "block" } }}
                        >
                            {contentTitle}
                        </Typography>
                        <Typography
                            variant="h6"
                            sx={{ lineHeight: "40px" }}
                            component="div"
                            dangerouslySetInnerHTML={{ __html: children }}
                        />
                        <Stack
                            direction={{ xs: "column", sm: "row" }}
                            alignItems="center"
                            spacing={3}
                        >
                            <div>
                                <CBWhiteButton>START LEARNING</CBWhiteButton>
                            </div>
                            <div>
                                <CBWhiteButton
                                    disabled={false}
                                    buttonProps={{
                                        href: syllabus,
                                        LinkComponent: "a",
                                        ...({
                                            target: "_blank",
                                            rel: "noopener noreferrer",
                                        } as any),
                                    }}
                                >
                                    📘 SEE SYLLABUS
                                </CBWhiteButton>
                            </div>
                        </Stack>
                    </Stack>
                    <Box
                        sx={{
                            flex: 1,
                            display: "flex",
                            justifyContent: "center",
                            alignItems: "center",
                            py: 2,
                        }}
                    >
                        <iframe
                            style={{
                                maxWidth: 400,
                                minWidth: 300,
                                width: "100%",
                                height: 250,
                            }}
                            title="Course video"
                            src={video}
                        />
                    </Box>
                </Stack>
            </Stack>
        </Box>
    );
}
