import {
    defaultCourseModuleState,
    CourseModuleState,
    CourseModuleActionType,
    CourseModuleAction,
} from "../types/course-module";

function courseModuleReducer(
    state: CourseModuleState = defaultCourseModuleState,
    action: CourseModuleAction
): CourseModuleState {
    switch (action.type) {
        case CourseModuleActionType.FetchCourseModules:
            state = {
                ...state,
                modules: {
                    ...state.modules,
                    ...action.value,
                },
            };
            return state;

        case CourseModuleActionType.FetchModuleDetails:
            state = {
                ...state,
                details: {
                    ...state.details,
                    ...action.value,
                },
            };
            return state;

        case CourseModuleActionType.AddModule:
            state = {
                ...state,
                modules: {
                    ...state.modules,
                    modules: [...state.modules.modules, action.value],
                },
            };
            return state;

        case CourseModuleActionType.UpdateModule: {
            const modules = state.modules.modules.slice();

            const index = modules.findIndex(
                (module) => module.id === action.value.moduleId
            );

            if (index >= 0) {
                modules[index] = {
                    ...modules[index],
                    title: action.value.title,
                    languageType: action.value.languageType,
                };
            }

            state = {
                ...state,
                details: {
                    ...state.details,
                    details: {
                        ...state.details.details!,
                        title: action.value.title,
                        languageType: action.value.languageType,
                    },
                },
                modules: {
                    ...state.modules,
                    modules: [...modules],
                },
            };
            return state;
        }

        case CourseModuleActionType.RemoveSubmodule: {
            if (state.details.details === null) return state;

            state = {
                ...state,
                details: {
                    ...state.details,
                    details: {
                        ...state.details.details,
                        submodules: [
                            ...state.details.details.submodules.filter(
                                (submodule) => submodule.id !== action.value
                            ),
                        ],
                    },
                },
            };

            return state;
        }

        case CourseModuleActionType.UpdateSubmodule: {
            if (state.details.details === null) return state;

            const submodules = state.details.details.submodules.slice();

            const index = submodules.findIndex(
                (submodule) => submodule.id === action.value.id
            );

            if (index >= 0) {
                submodules[index] = {
                    ...submodules[index],
                    ...action.value,
                };
            }

            state = {
                ...state,
                details: {
                    ...state.details,
                    details: {
                        ...state.details.details,
                        submodules: [...submodules],
                    },
                },
            };
            return state;
        }

        case CourseModuleActionType.AddSubmodule:
            state = {
                ...state,
                details: {
                    ...state.details,
                    details: {
                        ...state.details.details!,
                        submodules: [
                            ...state.details.details!.submodules,
                            action.value,
                        ],
                    },
                },
            };
            return state;

        default:
            return state;
    }
}

export default courseModuleReducer;
