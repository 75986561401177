import { Delete, Edit } from "@mui/icons-material";
import SaveIcon from "@mui/icons-material/Save";
import { Box, Button, IconButton, Stack, Typography } from "@mui/material";
import { SubmoduleType } from "@teyalite/hackbio-common/dist/types/submodule-type.enum";
import { PropsWithChildren, useState } from "react";
import { QuestionDetails, RichHTML, Submodule } from "../../types";
import theme from "../../utils/theme";
import SlideShow from "../SlideShow";
import VideoShow from "../VideoShow";
import EditorPreview from "../editor/EditorPreview";
import QuestionTestDisplay from "../questions/QuestionTestDisplay";

export default function SubmoduleDisplay({
    submodule,
    editorKey,
    onEdit,
    showSave = false,
    onDelete,
    onSave,
}: {
    submodule: Submodule;
    editorKey?: string;
    showSave?: boolean;
    onSave: (submodule: Submodule) => void;
    onDelete: (type: SubmoduleType, submoduleId: number) => void;
    onEdit: (
        type: SubmoduleType,
        submoduleId: number,
        title: string,
        content: any
    ) => void;
}) {
    const { type, content, title, id } = submodule;

    if (type === SubmoduleType.Test) {
        const question = content as QuestionDetails;

        return (
            <DisplayContainer
                showSave={showSave}
                onDelete={() => onDelete(type, id)}
                title={title}
                type={type}
                onSave={() => onSave(submodule)}
                onEdit={() => onEdit(type, id, title, content)}
            >
                <SubmoduleQuestion question={question} />
            </DisplayContainer>
        );
    }

    if (type === SubmoduleType.Video) {
        const videoContent = content as { videoUrl: string };

        return (
            <DisplayContainer
                onSave={() => onSave(submodule)}
                showSave={showSave}
                onDelete={() => onDelete(type, id)}
                title={title}
                type={type}
                onEdit={() => onEdit(type, id, title, content)}
            >
                <VideoShow src={videoContent.videoUrl} title={title} />
            </DisplayContainer>
        );
    }

    if (type === SubmoduleType.Slide) {
        const slideContent = content as { slideUrl: string };

        return (
            <DisplayContainer
                showSave={showSave}
                onDelete={() => onDelete(type, id)}
                onSave={() => onSave(submodule)}
                title={title}
                type={type}
                onEdit={() => onEdit(type, id, title, content)}
            >
                <SlideShow src={slideContent.slideUrl} title={title} />
            </DisplayContainer>
        );
    }

    const editorContent = content as RichHTML;

    return (
        <DisplayContainer
            key={editorKey + type + id}
            onSave={() => onSave(submodule)}
            onDelete={() => onDelete(type, id)}
            showSave={showSave}
            title={title}
            type={type}
            onEdit={() => onEdit(type, id, title, content)}
        >
            <EditorPreview initialState={editorContent.lexicalState} />
        </DisplayContainer>
    );
}

function DisplayContainer({
    children,
    title,
    type,
    onDelete,
    showSave,
    onSave,
    onEdit,
}: PropsWithChildren & {
    title: string;
    type: string;
    onEdit: () => void;
    onSave: () => void;
    onDelete: () => void;
    showSave: boolean;
}) {
    const handleDelete = () => {
        const confirm = window.confirm(
            "Are you sure you want section: " + title
        );

        if (confirm) {
            onDelete();
        }
    };

    return (
        <Stack
            sx={{
                py: 2,
                background: "rgb(25 210 61 / 12%)",
                borderRadius: 3,
            }}
            spacing={1}
            px={3}
        >
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
            >
                <Stack spacing={10} direction="row" alignItems="center">
                    <Typography variant="h6">
                        Section: <strong>{title}</strong>
                    </Typography>
                    <Stack direction="row" spacing={3}>
                        <IconButton onClick={onEdit}>
                            <Edit />
                        </IconButton>

                        <IconButton onClick={handleDelete}>
                            <Delete />
                        </IconButton>

                        {showSave && (
                            <Button
                                onClick={onSave}
                                startIcon={<SaveIcon />}
                                variant="outlined"
                                disableElevation
                            >
                                Save Changes
                            </Button>
                        )}
                    </Stack>
                </Stack>
                <strong style={{ color: "red" }}>
                    <em>{type}</em>
                </strong>
            </Stack>
            <Stack sx={{ background: "white", p: 2 }}>{children}</Stack>
        </Stack>
    );
}

function SubmoduleQuestion({ question }: { question: QuestionDetails }) {
    const [index, setIndex] = useState(0);

    if (question.tests.length === 0) {
        return (
            <Typography variant="h6" color={theme.palette.warning.main}>
                The linked question doesn't contain any test
            </Typography>
        );
    }

    return (
        <Stack spacing={2} minHeight="550px">
            <Box flexGrow={1}>
                <QuestionTestDisplay questionTest={question.tests[index]} />
            </Box>
            <Stack direction="row" spacing={3}>
                <Button
                    variant="contained"
                    disableElevation
                    disabled={index === 0}
                    onClick={() => setIndex(index - 1)}
                >
                    Previous test
                </Button>
                <Button
                    variant="contained"
                    disableElevation
                    disabled={index === question.tests.length - 1}
                    onClick={() => setIndex(index + 1)}
                >
                    Next test
                </Button>
            </Stack>
        </Stack>
    );
}
