import { DrawerLink } from "../types";
import HomeIcon from "@mui/icons-material/Home";
import SchoolIcon from "@mui/icons-material/School";
import SettingsIcon from "@mui/icons-material/Settings";
import QuestionMarkIcon from "@mui/icons-material/QuestionMark";
import LibraryBooksIcon from "@mui/icons-material/LibraryBooks";
import { Feedback } from "@mui/icons-material";

export const HOME: DrawerLink = {
    href: "/",
    text: "Home",
    icon: <HomeIcon />,
};

export const SETTINGS: DrawerLink = {
    href: "/settings",
    text: "Settings",
    icon: <SettingsIcon />,
};

export const FEEDBACKS: DrawerLink = {
    href: "/feedbacks",
    text: "Feedbacks",
    icon: <Feedback />,
};

export const COURSES: DrawerLink = {
    href: "/courses",
    text: "Courses",
    icon: <SchoolIcon />,
};

export const BOOTCAMPS: DrawerLink = {
    href: "/bootcamps",
    text: "Bootcamps",
    icon: <HomeIcon />,
};

export const QUESTIONS: DrawerLink = {
    href: "/questions",
    text: "Questions",
    icon: <QuestionMarkIcon />,
};

export const HACKBERRY: DrawerLink = {
    href: "/hackberry",
    text: "Hackberry",
    icon: <HomeIcon />,
};

export const DOCS: DrawerLink = {
    href: "https://hackbio.notion.site/Documentations-537beec66a624531a93842570b6c39c7",
    text: "Docs",
    icon: <LibraryBooksIcon />,
    external: true,
};
