/**
 * Course details image container
 */
import React, { CSSProperties } from "react";
import Stack from "@mui/material/Stack";
import Paper from "@mui/material/Paper";
import EditHead from "./EditHead";
import { SxProps } from "@mui/material";

type Props = {
    src: string;
    title: string;
    width: number;
    height: number;
    imageStyle?: CSSProperties;
    onClick: () => void;
    sx?: SxProps;
};

export default function ImageContainer({
    src,
    title,
    width,
    height,
    imageStyle,
    onClick,
    sx,
}: Props) {
    return (
        <Stack spacing={1} sx={{ alignSelf: "flex-start", ...sx }}>
            <EditHead title={title} onClick={onClick} />
            <Paper sx={styles.Paper} elevation={2}>
                <img
                    src={src}
                    width={width}
                    height={height}
                    style={imageStyle}
                    alt={title}
                />
            </Paper>
        </Stack>
    );
}

const styles = {
    Paper: { alignSelf: "flex-start", p: 3 },
};
