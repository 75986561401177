import React, { ChangeEvent, useState, FormEvent } from "react";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Chip from "@mui/material/Chip";
import Input from "../Input";
import { requiredErrorMessage } from "@teyalite/hackbio-common/dist/error-messages";
import { Biostack } from "../../types";
import { CONNECTION_FAILED } from "../../constants";
import { postRequest } from "../../utils/http";
import Loading from "../LoadingIndicator";

const ERROR_MESSAGE = requiredErrorMessage("This field");

export default function SettingsBiostack({
    biostacks,
    onAddBiostack,
}: {
    biostacks: Biostack[];
    onAddBiostack: (biostack: Biostack) => void;
}) {
    const [biostack, setBiostack] = useState("");
    const [triggered, setTriggered] = useState(false);
    const [loading, setLoading] = useState(false);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setBiostack(e.currentTarget.value);
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();

        if (biostack.length === 0) {
            setTriggered(true);
            return;
        }
        setLoading(true);

        try {
            const createdBiostack = await postRequest("/setting/biostack", {
                name: biostack,
            });

            setBiostack("");

            onAddBiostack(createdBiostack);
        } catch (error) {
            window.alert(CONNECTION_FAILED);
        }

        setLoading(false);
    };

    return (
        <Stack spacing={4}>
            <Typography variant="h5" fontWeight="bold">
                Biostack Creation
            </Typography>
            <Stack
                component="form"
                onSubmit={handleSubmit}
                sx={{ width: 300 }}
                spacing={2}
            >
                <Typography>Start typing to add a new biostack</Typography>
                <Stack direction="row" alignItems="center" spacing={2}>
                    <Input
                        onChange={handleChange}
                        size="small"
                        value={biostack}
                        placeholder="Start typing"
                        error={triggered && biostack.length === 0}
                        errorMessage={ERROR_MESSAGE}
                    />
                    {loading && <Loading />}
                </Stack>
            </Stack>
            <Stack direction="row" spacing={1}>
                {biostacks.map((biostack) => (
                    <Chip label={biostack.name} key={biostack.id} />
                ))}
            </Stack>
        </Stack>
    );
}
