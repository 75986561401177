import { Visibility, VisibilityOff } from "@mui/icons-material";
import MuiTableCell from "@mui/material/TableCell";
import { MouseEvent, PureComponent } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { Course } from "../../types";
import CustomTableRow, { tableLinkStyle } from "../TableRow";
import TextTabCell from "../TextTabCell";

const maxCellWidth: number = 150;

type Props = {
    course: Course;
};

type State = {};

class TableRowCC extends PureComponent<
    Props & { navigate: NavigateFunction },
    State
> {
    generateHref = (courseFullId: string) => `/courses/${courseFullId}`;

    onClick = () => {
        const { course, navigate } = this.props;

        navigate(this.generateHref(course.fullId));
    };

    render() {
        const { course } = this.props;

        return (
            <CustomTableRow onClick={this.onClick}>
                <TextTabCell maxWidth={maxCellWidth}>
                    <Link
                        style={tableLinkStyle}
                        to={this.generateHref(course.fullId)}
                        onClick={(e: MouseEvent<HTMLAnchorElement>) =>
                            e.preventDefault()
                        }
                    >
                        {course.title}
                    </Link>
                </TextTabCell>
                <TextTabCell align="right">{course.biostack.name}</TextTabCell>
                <MuiTableCell align="right">{course.price}</MuiTableCell>
                <MuiTableCell align="right">
                    {course.published ? <Visibility /> : <VisibilityOff />}
                </MuiTableCell>
                <MuiTableCell align="right">{course.students}</MuiTableCell>
            </CustomTableRow>
        );
    }
}

export default function TableRow(props: Props) {
    const navigate = useNavigate();

    return <TableRowCC {...props} navigate={navigate} />;
}
