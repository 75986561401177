import { configureStore } from "@reduxjs/toolkit";
import { CourseDetailsState } from "./types/course-details";
import courseDetailsReducer from "./reducers/course-details";
import { CourseState } from "./types/course";
import courseReducer from "./reducers/course";
import { QuestionState } from "./types/question";
import questionReducer from "./reducers/question";
import { SettingState } from "./types/setting";
import settingReducer from "./reducers/setting";
import { CourseModuleState } from "./types/course-module";
import courseModuleReducer from "./reducers/course-module";
import { CourseUsersState } from "./types/course-users";
import courseUsersReducer from "./reducers/course-users";
import feedbacksReducer from "./feedback/reducer";
import { FeedbacksState } from "./feedback/types";
import courseSubmissionsReducer from "./courses/submissions/reducer";
import { CourseSubmissionsState } from "./courses/submissions/types";

interface State {
    courseDetails: CourseDetailsState;
    course: CourseState;
    setting: SettingState;
    question: QuestionState;
    courseModule: CourseModuleState;
    courseUsers: CourseUsersState;
    feedbacks: FeedbacksState;
    courseSubmissions: CourseSubmissionsState;
}

export const store = configureStore<State, any>({
    reducer: {
        courseDetails: courseDetailsReducer,
        course: courseReducer,
        question: questionReducer,
        setting: settingReducer,
        courseModule: courseModuleReducer,
        courseUsers: courseUsersReducer,
        courseSubmissions: courseSubmissionsReducer,
        feedbacks: feedbacksReducer,
    },
});

export type AppDispatch = typeof store.dispatch;
export type AppState = ReturnType<typeof store.getState>;
