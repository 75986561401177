import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { ModuleLanguageType } from "@teyalite/hackbio-common/dist/types/module-language-type.enum";
import { ChangeEvent, Component } from "react";
import { Module } from "../../types";
import DialogForm from "../DialogForm";
import Input from "../Input";
import Select from "../Select";
import { postRequest } from "../../utils/http";
import { CONNECTION_FAILED } from "../../constants";
import { ModuleType } from "@teyalite/hackbio-common/dist/types/module-type.enum";

type Props = {
    open: boolean;
    onClose: () => void;
    addModule: (module: Module) => void;
    courseId: number;
};

type State = {
    isCreatingModule: boolean;

    title: string;
    languageType: ModuleLanguageType | null;
    moduleType: ModuleType | null;

    triggered: boolean;
};

const TITLE_ERROR = "TITLE_ERROR";

const ITEMS = [
    { label: "R", value: ModuleLanguageType.R },
    { label: "Python", value: ModuleLanguageType.Python },
];

const MODULE_TYPES = [
    { label: "Regular", value: ModuleType.Regular },
    { label: "Project", value: ModuleType.Project },
];

const defaultState: State = {
    isCreatingModule: false,

    title: "",
    languageType: null,
    moduleType: null,

    triggered: false,
};

export default class NewModuleForm extends Component<Props, State> {
    private mounted: boolean;
    constructor(props: Props) {
        super(props);
        this.state = {
            ...defaultState,
        };

        this.mounted = false;
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            title: e.currentTarget.value,
        });
    };

    onLanguageType = (event: SelectChangeEvent) => {
        this.setState({
            languageType:
                event.target.value.trim().length === 0
                    ? null
                    : (event.target.value as ModuleLanguageType),
        });
    };

    onModuleType = (event: SelectChangeEvent) => {
        this.setState({
            moduleType:
                event.target.value.trim().length === 0
                    ? null
                    : (event.target.value as ModuleType),
        });
    };

    onSubmit = async () => {
        const { title, languageType, moduleType } = this.state;
        const { addModule, onClose, courseId } = this.props;

        if (title.trim().length === 0) {
            return this.setState({ triggered: true });
        }

        const data: { title: any; languageType: any; type: any } = {
            title,
            languageType,
            type: undefined,
        };

        if (moduleType) {
            data.type = moduleType;
        }

        try {
            const createdModule = await postRequest<Module>(
                "/course/" + courseId + "/module",
                data
            );

            addModule(createdModule);
            this.setState({ ...defaultState });
            onClose();
        } catch (error: any) {
            window.alert(CONNECTION_FAILED);
            this.setState({ isCreatingModule: false });
        }
    };

    render() {
        const { title, languageType, triggered, isCreatingModule, moduleType } =
            this.state;

        const { open, onClose } = this.props;

        return (
            <DialogForm
                open={open}
                title="Add New Module"
                submitButtonText="Add"
                onClose={onClose}
                onSubmit={this.onSubmit}
                isLoading={isCreatingModule}
                PaperSx={{ minWidth: "500px" }}
            >
                <Stack spacing={3}>
                    <Input
                        placeholder="Title"
                        size="medium"
                        value={title}
                        onChange={this.onChangeTitle}
                        error={triggered && title.trim().length === 0}
                        errorMessage={TITLE_ERROR}
                        helperText="It's ok if you can't think of a good title now. You can change it later."
                        sx={styles.input}
                    />
                    <Select
                        items={ITEMS}
                        value={String(languageType ?? "")}
                        onChange={this.onLanguageType}
                        helperText="If you're not sure about the right language, you can change it later."
                        placeholder={
                            <span>
                                <span style={{ color: "#828282" }}>
                                    Module Programming language{" "}
                                </span>
                                <strong>(default to none)</strong>
                            </span>
                        }
                    />
                    <Select
                        items={MODULE_TYPES}
                        value={String(moduleType ?? "")}
                        onChange={this.onModuleType}
                        placeholder="Module type"
                    />
                </Stack>
            </DialogForm>
        );
    }
}

const styles = {
    input: {
        maxWidth: "500px",
        alignSelf: "center",
        width: "100%",
    },
};
