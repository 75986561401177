import AuthInput from "../components/auth/AuthInput";
import { Stack } from "@mui/material";
import Typography from "@mui/material/Typography";
import { emailValidation } from "@teyalite/hackbio-common/dist/email-validtation";
import { passwordValidation } from "@teyalite/hackbio-common/dist/password-validation";
import { BAD_REQUEST, UNAUTHORIZED } from "http-status";
import { ChangeEvent, Component, FormEvent } from "react";
import BackdropLoading from "../components/BackdropLoading";
import BlueButton from "../components/auth/BlueButton";
import PasswordInput from "../components/auth/PasswordInput";
import { CONNECTION_FAILED, IS_PRODUCTION } from "../constants";
import { postRequest } from "../utils/http";
import theme from "../utils/theme";
import { AuthContext } from "../auth.context";
import { AuthContextType, User } from "../types";
import { NavigateFunction, useNavigate } from "react-router-dom";

type Props = {
    navigate: NavigateFunction;
};

type State = {
    errorMessage: string;

    email: string;
    emailError: boolean;
    emailErrorMessage: string;

    password: string;
    passwordError: boolean;
    passwordErrorMessage: string;

    triggered: boolean;
    isLoading: boolean;
};

class LoginCC extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            errorMessage: "",

            email: IS_PRODUCTION ? "" : "hello@gmail.com",
            emailError: IS_PRODUCTION,
            emailErrorMessage: emailValidation(""),

            password: IS_PRODUCTION ? "" : "hellohello",
            passwordError: IS_PRODUCTION,
            passwordErrorMessage: passwordValidation(""),

            triggered: false,
            isLoading: false,
        };
    }

    static contextType = AuthContext;

    /**
     * Handle email field changes
     * @param param0
     */
    onEmailChange = ({ target: { value } }: ChangeEvent<HTMLInputElement>) => {
        const message = emailValidation(value);

        this.setState({
            email: value,
            emailError: Boolean(message),
            emailErrorMessage: message,
        });
    };

    /**
     * handle password changes
     * @param param0
     */
    onPasswordChange = ({
        target: { value },
    }: ChangeEvent<HTMLInputElement>) => {
        const message = passwordValidation(value);

        this.setState({
            password: value,
            passwordError: Boolean(message),
            passwordErrorMessage: message,
        });
    };

    /**
     * Submit signin form
     * @param event
     * @returns
     */
    onSubmit = async (event: FormEvent<HTMLFormElement>) => {
        event.preventDefault();

        const { setAdmin } = this.context as AuthContextType;
        const { navigate } = this.props;
        const { emailError, email, passwordError, password } = this.state;

        if (emailError || passwordError) {
            return this.setState({
                triggered: true,
            });
        }

        this.setState({ isLoading: true, errorMessage: "" });

        try {
            const admin = await postRequest<User>("/auth/signin", {
                email,
                password,
            });

            setAdmin(admin);
            return navigate("/courses");
        } catch (error: any) {
            let message = CONNECTION_FAILED;

            if (
                error.response &&
                [BAD_REQUEST, UNAUTHORIZED].includes(error.response.status)
            ) {
                message = Array.isArray(error.response.data.message)
                    ? error.response.data.message[0]
                    : error.response.data.message;
            }

            this.setState({
                isLoading: false,
                errorMessage: message,
            });
        }
    };

    render() {
        const {
            email,
            password,
            errorMessage,
            emailErrorMessage,
            emailError,
            passwordErrorMessage,
            passwordError,
            triggered,
            isLoading,
        } = this.state;

        const emailErr = emailError && triggered;
        const passwordErr = passwordError && triggered;

        return (
            <Stack
                sx={styles.root}
                className="bgcolor-blue"
                direction="column"
                spacing={4}
            >
                <BackdropLoading open={isLoading} />
                <Typography
                    sx={{
                        color: theme.palette.error.main,
                        maxWidth: "420px",
                    }}
                >
                    {errorMessage}
                </Typography>
                <Stack
                    spacing={3}
                    maxWidth="350px"
                    minWidth="350px"
                    component="form"
                    onSubmit={this.onSubmit}
                >
                    <AuthInput
                        placeholder="Email"
                        onChange={this.onEmailChange}
                        value={email}
                        error={emailErr}
                        helperText={emailErr ? emailErrorMessage : ""}
                        size="medium"
                    />

                    <PasswordInput
                        error={passwordErr}
                        placeholder="Password"
                        value={password}
                        onChange={this.onPasswordChange}
                        helperText={passwordErr ? passwordErrorMessage : ""}
                        size="medium"
                    />
                    <BlueButton type="submit">Login</BlueButton>
                </Stack>
            </Stack>
        );
    }
}

const styles = {
    root: {
        pt: 15,
        width: "100vw",
        height: "100vh",
        alignItems: "center",
    },
};

export default function Login() {
    const navigate = useNavigate();

    return <LoginCC navigate={navigate} />;
}
