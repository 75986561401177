import {
    defaultCourseSubmissionsState,
    CourseSubmissionsState,
    CourseSubmissionsActionType,
    CourseSubmissionsAction,
} from "./types";

function courseSubmissionsReducer(
    state: CourseSubmissionsState = defaultCourseSubmissionsState,
    action: CourseSubmissionsAction
): CourseSubmissionsState {
    switch (action.type) {
        case CourseSubmissionsActionType.FetchCourseSubmissions:
            state = {
                ...state,
                ...action.value,
            };
            return state;

        case CourseSubmissionsActionType.UpdateCourseSubmission:
            const submissions = state.submissions.slice();

            const idx = submissions.findIndex(
                (subm) => subm.id === action.value.id
            );

            if (idx < 0) return state;

            const submission = { ...submissions[idx] };

            submission.content = { ...action.value.content };

            submissions[idx] = { ...submission };

            state = {
                ...state,
                submissions: submissions,
            };

            return state;

        default:
            return state;
    }
}

export default courseSubmissionsReducer;
