import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { ModuleLanguageType } from "@teyalite/hackbio-common/dist/types/module-language-type.enum";
import { ChangeEvent, Component } from "react";
import DialogForm from "../DialogForm";
import Input from "../Input";
import Select from "../Select";

type Props = {
    dialogTitle: string;
    submitButtonText: string;
    open: boolean;
    title: string;
    languageType: ModuleLanguageType | null;
    isLoading: boolean;
    onClose: () => void;
    submit: (title: string, languageType: ModuleLanguageType | null) => void;
};

type State = {
    isCreatingModule: boolean;

    title: string;
    languageType: ModuleLanguageType | null;

    triggered: boolean;
};

const TITLE_ERROR = "TITLE_ERROR";

const ITEMS = [
    { label: "R", value: ModuleLanguageType.R },
    { label: "Python", value: ModuleLanguageType.Python },
];

export default class ModuleForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isCreatingModule: false,

            title: props.title,
            languageType: props.languageType,

            triggered: false,
        };
    }

    onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            title: e.currentTarget.value,
        });
    };

    onLanguageType = (event: SelectChangeEvent) => {
        this.setState({
            languageType:
                event.target.value.trim().length === 0
                    ? null
                    : (event.target.value as ModuleLanguageType),
        });
    };

    onSubmit = async () => {
        const { title, languageType } = this.state;
        const { submit } = this.props;

        if (title.trim().length === 0) {
            return this.setState({ triggered: true });
        }

        submit(title, languageType);
    };

    render() {
        const { title, languageType, triggered } = this.state;

        const { open, onClose, isLoading, dialogTitle, submitButtonText } =
            this.props;

        return (
            <DialogForm
                open={open}
                title={dialogTitle}
                submitButtonText={submitButtonText}
                onClose={onClose}
                onSubmit={this.onSubmit}
                isLoading={isLoading}
                PaperSx={{ minWidth: "500px" }}
            >
                <Stack spacing={3}>
                    <Input
                        placeholder="Title"
                        size="medium"
                        value={title}
                        onChange={this.onChangeTitle}
                        error={triggered && title.trim().length === 0}
                        errorMessage={TITLE_ERROR}
                        helperText="It's ok if you can't think of a good title now. You can change it later."
                        sx={styles.input}
                    />
                    <Select
                        items={ITEMS}
                        value={String(languageType ?? "")}
                        onChange={this.onLanguageType}
                        helperText="If you're not sure about the right language, you can change it later."
                        placeholder="Module Programming language"
                    />
                </Stack>
            </DialogForm>
        );
    }
}

const styles = {
    input: {
        maxWidth: "500px",
        alignSelf: "center",
        width: "100%",
    },
};
