import MuiDrawer from "@mui/material/Drawer";
import List from "@mui/material/List";
import { PropsWithChildren } from "react";
// import { useLocation, useNavigate } from "react-router-dom";
import Logo from "../Logo";
// import DrawerButton from "./DrawerButton";
import DrawerLink from "./DrawerLink";
import ArrowCircleLeftOutlinedIcon from "@mui/icons-material/ArrowCircleLeftOutlined";

export default function Drawer({
    children,
    backButton, // if is present display backbutton
}: PropsWithChildren & { backButton?: { title: string; href: string } }) {
    return (
        <MuiDrawer variant="permanent" PaperProps={PaperProps}>
            <Logo />
            <List>
                {backButton && (
                    <BackButtton
                        text={backButton.title}
                        backHref={backButton.href}
                    />
                )}
                {children}
            </List>
        </MuiDrawer>
    );
}

function BackButtton({ text, backHref }: { text: string; backHref: string }) {
    // const location = useLocation(); // location used to check if navigate from another screen or not.
    // const navigate = useNavigate(); // navigation used to programmatically navigate

    // /**
    //  * Go back when auth isn't first shown
    //  * @returns
    //  */
    // const onBack = () => {
    //     if (location.key === "default") {
    //         return navigate("/courses", { replace: true });
    //     }

    //     navigate(-1);
    // };

    return (
        <DrawerLink
            text={text}
            href={backHref}
            icon={<ArrowCircleLeftOutlinedIcon />}
        />
    );
    // return <DrawerButton text={text} onClick={onBack} />;
}

const styles = {
    // if width is changed then right child should be changed too
    drawerPaper: {
        width: "250px",
        background: "#f6f8fc",
        border: "none",
        px: 2,
    },
};

// Drawer Paper prop
const PaperProps = { sx: styles.drawerPaper };
