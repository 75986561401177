import { FeedbackTest, FeedbackTestDetails } from "../../types";

export type FeedbackDetails = FeedbackTestDetails | null | "loading" | "failed";

export interface FeedbacksState {
    isLoading: boolean;
    failed: boolean;
    tests: FeedbackTest[];
    details: FeedbackDetails;
}

export interface FetchFeedbackTests {
    type: FeedbacksActionType.FetchFeedbackTests;
    value: Omit<FeedbacksState, "details">;
}

export interface FetchFeedbackDetails {
    type: FeedbacksActionType.FetchFeedbackDetails;
    value: FeedbackDetails;
}

export enum FeedbacksActionType {
    FetchFeedbackTests = "FeedbackFetchFeedbackTests",
    FetchFeedbackDetails = "FeedbackFetchFeedbackDetails",
}

export type FeedbacksAction = FetchFeedbackTests | FetchFeedbackDetails;

export const defaultFeedbacksState: FeedbacksState = {
    isLoading: false,
    failed: false,
    tests: [],
    details: null,
};
