import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import MuiLink from "@mui/material/Link";
import { MAX_PROJECT_GRADE } from "@teyalite/hackbio-common/dist/constants";
import { ChangeEvent, Component, useState } from "react";
import { ProjectSubmission } from "../../types";
import { getRequest, postRequest } from "../../utils/http";
import { withCourseId } from "../../utils/with-course";
import { ConnectedProps, connect } from "react-redux";
import { AppState } from "../../redux/store";
import {
    fetchCourseSubmissionsCreator,
    updateCourseSubmissionCreator,
} from "../../redux/courses/submissions/actions";
import Loading from "../../components/Loading";
import Table from "../../components/courses/submissions/Table";
import DialogForm from "../../components/DialogForm";
import theme from "../../utils/theme";
import { TwoRowBlueInput } from "../../components/questions/FillInGapForm";
import { CONNECTION_FAILED } from "../../constants";

type Props = PropsFromRedux & { courseId: number };

type State = { submission: ProjectSubmission | null; isGrading: boolean };

class ProjectGrading extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            submission: null,
            isGrading: false,
        };
    }

    componentDidMount(): void {
        const { isLoading, submissions } = this.props;

        if (!isLoading && submissions.length === 0) {
            this.fetchUsers();
        }
    }

    fetchUsers = async () => {
        const { fetch, submissions, courseId } = this.props;

        fetch({ isLoading: true, failed: false, submissions });

        try {
            const fetchedSubmissions = await getRequest<ProjectSubmission[]>(
                "/course/" + courseId + "/submission"
            );

            fetch({
                isLoading: false,
                failed: false,
                submissions: fetchedSubmissions,
            });
        } catch (error: any) {
            fetch({ isLoading: false, failed: true, submissions: [] });
        }
    };

    handleGrade = (submission: ProjectSubmission) => {
        this.setState({
            submission,
        });
    };

    submitGrade = async (grade: number, comments: string[]) => {
        const { courseId, update } = this.props;
        const { submission } = this.state;

        if (!submission) return;

        this.setState({ isGrading: true });

        try {
            await postRequest(`/course/${courseId}/grade`, {
                id: submission.id,
                courseId,
                moduleId: submission.module.id,
                submoduleId: submission.submodule.id,
                grade,
                comments,
            });

            this.setState({ isGrading: false, submission: null });

            update({
                ...submission,
                content: { ...submission.content, grade, comments: comments },
            });
        } catch (error: any) {
            window.alert(CONNECTION_FAILED);

            this.setState({ isGrading: false });
        }
    };

    render() {
        const { isLoading, failed, submissions } = this.props;

        if (isLoading || failed) {
            return (
                <Stack flexGrow={1} alignItems="center" justifyContent="center">
                    <Loading failed={failed} onRetry={this.fetchUsers} />
                </Stack>
            );
        }

        if (submissions.length === 0) {
            return (
                <Stack className="content-min-width" spacing={3} pt={5}>
                    <Typography
                        fontWeight="bold"
                        variant="h5"
                        textAlign="center"
                    >
                        There is no project submission
                    </Typography>
                </Stack>
            );
        }

        return (
            <Stack className="content-min-width" spacing={3} pt={5}>
                <Table submissions={submissions} onGrade={this.handleGrade} />

                {this.state.submission && (
                    <GradeForm
                        onClose={() => {
                            if (!this.state.isGrading) {
                                this.setState({ submission: null });
                            }
                        }}
                        loading={this.state.isGrading}
                        submission={this.state.submission}
                        submit={this.submitGrade}
                    />
                )}
            </Stack>
        );
    }
}

function GradeForm({
    submission,
    submit,
    onClose,
    loading,
}: {
    submission: ProjectSubmission;
    submit: (grade: number, comments: string[]) => void;
    onClose: () => void;
    loading: boolean;
}) {
    const [comments, setComments] = useState(
        submission.content.comments?.join("\r\n") ?? ""
    );

    const [grade, setGrade] = useState<string>(
        String(submission.content.grade ?? "")
    );

    const handleClose = () => {
        onClose();
    };

    const handleCommentsChange = (e: ChangeEvent<HTMLTextAreaElement>) => {
        setComments(e.currentTarget.value);
    };

    const handlGradeChange = (e: ChangeEvent<HTMLInputElement>) => {
        const nbr = Number(e.currentTarget.value);

        if (
            e.currentTarget.value === "" ||
            (nbr > 0 && nbr <= MAX_PROJECT_GRADE)
        ) {
            setGrade(e.currentTarget.value);
        }
    };

    const handleSubmit = () => {
        const nbr = Number(grade);

        if (nbr > 0 && nbr <= MAX_PROJECT_GRADE) {
            submit(nbr, comments.split(/\r|\r|\n/g));
        }
    };

    const nmb = Number(grade);

    return (
        <DialogForm
            open
            title="Grade Project"
            submitButtonText={"Submit Grade"}
            onClose={handleClose}
            onSubmit={handleSubmit}
            isLoading={loading}
            PaperSx={{ minWidth: "500px" }}
            submitDisabled={
                !!submission.content.grade ||
                !(nmb > 0 && nmb <= MAX_PROJECT_GRADE)
            }
        >
            <Stack spacing={4}>
                <Stack spacing={2}>
                    <Stack spacing={1}>
                        <Stack spacing={1} direction="row">
                            <Typography fontWeight="bold">Name:</Typography>
                            <Typography>{submission.user.name}</Typography>
                        </Stack>
                        <Stack spacing={1} direction="row">
                            <Typography fontWeight="bold">Email:</Typography>
                            <MuiLink href={`mailto:${submission.user.email}`}>
                                {submission.user.email}
                            </MuiLink>
                        </Stack>
                    </Stack>

                    <Stack spacing={0.1}>
                        <Typography variant="h6">Course:</Typography>
                        <Typography variant="h6">
                            <strong>{submission.course.title}</strong>
                        </Typography>
                    </Stack>
                    <Stack spacing={0.1}>
                        <Typography variant="h6">Module:</Typography>
                        <Typography variant="h6">
                            <strong>{submission.module.title}</strong>
                        </Typography>
                    </Stack>

                    <Stack spacing={0.1}>
                        <Typography variant="h6">Project:</Typography>
                        <Typography variant="h6">
                            <strong>{submission.submodule.title}</strong>
                        </Typography>
                    </Stack>

                    <Stack spacing={0.1}>
                        <Typography variant="h6">Submission:</Typography>
                        <MuiLink
                            href={submission.content.url}
                            target="_blank"
                            rel="noreferrer"
                            underline="hover"
                            alignSelf="flex-start"
                            color={theme.palette.info.dark}
                            sx={{ wordBreak: "break-word" }}
                        >
                            <strong>{submission.content.url}</strong>
                        </MuiLink>
                    </Stack>
                </Stack>

                <TwoRowBlueInput
                    placeholder={`Grade out of ${MAX_PROJECT_GRADE}`}
                    value={grade}
                    onChange={handlGradeChange}
                    disabled={!!submission.content.grade}
                />

                <Stack spacing={1}>
                    <Typography variant="h6">Comments:</Typography>
                    <textarea
                        rows={4}
                        placeholder="Comments..."
                        className="question-input"
                        value={comments}
                        disabled={!!submission.content.comments}
                        onChange={handleCommentsChange}
                    ></textarea>
                </Stack>
            </Stack>
        </DialogForm>
    );
}

const mapDispatchToProps = {
    fetch: fetchCourseSubmissionsCreator,
    update: updateCourseSubmissionCreator,
};

function mapStateToProps(state: AppState) {
    return {
        ...state.courseSubmissions,
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withCourseId<Props>(ProjectGrading));
