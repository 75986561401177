import { OutlinedInput, Stack, Typography } from "@mui/material";
import FormControlLabel from "@mui/material/FormControlLabel";
import Radio from "@mui/material/Radio";
import TextField from "@mui/material/TextField";
import { ChangeEvent, Component, MouseEvent } from "react";
import { QuestionTest, MCQAnswer } from "../../types";
import DialogForm from "../DialogForm";
import Input from "../Input";

type Props = {
    questionTest: QuestionTest;
    onClose: () => void;
    onSubmit: (data: {
        id: number;
        title: string;
        trials: number;
        explanation: string[];
        hint: string[];
        xp: number;
        mcq: { answers: MCQAnswer[]; question: string };
    }) => void;
};

type State = {
    title: string;
    trials: string;
    xp: string;
    explanation: string;
    hint: string;

    question: string;
    options: MCQAnswer[];
    triggered: boolean;
};

export default class MCQForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            title: props.questionTest.title,
            trials: String(props.questionTest.trials),
            xp: String(props.questionTest.xp),
            explanation: props.questionTest.explanation.join("\r\n"),
            hint: props.questionTest.hint.join("\r\n"),
            question: props.questionTest.content.question,
            options: props.questionTest.content.answers,
            triggered: false,
        };
    }

    onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            title: e.currentTarget.value,
        });
    };

    onChangeQuestion = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            question: e.currentTarget.value,
        });
    };

    onChangeOption = (index: number, value: string) => {
        const options = [...this.state.options.slice()];

        options[index] = { ...options[index], text: value };

        this.setState({
            options: [...options],
        });
    };

    onClickOption = (index: number) => {
        const options = [...this.state.options.slice()];

        for (let index = 0; index < options.length; index++) {
            options[index].correct = false;
        }

        options[index] = { ...options[index], correct: true };

        this.setState({
            options: [...options],
        });
    };

    onChangeExplanation = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            explanation: e.currentTarget.value,
        });
    };

    onChangeHint = (e: ChangeEvent<HTMLTextAreaElement>) => {
        this.setState({
            hint: e.currentTarget.value,
        });
    };

    onChangeTrials = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            trials: e.currentTarget.value,
        });
    };

    onChangeXP = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            xp: e.currentTarget.value,
        });
    };

    onSubmit = () => {
        const { title, options, question, trials, xp, explanation, hint } =
            this.state;

        // todo: make verification

        const explanationValue = explanation.split(/\r|\r|\n/g);
        const hintValue = hint.split(/\r|\r|\n/g);

        const {
            questionTest: { id },
        } = this.props;

        this.props.onSubmit({
            title,
            mcq: { answers: options, question },
            trials: Number(trials),
            xp: Number(xp),
            explanation:
                explanationValue.length === 1 && explanationValue[0] === ""
                    ? []
                    : explanationValue,
            hint:
                hintValue.length === 1 && hintValue[0] === "" ? [] : hintValue,
            id,
        });
    };

    render() {
        const { title, trials, xp, question, options, explanation, hint } =
            this.state;

        return (
            <DialogForm
                title="MCQ"
                onClose={this.props.onClose}
                onSubmit={this.onSubmit}
                submitButtonText="Preview changes"
                isLoading={false}
            >
                <Stack spacing={3} pb={3}>
                    <Input
                        placeholder="Title"
                        size="medium"
                        value={title}
                        onChange={this.onChangeTitle}
                        sx={styles.input}
                    />
                    <textarea
                        rows={4}
                        placeholder="Question..."
                        className="question-input"
                        value={question}
                        onChange={this.onChangeQuestion}
                    ></textarea>
                    {options.map((option, index) => (
                        <QuestionBlueInput
                            key={index}
                            index={index}
                            placeholder={`Option ${index + 1}`}
                            value={option.text}
                            checked={option.correct}
                            onChange={this.onChangeOption}
                            onClick={this.onClickOption}
                        />
                    ))}

                    <Stack spacing={0.5}>
                        <Typography fontWeight="bold">Explanation:</Typography>
                        <textarea
                            rows={4}
                            placeholder="Explanation..."
                            className="question-input"
                            value={explanation}
                            onChange={this.onChangeExplanation}
                        ></textarea>
                    </Stack>

                    <Stack spacing={0.5}>
                        <Typography fontWeight="bold">Hint:</Typography>
                        <textarea
                            rows={4}
                            placeholder="Hint..."
                            className="question-input"
                            value={hint}
                            onChange={this.onChangeHint}
                        ></textarea>
                    </Stack>

                    <TwoRowBlueInput
                        placeholder="Number of trials"
                        value={trials}
                        onChange={this.onChangeTrials}
                    />
                    <TwoRowBlueInput
                        placeholder="Experience point (XP)"
                        value={xp}
                        onChange={this.onChangeXP}
                    />
                </Stack>
            </DialogForm>
        );
    }
}

function QuestionBlueInput({
    placeholder,
    checked,
    value,
    onChange,
    onClick,
    index,
}: {
    placeholder: string;
    checked: boolean;
    value: string;
    index: number;
    onChange: (index: number, value: string) => void;
    onClick: (index: number) => void;
}) {
    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        onChange(index, e.currentTarget.value);
    };

    const handleClick = (e: MouseEvent<HTMLButtonElement>) => {
        onClick(index);
    };

    return (
        <FormControlLabel
            control={<Radio onClick={handleClick} />}
            checked={checked}
            label={
                <TextField
                    label={placeholder}
                    variant="filled"
                    placeholder={placeholder}
                    value={value}
                    onChange={handleChange}
                    color="primary"
                    size="small"
                    sx={{
                        width: "500px",
                        "&.MuiTextField-root": {
                            background: "rgb(47 128 237 / 11%)",
                            borderLeft: "3px solid #6F71E0",
                            borderBottom: "none",
                        },
                        "& :focus": {},
                    }}
                />
            }
        />
    );
}

function TwoRowBlueInput({
    placeholder,
    value,
    onChange,
}: {
    placeholder: string;
    value: string;
    onChange: (e: ChangeEvent<HTMLInputElement>) => void;
}) {
    return (
        <Stack
            sx={{ width: "300px" }}
            spacing={1}
            direction="row"
            alignItems="center"
        >
            <Typography
                sx={{
                    background: "rgba(47, 128, 237, 0.2)",
                    borderLeft: "3px solid #6F71E0",
                    height: "35px",
                    pt: 0.8,
                    pl: 1,
                    flexGrow: 1,
                }}
            >
                {placeholder}
            </Typography>
            <OutlinedInput
                sx={{ width: "70px", height: "35px" }}
                onChange={onChange}
                value={value}
                type="number"
                inputProps={{ min: 0 }}
            />
        </Stack>
    );
}

const styles = {
    input: {
        width: "542px",
        alignSelf: "center",
    },
};
