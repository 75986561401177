import { MouseEvent, PureComponent } from "react";
import { Link, NavigateFunction, useNavigate } from "react-router-dom";
import { Question } from "../../types";
import CustomTableRow, { tableLinkStyle } from "../TableRow";
import TextTabCell from "../TextTabCell";

const maxCellWidth: number = 400;

type Props = {
    question: Question;
};

type State = {};

class TableRowCC extends PureComponent<
    Props & { navigate: NavigateFunction },
    State
> {
    generateHref = (id: number) => `/questions/${id}`;

    onClick = () => {
        const { question, navigate } = this.props;

        navigate(this.generateHref(question.id));
    };

    render() {
        const { question } = this.props;

        return (
            <CustomTableRow onClick={this.onClick}>
                <TextTabCell maxWidth={maxCellWidth}>
                    <Link
                        style={tableLinkStyle}
                        to={this.generateHref(question.id)}
                        onClick={(e: MouseEvent<HTMLAnchorElement>) =>
                            e.preventDefault()
                        }
                    >
                        {question.title}
                    </Link>
                </TextTabCell>
                <TextTabCell align="right">{question.tests}</TextTabCell>
            </CustomTableRow>
        );
    }
}

export default function TableRow(props: Props) {
    const navigate = useNavigate();

    return <TableRowCC {...props} navigate={navigate} />;
}
