import React from "react";

export default function SlideShow({ src, title }) {
    const handleLoad = async () => { console.log("LOADED SLIDE") }

    return (
        <iframe
            src={src}
            frameorder="0"
            width="760"
            height="473"
            allowFullScreen={true}
            mozallowfullscreen="true"
            webkitallowfullscreen="true"
            title={title}
            onLoad={handleLoad}
        ></iframe>
    );
}
