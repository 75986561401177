import Avatar from "@mui/material/Avatar";
import Box from "@mui/material/Box";
import Container from "@mui/material/Container";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import AlumnImage from "../../assets/images/alumni-cb.png";
import Aanuoluwa from "../../assets/images/anuolowa.svg";

// todo:minor universities image when screen is xs but big
export default function CBAlumni() {
    return (
        <Box className="bgcolor-light" width={"100%"}>
            <Stack
                component={Container}
                direction="column"
                spacing={{ xs: 4, md: 10 }}
                pt={6}
                pb={8}
            >
                <Typography
                    fontSize={{ xs: "2rem", md: "3rem" }}
                    textAlign="center"
                    fontWeight="bold"
                >
                    Our alumni work at
                </Typography>
                <Stack
                    direction={{ xs: "column", md: "row" }}
                    spacing={{ xs: 4, md: 10 }}
                >
                    <Box
                        sx={{
                            flex: 1,
                            minHeight: "200px",
                            display: "flex",
                            flexDirection: "row",
                            flexWrap: "wrap",
                            justifyContent: "space-around",
                            position: "relative",
                        }}
                    >
                        <img
                            src={AlumnImage}
                            alt="HackBio trusted by"
                            width="100%"
                            height="100%"
                        />
                    </Box>
                    <Stack
                        flex={{ xs: 1, md: 1.2 }}
                        spacing={4}
                        sx={{
                            alignItems: { xs: "center", md: "normal" },
                        }}
                    >
                        <Typography
                            variant="h6"
                            textAlign={{ xs: "center", md: "left" }}
                        >
                            <strong style={{ fontSize: "xx-large" }}>“</strong>
                            <br />I had a great learning experience and
                            importantly, I was super excited today because I got
                            the opportunity to put my learning to use and I was
                            able to do just that!
                        </Typography>

                        <Stack
                            direction={{ xs: "column", md: "row" }}
                            spacing={2}
                        >
                            <Avatar
                                alt="Aanuoluwa E.A."
                                src={Aanuoluwa}
                                sx={{
                                    width: 60,
                                    height: 60,
                                    alignSelf: "center",
                                }}
                            />
                            <Stack
                                spacing={{ xs: 2.5, md: 0 }}
                                textAlign={{ xs: "center", md: "left" }}
                            >
                                <Typography
                                    variant="h6"
                                    sx={{
                                        fontWeight: "bold",
                                    }}
                                >
                                    Aanuoluwa E.A.
                                </Typography>
                                <Typography variant="h6">
                                    Now in University of Oklahoma
                                </Typography>
                            </Stack>
                        </Stack>
                    </Stack>
                </Stack>
            </Stack>
        </Box>
    );
}
