import { Dispatch } from "redux";
import {
    FeedbacksActionType,
    FeedbacksState,
    FeedbackDetails,
    FetchFeedbackTests,
    FetchFeedbackDetails,
} from "./types";

export const fetchFeedbacksCreator = (
    payload: Omit<FeedbacksState, "details">
) => {
    return (dispatch: Dispatch<FetchFeedbackTests>) => {
        dispatch({
            value: payload,
            type: FeedbacksActionType.FetchFeedbackTests,
        });
    };
};

export const fetchFeedbackDetailsCreator = (payload: FeedbackDetails) => {
    return (dispatch: Dispatch<FetchFeedbackDetails>) => {
        dispatch({
            value: payload,
            type: FeedbacksActionType.FetchFeedbackDetails,
        });
    };
};
