import { Add } from "@mui/icons-material";
import { Box, Button, Menu, MenuItem } from "@mui/material";
import { ModuleType } from "@teyalite/hackbio-common/dist/types/module-type.enum";
import { SubmoduleType } from "@teyalite/hackbio-common/dist/types/submodule-type.enum";
import React, { Component } from "react";

type Props = {
    onCreateVideoSection: () => void;
    onCreateSlideSection: () => void;
    onTestCreate: () => void;
    onCreateRichHtml: (
        type: SubmoduleType.Text | SubmoduleType.Project
    ) => void;
    moduleType: ModuleType;
};

type State = {
    anchorEl: null | HTMLElement;
};

export default class ModuleDetailsForm extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            anchorEl: null,
        };
    }

    /**
     * Open question menu
     * @param event
     */
    onNewQuestionTest = (event: React.MouseEvent<HTMLButtonElement>) => {
        this.setState({ anchorEl: event.currentTarget });
    };

    /**
     * Close question menu
     */
    onCloseNewQuestion = () => {
        this.setState({ anchorEl: null });
    };

    handleCreateProject = () => {
        this.onCloseNewQuestion();
        this.props.onCreateRichHtml(SubmoduleType.Project);
    };

    render() {
        const { anchorEl } = this.state;
        const {
            onCreateVideoSection,
            onCreateSlideSection,
            onCreateRichHtml,
            onTestCreate,
            moduleType,
        } = this.props;

        if (moduleType === ModuleType.Project) {
            return (
                <Box sx={{ alignSelf: "flex-start" }}>
                    <Button
                        startIcon={<Add />}
                        onClick={this.handleCreateProject}
                    >
                        Add Project
                    </Button>
                </Box>
            );
        }

        return (
            <>
                <Box sx={{ alignSelf: "flex-start" }}>
                    <Button
                        startIcon={<Add />}
                        onClick={this.onNewQuestionTest}
                    >
                        Add Section
                    </Button>
                    <Menu
                        anchorEl={anchorEl}
                        open={Boolean(anchorEl)}
                        onClose={this.onCloseNewQuestion}
                    >
                        <MenuItem
                            onClick={() => {
                                this.onCloseNewQuestion();
                                onCreateRichHtml(SubmoduleType.Text);
                            }}
                        >
                            Text
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.onCloseNewQuestion();
                                onCreateVideoSection();
                            }}
                        >
                            Video
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.onCloseNewQuestion();
                                onCreateSlideSection();
                            }}
                        >
                            Slide
                        </MenuItem>
                        <MenuItem
                            onClick={() => {
                                this.onCloseNewQuestion();
                                onTestCreate();
                            }}
                        >
                            Test
                        </MenuItem>
                        <MenuItem onClick={this.handleCreateProject}>
                            Project
                        </MenuItem>
                    </Menu>
                </Box>
            </>
        );
    }
}
