/**
 * Error message functions
 */

/* Is required message */
export const requiredErrorMessage = (field: string) => `${field} is required`;
/* Short length error message */
export const shortLengthErrorMessage = (min: number, field: string) =>
  `${field} must be at least ${min} characters`;
/* Long length error message */
export const longLengthErrorMessage = (max: number, field: string) =>
  `${field} must be shorter than or equal to ${max} characters`;
