import { Box, FormHelperText } from "@mui/material";
import FormControl from "@mui/material/FormControl";
import OutlinedInput from "@mui/material/OutlinedInput";
import React, { ChangeEvent } from "react";

// todo: max length attribute

type Props = {
    error?: boolean;
    placeholder: string;
    value: string;
    size?: "small" | "medium";
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    helperText?: string;
    type?: string;
};

export default function AuthInput({
    placeholder,
    size,
    value,
    type,
    onChange,
    error,
    helperText,
}: Props) {
    return (
        <FormControl size={size} variant="filled" color="info" error={error}>
            <Box
                style={{
                    backgroundColor: "white",
                    display: "flex",
                }}
            >
                <OutlinedInput
                    style={{ width: "100%" }}
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    type={type}
                    inputProps={{ autoComplete: "username" }}
                />
            </Box>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
