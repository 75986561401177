import { Dispatch } from "redux";
import {
    FetchSetting,
    SettingActionType,
    SettingState,
    AddBadge,
    AddBiostack,
} from "../types/setting";
import { Badge, Biostack } from "../../types";

export const fetchSettingCreator = (payload: SettingState) => {
    return (dispatch: Dispatch<FetchSetting>) => {
        dispatch({
            value: payload,
            type: SettingActionType.FetchSetting,
        });
    };
};

export const addBadgeCreator = (payload: Badge) => {
    return (dispatch: Dispatch<AddBadge>) => {
        dispatch({
            value: payload,
            type: SettingActionType.AddBadge,
        });
    };
};

export const addBiostackCreator = (payload: Biostack) => {
    return (dispatch: Dispatch<AddBiostack>) => {
        dispatch({
            value: payload,
            type: SettingActionType.AddBiostack,
        });
    };
};
