import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { Component } from "react";
import { connect, ConnectedProps } from "react-redux";
import Container from "../../components/Container";
import FeedbackTable from "../../components/feedbacks/Table";
import {
    COURSES,
    DOCS,
    FEEDBACKS,
    QUESTIONS,
    SETTINGS,
} from "../../data/navigation.data";
import { fetchFeedbacksCreator } from "../../redux/feedback/actions";
import { AppState } from "../../redux/store";
import { FeedbackTest } from "../../types";
import { getRequest } from "../../utils/http";

type Props = PropsFromRedux & {};

class Feedbacks extends Component<Props> {
    componentDidMount() {
        const { tests, isLoading } = this.props;

        if (!isLoading && tests.length === 0) {
            this.fetchFeedbacks();
        }
    }

    fetchFeedbacks = async () => {
        const { fetch } = this.props;

        fetch({
            isLoading: true,
            failed: false,
            tests: [],
        });

        try {
            const tests = await getRequest<FeedbackTest[]>("/feedback");

            fetch({
                isLoading: false,
                failed: false,
                tests,
            });
        } catch (error) {
            fetch({
                isLoading: true,
                failed: true,
                tests: [],
            });
        }
    };

    render() {
        const { tests, isLoading, failed } = this.props;

        return (
            <Container
                drawerItems={drawerItems}
                appBarTitle="Feedbacks"
                isLoading={isLoading}
                failed={failed}
                onFail={this.fetchFeedbacks}
            >
                <Stack className="content-min-width">
                    <Typography variant="h6">
                        Feedbacks from questions
                    </Typography>

                    <FeedbackTable tests={tests} />
                </Stack>
            </Container>
        );
    }
}

export const drawerItems = [
    COURSES,
    QUESTIONS,
    DOCS,
    { ...FEEDBACKS, selected: true },
    SETTINGS,
];

const mapDispatchToProps = {
    fetch: fetchFeedbacksCreator,
};

function mapStateToProps(state: AppState) {
    return { ...state.feedbacks };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(Feedbacks);
