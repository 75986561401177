import { Button } from "@mui/material";
import MuiTableCell from "@mui/material/TableCell";
import { ProjectSubmission } from "../../../types";
import CustomTableRow from "../../TableRow";
import TextTabCell from "../../TextTabCell";

const maxCellWidth: number = 150;

type Props = {
    submission: ProjectSubmission;
    onGrade: (d: ProjectSubmission) => void;
};

export default function TableRow({ submission, onGrade }: Props) {
    return (
        <CustomTableRow>
            <TextTabCell maxWidth={maxCellWidth}>
                {submission.module.title}
            </TextTabCell>
            <TextTabCell maxWidth={maxCellWidth}>
                {submission.submodule.title}
            </TextTabCell>
            <MuiTableCell align="right">
                {submission.content.grade ?? "-"}
            </MuiTableCell>
            <MuiTableCell align="right">
                <Button
                    variant="contained"
                    disableElevation
                    color="secondary"
                    sx={{ fontWeight: "bold" }}
                    onClick={() => onGrade({ ...submission })}
                >
                    Grade
                </Button>
            </MuiTableCell>
        </CustomTableRow>
    );
}
