import { CourseUser } from "../../types";

export interface CourseUsersState {
    isLoading: boolean;
    failed: boolean;
    users: CourseUser[];
}

export interface FetchCourseUsers {
    type: CourseUsersActionType.FetchCourseUsers;
    value: CourseUsersState;
}

export enum CourseUsersActionType {
    FetchCourseUsers = "CourseFetchCourseUsers",
}

export type CourseUsersAction = FetchCourseUsers;

export const defaultCourseUsersState: CourseUsersState = {
    isLoading: false,
    failed: false,
    users: [],
};
