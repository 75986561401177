import { SelectChangeEvent } from "@mui/material/Select";
import Stack from "@mui/material/Stack";
import { ChangeEvent, Component } from "react";
import { Biostack, Course } from "../../types";
import DialogForm from "../DialogForm";
import Input from "../Input";
import Select from "../Select";
import { postRequest } from "../../utils/http";
import { CONNECTION_FAILED } from "../../constants";

type Props = {
    open: boolean;
    biostacks: Biostack[];
    onClose: () => void;
    addCourse: (course: Course) => void;
};

type State = {
    isCreatingCourse: boolean;

    title: string;
    biostack: string;
    price: string;

    triggered: boolean;
};

const TITLE_ERROR = "Title is required";

const PRICE_ERROR = "Price is required";

const BIOSTACK_ERROR = "Biostack is required";

const defaultState: State = {
    isCreatingCourse: false,

    title: "",
    biostack: "",
    price: "10",

    triggered: false,
};

export default class NewCourseForm extends Component<Props, State> {
    private mounted: boolean;
    constructor(props: Props) {
        super(props);
        this.state = {
            ...defaultState,
        };

        this.mounted = false;
    }

    componentWillUnmount(): void {
        this.mounted = false;
    }

    fetchBiotstacks = () => {};

    onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            title: e.currentTarget.value,
        });
    };

    onBiostack = (event: SelectChangeEvent) => {
        this.setState({
            biostack: event.target.value,
        });
    };

    onChangePrice = (e: ChangeEvent<HTMLInputElement>) => {
        this.setState({
            price: e.currentTarget.value,
        });
    };

    onSubmit = async () => {
        const { title, biostack, price } = this.state;
        const { addCourse, onClose } = this.props;

        if (
            title.trim().length === 0 ||
            biostack.length === 0 ||
            price.length === 0
        ) {
            return this.setState({ triggered: true });
        }

        try {
            const createdCourse = await postRequest<Course>("/course", {
                title,
                price: Number(price),
                biostack: Number(biostack),
            });

            addCourse(createdCourse);
            this.setState({ ...defaultState });
            onClose();
        } catch (error: any) {
            window.alert(CONNECTION_FAILED);
            this.setState({ isCreatingCourse: false });
        }
    };

    render() {
        const { title, price, biostack, triggered, isCreatingCourse } =
            this.state;

        const { biostacks, open, onClose } = this.props;

        return (
            <DialogForm
                open={open}
                title="Add New Course"
                submitButtonText="Add"
                onClose={onClose}
                onSubmit={this.onSubmit}
                isLoading={isCreatingCourse}
                PaperSx={{ minWidth: "500px" }}
            >
                <Stack spacing={3}>
                    <Input
                        placeholder="Title"
                        size="medium"
                        value={title}
                        onChange={this.onChangeTitle}
                        error={triggered && title.trim().length === 0}
                        errorMessage={TITLE_ERROR}
                        helperText="It's ok if you can't think of a good title now. You can change it later."
                        sx={styles.input}
                    />
                    <Input
                        placeholder="Price"
                        size="medium"
                        type="number"
                        value={price}
                        onChange={this.onChangePrice}
                        error={triggered && price.trim().length === 0}
                        errorMessage={PRICE_ERROR}
                        sx={styles.input}
                        helperText="It's ok if you can't think of a good price now. You can change it later."
                    />
                    <Select
                        items={biostacks.map(({ id, name }) => ({
                            value: String(id),
                            label: name,
                        }))}
                        value={biostack}
                        onChange={this.onBiostack}
                        helperText="If you're not sure about the right biostack, you can change it later."
                        placeholder="Biostack"
                        error={triggered && biostack.trim().length === 0}
                        errorMessage={BIOSTACK_ERROR}
                    />
                </Stack>
            </DialogForm>
        );
    }
}

const styles = {
    input: {
        maxWidth: "500px",
        alignSelf: "center",
        width: "100%",
    },
};
