import RefreshIcon from "@mui/icons-material/Refresh";
import { IconButton, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import { PropsWithChildren, ReactNode } from "react";
import { CONNECTION_FAILED } from "../constants";
import { DrawerLink as DrawerLinkType } from "../types";
import Loading from "./Loading";
import AppBar from "./navigation/AppBar";
import Drawer from "./navigation/Drawer";
import DrawerLink from "./navigation/DrawerLink";

type Props = PropsWithChildren & {
    drawerItems: DrawerLinkType[];
    drawerBackButton?: { title: string; href: string };
    drawerChildren?: ReactNode; // Additional non link elements are needed
    appBarTitle: ReactNode;
    isLoading?: boolean;
    failed?: boolean;
    onFail?: () => void;
};

export default function Container({
    children,
    drawerItems,
    drawerBackButton,
    appBarTitle,
    drawerChildren,
    isLoading = false,
    failed,
    onFail,
}: Props) {
    return (
        <main>
            <AppBar title={appBarTitle} />
            <Drawer backButton={drawerBackButton}>
                {drawerChildren}
                {drawerItems.map((drawerItem) => (
                    <DrawerLink key={drawerItem.href} {...drawerItem} />
                ))}
            </Drawer>
            <Stack id="content">
                {isLoading ? (
                    <Stack pt={10}>
                        {!failed ? (
                            <Loading />
                        ) : (
                            <Stack
                                textAlign="center"
                                spacing={3}
                                alignItems="center"
                                justifyContent="center"
                            >
                                <IconButton size="large" onClick={onFail}>
                                    <RefreshIcon sx={{ fontSize: 50 }} />
                                </IconButton>
                                <Typography>{CONNECTION_FAILED}</Typography>
                                <Typography>Try again</Typography>
                            </Stack>
                        )}
                    </Stack>
                ) : (
                    children
                )}
            </Stack>
        </main>
    );
}
