import { createTheme } from "@mui/material/styles";

// Create a theme instance.
const theme = createTheme({
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 900,
            lg: 1200,
            xl: 1536,
        },
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    textTransform: "none",
                },
            },
        },
        MuiListItemButton: {
            styleOverrides: {
                root: {
                    borderRadius: "5px",
                    "&.Mui-selected": {
                        background: "rgb(25 118 210 / 24%)",
                    },
                },
            },
        },
    },
});

export default theme;
