import Stack from "@mui/material/Stack";
import { AdminRole } from "@teyalite/hackbio-common/dist/types/admin-role.enum";
import { UNAUTHORIZED } from "http-status";
import React, {
    PropsWithChildren,
    createContext,
    useContext,
    useEffect,
    useState,
} from "react";
import { Navigate, Outlet } from "react-router-dom";
import Loading from "./components/Loading";
import SimpleAppBar from "./components/navigation/SimpleAppBar";
import NotFound from "./pages/NotFound";
import { AuthContextType, User } from "./types";
import { getRequest } from "./utils/http";

// todo: skeleton

export const AuthContext = createContext<AuthContextType>({
    user: null,
    loading: true,
    failed: false,
    onRetry: () => {},
    setAdmin: () => {},
});

export const AuthContextProvider = ({
    children,
}: {
    children: React.ReactNode;
}) => {
    const [user, setUser] = useState<User | null>(null);
    const [loading, setLoading] = useState<boolean>(true);
    const [failed, setFailed] = useState<boolean>(false);

    useEffect(() => {
        fetchUser();
    }, []);

    const fetchUser = async () => {
        setLoading(true);
        setFailed(false);

        try {
            const admin = await getRequest<User>("/auth/admin");
            setLoading(false);
            setFailed(false);
            setUser(admin);
        } catch (error: any) {
            setFailed(
                !(error.response && error.response.status === UNAUTHORIZED)
            );

            setLoading(false);
        }
    };

    const setAdmin = (admin: User) => {
        setUser(admin);
    };

    return (
        <AuthContext.Provider
            value={{
                user,
                loading,
                failed,
                onRetry: fetchUser,
                setAdmin,
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

/**
 * Protect all routes except login route
 * @returns
 */
export function AuthProtected() {
    const { user, loading, failed, onRetry } = useContext(AuthContext);

    if (loading || failed) {
        return (
            <Stack width="100%" height="100%" sx={{ background: "white" }}>
                <SimpleAppBar />
                <Stack alignItems="center" justifyContent="center" pt={20}>
                    <Loading failed={failed} onRetry={onRetry} />
                </Stack>
            </Stack>
        );
    }

    if (user) {
        return <Outlet />;
    }

    return <Navigate to="/login" />;
}

/**
 * Routes allowed only for super admin
 * @returns
 */
export function SuperProtected({ children }: PropsWithChildren) {
    const user = useContext(AuthContext).user!;

    if (user.role.includes(AdminRole.Super)) {
        return children ? <>{children}</> : <Outlet />;
    }

    // General 404 error page
    return <NotFound />;
}
