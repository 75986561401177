import { ComponentClass } from "react";
import { useOutletContext } from "react-router-dom";

export function withCourseId<ComponentProps>(Component: ComponentClass<any>) {
    function ComponentWithRouterProp(
        props: Omit<
            Omit<Omit<ComponentProps, "courseId">, "setCourseTitle">,
            "moduleId"
        >
    ) {
        const { courseId, setCourseTitle, moduleId } = useOutletContext<{
            courseId: number;
            setCourseTitle: (title: string) => void;
            moduleId: number;
        }>();

        return (
            <Component
                {...props}
                courseId={courseId}
                setCourseTitle={setCourseTitle}
                moduleId={moduleId}
            />
        );
    }

    return ComponentWithRouterProp;
}
