import React, { Component } from "react";
import { Outlet } from "react-router-dom";
import { withCourseId } from "../../utils/with-course";

type Props = {
    courseId: number;
    setCourseTitle: (title: string) => void;
    moduleId: number;
};

class ModulesLayout extends Component<Props> {
    render() {
        const { courseId, moduleId, setCourseTitle } = this.props;

        return <Outlet context={{ courseId, moduleId, setCourseTitle }} />;
    }
}

export default withCourseId<Props>(ModulesLayout);
