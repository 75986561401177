import AddIcon from "@mui/icons-material/Add";
import { Button, Stack, Typography } from "@mui/material";
import MuiLink from "@mui/material/Link";
import { Component } from "react";
import { ConnectedProps, connect } from "react-redux";
import { Link } from "react-router-dom";
import BackdropLoading from "../../components/BackdropLoading";
import Loading from "../../components/Loading";
import NewModuleForm from "../../components/courses/NewModuleForm";
import {
    addModuleCreator,
    fetchCourseModulesCreator,
} from "../../redux/actions/course-module";
import { AppState } from "../../redux/store";
import { Module } from "../../types";
import { getRequest } from "../../utils/http";
import { withCourseId } from "../../utils/with-course";

type Props = PropsFromRedux & {
    courseId: number;
};

type State = {
    isCreatingModule: boolean;
    open: boolean;
};

class CourseModules extends Component<Props, State> {
    constructor(props: Props) {
        super(props);
        this.state = {
            isCreatingModule: false,
            open: false,
        };
    }

    componentDidMount(): void {
        const { isLoading, modules } = this.props;

        if (!isLoading && modules.length === 0) {
            this.fetchCourseModules();
        }
    }

    fetchCourseModules = async () => {
        const { modules, fetch, courseId } = this.props;

        fetch({ isLoading: true, failed: false, modules });

        try {
            const fetchedModules = await getRequest<Module[]>(
                "/course/" + courseId + "/module"
            );

            fetch({ isLoading: false, failed: false, modules: fetchedModules });
        } catch (error: any) {
            fetch({ isLoading: false, failed: true, modules: [] });
        }
    };

    onAdd = () => {
        this.setState({ open: true });
    };

    onClose = () => {
        this.setState({ open: false });
    };

    render() {
        const { isLoading, failed, modules, courseId, addModule } = this.props;

        const { isCreatingModule, open } = this.state;

        if (isLoading || failed) {
            return (
                <Stack flexGrow={1} alignItems="center" justifyContent="center">
                    <Loading
                        failed={failed}
                        onRetry={this.fetchCourseModules}
                    />
                </Stack>
            );
        }

        return (
            <Stack className="content-min-width" spacing={3} pt={5}>
                {isCreatingModule && <BackdropLoading open />}

                <NewModuleForm
                    courseId={courseId}
                    open={open}
                    onClose={this.onClose}
                    addModule={addModule}
                />

                <Button
                    variant="contained"
                    disableElevation
                    sx={{ alignSelf: "flex-start" }}
                    onClick={this.onAdd}
                    startIcon={<AddIcon />}
                >
                    New Module
                </Button>

                <Stack spacing={2} sx={{ maxWidth: 900 }} component="ol" px={3}>
                    {modules.map(({ title, id, submodules, languageType }) => (
                        <Typography component="li" key={id} variant="h6">
                            <MuiLink
                                component={Link}
                                underline="hover"
                                color="#000000"
                                to={`/courses/${courseId}/modules/${id}`}
                            >
                                {title}
                                {/* {title} {submodules}{" "} */}
                                {/* {languageType ?? "No language"} */}
                            </MuiLink>
                        </Typography>
                    ))}
                </Stack>
            </Stack>
        );
    }
}

const mapDispatchToProps = {
    fetch: fetchCourseModulesCreator,
    addModule: addModuleCreator,
};

function mapStateToProps(state: AppState) {
    return {
        ...state.courseModule.modules,
    };
}

const connector = connect(mapStateToProps, mapDispatchToProps);
type PropsFromRedux = ConnectedProps<typeof connector>;

export default connector(withCourseId<Props>(CourseModules));
