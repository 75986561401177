import React, { Component, CSSProperties, FormEvent, ReactNode } from "react";
import Box from "@mui/material/Box";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContentText from "@mui/material/DialogContentText";
import DialogContent from "@mui/material/DialogContent";
import CloseIcon from "@mui/icons-material/Close";
import IconButton from "@mui/material/IconButton";
import { Stack } from "@mui/material";
import { SxProps, Theme } from "@mui/material/styles";
import { DIALOG_CANCEL } from "../constants";
import LoadingButton from "@mui/lab/LoadingButton";

const MIN_WIDTH = 400;

type Props = {
    title: string;
    submitButtonText: string;
    children?: ReactNode;
    isLoading?: boolean; // True when form is being submitted
    open?: boolean; // Open the form
    bottomText?: string;
    cancelButtonText?: string;
    onClose: () => void;
    onSubmit: () => void;
    minWidth?: number;
    PaperSx?: SxProps<Theme>;
    submitDisabled?: boolean;
};

class DialogForm extends Component<Props> {
    render() {
        const {
            open,
            title,
            submitButtonText,
            bottomText,
            cancelButtonText,
            onClose,
            children,
            onSubmit,
            minWidth,
            submitDisabled,
            isLoading,
            PaperSx,
        } = this.props;

        return (
            <Dialog
                open={open === undefined ? true : open}
                PaperProps={{ sx: PaperSx }}
                onClose={onClose}
                scroll={"paper"}
                aria-labelledby="scroll-dialog-title"
                aria-describedby="scroll-dialog-description"
            >
                <Box sx={styles.header}>
                    <DialogTitle id="scroll-dialog-title">
                        <Typography noWrap sx={{ maxWidth: 350 }}>
                            {title}
                        </Typography>
                    </DialogTitle>
                    <IconButton
                        onClick={onClose}
                        disabled={isLoading}
                        color="error"
                        sx={styles.closeIconButton}
                    >
                        <CloseIcon />
                    </IconButton>
                </Box>
                <DialogContent>
                    <DialogContentText
                        id="scroll-dialog-description"
                        component="div"
                        tabIndex={-1}
                    >
                        <Box
                            component="form"
                            onSubmit={(e: FormEvent<HTMLFormElement>) =>
                                e.preventDefault()
                            }
                            autoComplete="off"
                            sx={styles.form}
                            style={{ minWidth: minWidth ?? MIN_WIDTH }}
                        >
                            {children}
                        </Box>
                        <span style={styles.bottomText}>{bottomText}</span>
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={styles.DialogActions}>
                    <Stack direction="row" spacing={5} pb={2} px={2}>
                        {isLoading ? (
                            <LoadingButton loading variant="outlined" fullWidth>
                                Submit
                            </LoadingButton>
                        ) : (
                            <>
                                <Button
                                    onClick={onClose}
                                    color="error"
                                    variant="contained"
                                    disableElevation
                                >
                                    {cancelButtonText ?? DIALOG_CANCEL}
                                </Button>
                                <Button
                                    onClick={onSubmit}
                                    color="success"
                                    variant="contained"
                                    disableElevation
                                    disabled={submitDisabled}
                                >
                                    {submitButtonText}
                                </Button>
                            </>
                        )}
                    </Stack>
                </DialogActions>
            </Dialog>
        );
    }
}

const styles = {
    header: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        py: 2,
        backgroundColor: "rgba(47, 128, 237, 0.2)",
    },
    closeIconButton: {
        textTransform: "none",
        marginRight: 3,
    },
    form: {
        marginBottom: 1,
    },
    bottomText: {
        color: "red",
        fontSize: "small",
    } as CSSProperties,
    DialogActions: {},
};

export default DialogForm;
