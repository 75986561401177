import { Dispatch } from "redux";
import { Course } from "../../types";
import {
    AddCourse,
    CourseActionType,
    CourseState,
    FetchCourse,
    UpdateCourse,
} from "../types/course";

export const fetchCourseCreator = (payload: CourseState) => {
    return (dispatch: Dispatch<FetchCourse>) => {
        dispatch({
            value: payload,
            type: CourseActionType.FetchCourse,
        });
    };
};

export const addCourseCreator = (payload: Course) => {
    return (dispatch: Dispatch<AddCourse>) => {
        dispatch({
            value: payload,
            type: CourseActionType.AddCourse,
        });
    };
};

export const updateCourseCreator = (payload: {
    courseId: number;
    course: Partial<Course>;
}) => {
    return (dispatch: Dispatch<UpdateCourse>) => {
        dispatch({
            value: payload,
            type: CourseActionType.UpdateCourse,
        });
    };
};
