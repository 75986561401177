import { Course } from "../../types";

export interface CourseState {
    isLoading: boolean;
    failed: boolean;
    courses: Course[];
}

export interface FetchCourse {
    type: CourseActionType.FetchCourse;
    value: CourseState;
}

export interface AddCourse {
    type: CourseActionType.AddCourse;
    value: Course;
}

export interface UpdateCourse {
    type: CourseActionType.UpdateCourse;
    value: { courseId: number; course: Partial<Course> };
}

export enum CourseActionType {
    FetchCourse = "FetchCourse",
    AddCourse = "AddCourse",
    UpdateCourse = "UpdateCourse",
}

export type CourseAction = FetchCourse | AddCourse | UpdateCourse;

export const defaultCourseState: CourseState = {
    isLoading: false,
    failed: false,
    courses: [],
};
