import {
    defaultCourseState,
    CourseState,
    CourseActionType,
    CourseAction,
} from "../types/course";

function courseReducer(
    state: CourseState = defaultCourseState,
    action: CourseAction
    // action: any
): CourseState {
    switch (action.type) {
        case CourseActionType.FetchCourse:
            state = {
                ...state,
                ...action.value,
            };
            return state;

        case CourseActionType.AddCourse:
            state = {
                ...state,
                courses: [action.value, ...state.courses],
            };
            return state;

        case CourseActionType.UpdateCourse:
            const courses = state.courses.slice();

            const index = courses.findIndex(
                (course) => course.id === action.value.courseId
            );

            if (index >= 0) {
                courses[index] = {
                    ...courses[index],
                    ...action.value.course,
                };
            }

            state = {
                ...state,
                courses: [...courses],
            };

            return state;

        default:
            return state;
    }
}

export default courseReducer;
