import React, { ReactNode } from "react";
import { IconButton, SxProps, Typography } from "@mui/material";
import Stack from "@mui/material/Stack";
import Edit from "@mui/icons-material/Edit";

type Props = {
    title: string;
    children: ReactNode;
    onClick: () => void;
    sx?: SxProps;
};

export default function TextEditContainer({
    title,
    children,
    onClick,
    sx = styles.TextEditContainer,
}: Props) {
    return (
        <Stack spacing={1} sx={sx}>
            <Stack
                direction="row"
                justifyContent="space-between"
                alignItems="center"
                width="100%"
            >
                <Typography variant="h6" fontWeight="bold">
                    {title}
                </Typography>
                <IconButton onClick={onClick}>
                    <Edit />
                </IconButton>
            </Stack>
            {children}
        </Stack>
    );
}

const styles = {
    TextEditContainer: {
        width: "800px",
    },
};
