import {
  PASSWORD_ASCII_ERROR_MESSAGE,
  PASSWORD_FIELD,
  PASSWORD_MAX_LENGTH,
  PASSWORD_MIN_LENGTH,
} from './constants';
import { isAscii } from 'class-validator';
import { shortLengthErrorMessage } from './error-messages';

/**
 * Validate password
 * @param password
 * @returns
 */
export function passwordValidation(password: string) {
  if (
    password.length < PASSWORD_MIN_LENGTH ||
    password.length > PASSWORD_MAX_LENGTH
  ) {
    return shortLengthErrorMessage(PASSWORD_MIN_LENGTH, PASSWORD_FIELD);
  }

  if (!isAscii(password)) {
    return PASSWORD_ASCII_ERROR_MESSAGE;
  }
}
