import React, { MouseEvent, ChangeEvent } from "react";
import OutlinedInput from "@mui/material/OutlinedInput";
import Box from "@mui/material/Box";
import FormControl from "@mui/material/FormControl";
import InputAdornment from "@mui/material/InputAdornment";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { FormHelperText, IconButton } from "@mui/material";
import { PASSWORD_MAX_LENGTH } from "@teyalite/hackbio-common/src/constants";

type Props = {
    error?: boolean;
    placeholder: string;
    value: string;
    size?: "small" | "medium";
    onChange?: (event: ChangeEvent<HTMLInputElement>) => void;
    helperText?: string;
};

export default function PasswordInput({
    placeholder,
    value,
    size,
    onChange,
    helperText,
    error,
}: Props) {
    const [showPassword, setShowPassword] = React.useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    return (
        <FormControl variant="standard" error={error} size={size} color="info">
            <Box sx={{ backgroundColor: "white" }}>
                <OutlinedInput
                    value={value}
                    placeholder={placeholder}
                    onChange={onChange}
                    type={showPassword ? "text" : "password"}
                    endAdornment={
                        <InputAdornment position="end">
                            <IconButton
                                onClick={handleClickShowPassword}
                                onMouseDown={handleMouseDownPassword}
                            >
                                {showPassword ? (
                                    <VisibilityOff />
                                ) : (
                                    <Visibility />
                                )}
                            </IconButton>
                        </InputAdornment>
                    }
                    sx={{ width: "100%" }}
                    inputProps={{
                        maxLength: PASSWORD_MAX_LENGTH,
                        autoComplete: "current-password",
                    }}
                />
            </Box>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
        </FormControl>
    );
}
