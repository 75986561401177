import { Badge, Biostack } from "../../types";

export interface SettingState {
    isLoading: boolean;
    failed: boolean;
    badges: Badge[];
    biostacks: Biostack[];
}

export interface FetchSetting {
    type: SettingActionType.FetchSetting;
    value: SettingState;
}

export interface AddBadge {
    type: SettingActionType.AddBadge;
    value: Badge;
}

export interface AddBiostack {
    type: SettingActionType.AddBiostack;
    value: Biostack;
}

export enum SettingActionType {
    FetchSetting = "FetchSetting",
    AddBadge = "AddBadge",
    AddBiostack = "AddBiostack",
}

export type SettingAction = FetchSetting | AddBadge | AddBiostack;

export const defaultSettingState: SettingState = {
    isLoading: false,
    failed: false,
    badges: [],
    biostacks: [],
};
