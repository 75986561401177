import { Dispatch } from "redux";
import { Module, Submodule } from "../../types";
import {
    AddModule,
    AddSubmodule,
    CourseModuleActionType,
    FetchCourseModules,
    FetchModuleDetails,
    ModuleDetailsState,
    ModulesState,
    RemoveSubmodule,
    UpdateModule,
    UpdateModuleData,
    UpdateSubmodule,
} from "../types/course-module";

export const fetchCourseModulesCreator = (payload: ModulesState) => {
    return (dispatch: Dispatch<FetchCourseModules>) => {
        dispatch({
            value: payload,
            type: CourseModuleActionType.FetchCourseModules,
        });
    };
};

export const fetchModuleDetailsCreator = (payload: ModuleDetailsState) => {
    return (dispatch: Dispatch<FetchModuleDetails>) => {
        dispatch({
            value: payload,
            type: CourseModuleActionType.FetchModuleDetails,
        });
    };
};

export const addModuleCreator = (payload: Module) => {
    return (dispatch: Dispatch<AddModule>) => {
        dispatch({
            value: payload,
            type: CourseModuleActionType.AddModule,
        });
    };
};

export const updateModuleCreator = (payload: UpdateModuleData) => {
    return (dispatch: Dispatch<UpdateModule>) => {
        dispatch({
            value: payload,
            type: CourseModuleActionType.UpdateModule,
        });
    };
};

export const updateSubmoduleCreator = (payload: Submodule) => {
    return (dispatch: Dispatch<UpdateSubmodule>) => {
        dispatch({
            value: payload,
            type: CourseModuleActionType.UpdateSubmodule,
        });
    };
};

export const removeSubmoduleCreator = (payload: number) => {
    return (dispatch: Dispatch<RemoveSubmodule>) => {
        dispatch({
            value: payload,
            type: CourseModuleActionType.RemoveSubmodule,
        });
    };
};

export const addSubmoduleCreator = (payload: Submodule) => {
    return (dispatch: Dispatch<AddSubmodule>) => {
        dispatch({
            value: payload,
            type: CourseModuleActionType.AddSubmodule,
        });
    };
};
