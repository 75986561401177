import React, { ChangeEvent, useState } from "react";
import DialogForm from "../DialogForm";
import { Stack } from "@mui/material";
import Input from "../Input";
import { SubmoduleType } from "@teyalite/hackbio-common/dist/types/submodule-type.enum";

const TITLE_ERROR = "TITLE_ERROR";
const URL_ERROR = "URL_ERROR";

export default function UrlForm({
    data,
    submoduleId,
    type,
    urlPlaceholder,
    open = false,
    isLoading = false,
    submitButtonText,
    dialogTitle,
    onClose,
    submit,
}: {
    data: { title: string; url: string };
    type: SubmoduleType.Video | SubmoduleType.Slide;
    submoduleId?: number;
    urlPlaceholder: string;
    open?: boolean;
    dialogTitle: string;
    submitButtonText: string;
    isLoading?: boolean;
    submit: (
        type: SubmoduleType.Video | SubmoduleType.Slide,
        title: string,
        url: string,
        submoduleId?: number
    ) => void;
    onClose: () => void;
}) {
    const [title, setTitle] = useState(data.title);
    const [url, setUrl] = useState(data.url);
    const [triggered, setTriggered] = useState(false);

    const onChangeTitle = (e: ChangeEvent<HTMLInputElement>) => {
        setTitle(e.currentTarget.value);
    };

    const onChangeUrl = (e: ChangeEvent<HTMLInputElement>) => {
        setUrl(e.currentTarget.value);
    };

    const handleSubmit = () => {
        if (url.trim().length === 0 || title.trim().length === 0) {
            return setTriggered(true);
        }

        submit(type, title, url, submoduleId);
    };

    return (
        <DialogForm
            open={open}
            title={dialogTitle}
            submitButtonText={submitButtonText}
            onClose={onClose}
            onSubmit={handleSubmit}
            isLoading={isLoading}
            PaperSx={{ minWidth: "500px" }}
        >
            <Stack spacing={3}>
                <Input
                    placeholder="Title"
                    size="medium"
                    value={title}
                    onChange={onChangeTitle}
                    error={triggered && title.trim().length === 0}
                    errorMessage={TITLE_ERROR}
                    // helperText="It's ok if you can't think of a good title now. You can change it later."
                    sx={styles.input}
                />
                <Input
                    placeholder={urlPlaceholder}
                    size="medium"
                    value={url}
                    onChange={onChangeUrl}
                    error={triggered && url.trim().length === 0}
                    errorMessage={URL_ERROR}
                    sx={styles.input}
                />
            </Stack>
        </DialogForm>
    );
}

const styles = {
    input: {
        maxWidth: "500px",
        alignSelf: "center",
        width: "100%",
    },
};
