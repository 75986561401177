import { Question, QuestionDetails, QuestionTest } from "../../types";

export interface QuestionDetailsState {
    isLoading: boolean;
    failed: boolean;
    details: QuestionDetails | null;
}

export interface QuestionState {
    isLoading: boolean;
    failed: boolean;
    questions: Question[];
    questionDetails: QuestionDetailsState;
}

export type FetchQuestionState = Omit<QuestionState, "questionDetails">;

export interface FetchQuestion {
    type: QuestionActionType.FetchQuestion;
    value: FetchQuestionState;
}

export interface AddQuestion {
    type: QuestionActionType.AddQuestion;
    value: Question;
}

export interface FetchQuestionDetails {
    type: QuestionActionType.FetchQuestionDetails;
    value: QuestionDetailsState;
}

export interface UpdateQuestionTest {
    type: QuestionActionType.UpdateQuestionTest;
    value: QuestionTest;
}

export interface AddQuestionTest {
    type: QuestionActionType.AddQuestionTest;
    value: QuestionTest;
}

/**
 * Edit single question in questionDetails.question and update the title in questions
 */
export interface EditQuestionDetails {
    type: QuestionActionType.EditQuestionDetails;
    value: QuestionDetails;
}

export enum QuestionActionType {
    FetchQuestion = "FetchQuestion",
    AddQuestion = "AddQuestion",
    FetchQuestionDetails = "FetchQuestionDetails",
    EditQuestionDetails = "EditQuestionDetails",
    UpdateQuestionTest = "UpdateQuestionTest",
    AddQuestionTest = "AddQuestionTest",
}

export type QuestionAction =
    | FetchQuestion
    | AddQuestion
    | EditQuestionDetails
    | FetchQuestionDetails
    | UpdateQuestionTest
    | AddQuestionTest;

export const defaultQuestionState: QuestionState = {
    isLoading: false,
    failed: false,
    questions: [],
    questionDetails: {
        isLoading: false,
        failed: false,
        details: null,
    },
};
